<script>
import {
    layoutComputed
} from "@/state/helpers";
import VerticalPenilaian from "./vertical-penilaian";

export default {
    components: {
        VerticalPenilaian,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
    mounted() {
        // document.querySelector("html").setAttribute('dir', 'rtl');
    }
};
</script>
<style>
/* .vertical-menu-penilaian,
.footer,
#page-topbar,
.page-title-box {
    display: none !important; */
    /* display: lokasi di file all-penjadwalan; */
/* } */

.main-content-penilaian {
    margin-left: 0px !important;
}

.page-content-penilaian {
    padding-top: 0px;
}
</style>
<template>
    <div>
        <VerticalPenilaian :layout="layoutType">
            <slot />
        </VerticalPenilaian>
    </div>
</template>
