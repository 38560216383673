<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import DetailPotensi from "./detail-potensi/detail-potensi.vue";
import { decryptData } from "@/helpers/crypt.js";
export default {
  components: {
    Datatable,
    Pagination,
    DetailPotensi,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_kompetensi_peg" },
      {
        width: "auto",
        label: "NIP",
        name: "ja_nip",
      },
      {
        width: "auto",
        label: "Nama",
        name: "ja_nama",
      },
      {
        width: "auto",
        label: "ID Jadwal",
        name: "jadwal_id",
      },
      {
        width: "auto",
        label: "Jenis Kompetensi",
        name: "j_txt_dirkom",
      },
      {
        width: "auto",
        label: "Tanggal Asesmen",
        name: "j_tanggal_mulai",
      },
      {
        width: "auto",
        label: "Aksi",
        name: "-",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    let sortOrders2 = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns2 = [
      { width: "50px", label: "-", name: "-" },
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      {
        width: "auto",
        label: "NIP",
        name: "ja_nip",
      },
      {
        width: "auto",
        label: "Nama",
        name: "ja_nama",
      },
      {
        width: "auto",
        label: "Unit",
        name: "ja_unit",
      },
      {
        width: "auto",
        label: "Jabatan",
        name: "ja_jabatan",
      },
      {
        width: "auto",
        label: "Status",
        name: "status_jadwal_ap",
      },
    ];

    // cek sorting
    columns2.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      option_asesmen_grade: this.data_jadwal.asesmen_grade,
      selected_id_asesmen_grade: "",
      selected_asesmen_grade: [],

      idjadwal: decryptData(this.$route.params.id),
      selected_nama: "",
      idjadwal_peserta: "",
      table_data: [],
      loadingTable: true,
      is_process: false,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      selected_peserta: [],
      sessionRoleId: localStorage.session_role_active_id,
      syncData: {
        idjadwal: "",
        lastSync: "",
      },
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        idjadwal: this.$route.params.id,
        role_id: localStorage.session_role_active_id,
        username: localStorage.session_username,
        m_dirkom_idm_dirkom: "",
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      checkedAll: false,
      table_data2: [],
      loadingTable2: true,
      columns2: columns2,
      sortKey2: "id_role", // sort key awal
      sortOrders2: sortOrders2,
      currentTablePage2: "",
      // parsing data ke table data
      tableData2: {
        draw: 0,
        length: 50,
        search: "",
        column: 0,
        dir: "asc",
        idjadwal: this.$route.params.id,
        completed_idasesi: [],
        role_id: localStorage.session_role_active_id,
        username: localStorage.session_username,
        m_dirkom_idm_dirkom: "",
      },
      // config untuk paginasi
      pagination2: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      path_file_import: "",

      showDetailPotensi: false,
      selected_nip: "",
    };
  },
  mounted() {
    // let self = this;

    this.tableData.idjadwal = decryptData(this.$route.params.id);
    this.tableData2.idjadwal = decryptData(this.$route.params.id);
    // self.getDataTable();
    this.syncCompletedAP();
    this.getLastSync();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
    this.searchDatatable2 = debounce(() => {
      this.getDataTable2();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pegpog/penilaian-complete-potensi"
    ) {
      let self = this;
      self.loadingTable = true;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            self.loadingTable = false;
            self.table_data.forEach((element) => {
              self.tableData2.completed_idasesi.push(element.ja_idasesi);
            });

            this.getDataTable2();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDataTable2(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pegpog/penilaian-uncomplete-potensi"
    ) {
      let self = this;
      self.loadingTable2 = true;

      var config = {
        method: "get",
        url: url,
        params: self.tableData2,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data2 = response_data_fix.list_data.data;
            self.configPagination2(response_data_fix.list_data);
            self.currentTablePage2 = response_data_fix.list_data.current_page;
            self.loadingTable2 = false;
            console.log(self.table_data2);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination2(data) {
      let self = this;
      self.pagination2.lastPage = data.last_page;
      self.pagination2.currentPage = data.current_page;
      self.pagination2.total = data.total;
      self.pagination2.lastPageUrl = data.last_page_url;
      self.pagination2.nextPageUrl = data.next_page_url;
      self.pagination2.prevPageUrl = data.prev_page_url;
      self.pagination2.from = data.from;
      self.pagination2.to = data.to;
      self.pagination2.links = data.links;
    },
    sortBy2(key) {
      let self = this;
      self.sortKey2 = key;
      self.sortOrders2[key] = self.sortOrders2[key] * -1;
      self.tableData2.column = self.getIndex(self.columns, "name", key);
      self.tableData2.dir = self.sortOrders2[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex2(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage2: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("DD-MM-YYYY HH:mm");
    },
    openDetailModal(nip, nama, idjadwal_peserta) {
      this.showDetailPotensi = !this.showDetailPotensi;
      this.selected_nama = nama;
      this.selected_nip = nip;
      this.idjadwal_peserta = idjadwal_peserta;
    },
    syncCompletedAP(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pegpog/sync-complete-potensi"
    ) {
      let self = this;
      self.loadingTable2 = true;
      self.syncData.idjadwal = self.idjadwal;
      self.is_process = true;
      var config = {
        method: "get",
        url: url,
        params: self.syncData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            this.getLastSync();
            // Swal.fire({
            //   icon: "success",
            //   title: "Berhasil!",
            //   text: response.data.meta.message,
            // });
            self.is_process = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.meta.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getLastSync(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pegpog/last-sync-complete-potensi"
    ) {
      let self = this;
      self.loadingTable2 = true;
      self.syncData.idjadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.syncData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          this.syncData.lastSync = response.data.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    checkPeserta() {
      let self = this;
      if (this.checkedAll) {
        self.table_data2.forEach((option) => {
          self.selected_peserta.push(option);
        });
      } else {
        self.selected_peserta = [];
      }
    },
    onChangeAsesmenGrade() {
      this.selected_id_asesmen_grade =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.tableData.m_dirkom_idm_dirkom =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.tableData2.m_dirkom_idm_dirkom =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.getDataTable();
      this.getDataTable2();
    },
    exportCompletePotensi() {
      let self = this;
      self.tableData.idjadwal = self.idjadwal;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/export-complete-potensi",
        responseType: "blob",
        crossdomain: true,
        params: this.tableData,
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename =
          "Export Potensi" +
          new Date().toISOString().slice(0, 10) +
          ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },

    exportUncompletePotensi() {
      let self = this;
      self.tableData.idjadwal = self.idjadwal;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/export-uncomplete-potensi",
        responseType: "blob",
        crossdomain: true,
        params: this.tableData,
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename =
          "Export_peserta_belum_potensi" +
          new Date().toISOString().slice(0, 10) +
          ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-4">
        <label for="Batch">Pilih Asesmen Grade</label>
        <v-select
          :options="option_asesmen_grade"
          label="nama_jenis_asesmen"
          v-model="selected_asesmen_grade"
          @update:modelValue="onChangeAsesmenGrade()"
          placeholder="-Pilih Jenis Asesmen-"
          class="text-secondary"
        ></v-select>
      </div>
    </div>
    <div class="row mt-4" v-if="selected_id_asesmen_grade == ''">
      <b-alert variant="primary" show
        >Silahkan pilih salah satu Asesmen Grade</b-alert
      >
    </div>
    <div v-else>
      <div class="row">
        <div class="row">
          <div class="col-6">
            <h4>Peserta yang Punya Nilai Potensi</h4>
          </div>
          
          <div class="col-6 text-end">
            <div class="btn-group" role="group" v-if="sessionRoleId != 9">
              <!-- <button type="button" class="btn btn-success" @click="syncCompletedAP()" v-if="is_process == false">
              Refresh Data
            </button>
            <button type="button" class="btn btn-warning text-dark" disabled v-if="is_process == true">Proses...</button> -->
              <!-- <button type="button" class="btn btn-outline-success text-dark" @click="syncCompletedAP()">
              {{
                syncData.lastSync.updated_at
                ? fullDateTimeFormat(syncData.lastSync.updated_at)
                : "-"
              }}
            </button> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <div class="row mb-3"></div>
            <hr />
            <div class="col-12 text-end">
              <button
                type="button"
                class="btn btn-primary"
                @click="exportCompletePotensi()"
              >
                Export
              </button>
            </div>
            <div class="row">
              <div class="col-md-4">
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                        style="width: 100%"
                        v-model="tableData.length"
                        @change="getDataTable()"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-5">&nbsp;</div>
              <div class="col-md-3">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="searchTable"
                  placeholder="Cari Data ..."
                  v-model="tableData.search"
                  @input="searchDatatable"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <datatable
            :columns="columns"
            :sortKey="sortKey"
            :sortOrders="sortOrders"
            @sort="sortBy"
          >
            <tbody>
              <tr v-if="loadingTable">
                <td colspan="6">
                  <i class="fas fa-spinner fa-spin"></i> Loading...
                </td>
              </tr>
              <tr v-else-if="table_data == ''">
                <td class="text-center" colspan="6">Data Tidak Tersedia</td>
              </tr>
              <tr
                v-else
                v-for="(row_data, key_data) in table_data"
                :key="key_data"
              >
                <td class="text-center">
                  <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                  <div v-else>
                    {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                    }}{{ key_data + 1 }}.
                  </div>
                </td>
                <td>{{ row_data.ja_nip }}</td>
                <td>{{ row_data.ja_nama }}</td>
                <td>{{ row_data.idjadwal }}</td>
                <td>{{ row_data.j_txt_dirkom }}</td>
                <td>{{ row_data.j_tanggal_mulai }}</td>
                <td class="text-center">
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="
                      openDetailModal(
                        row_data.ja_nip,
                        row_data.ja_nama,
                        row_data.idjadwal
                      )
                    "
                  >
                    <i class="fa fa-eye"></i> Detail
                  </button>
                </td>
              </tr>
            </tbody>
          </datatable>
        </div>
        <div class="row">
          <pagination :pagination="pagination" @to-page="toPage"></pagination>
        </div>
      </div>
      <div class="row mt-5">
        <div class="row">
          <h4>Peserta yang belum Punya Nilai Potensi</h4>
        </div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <div class="row mb-3"></div>
            <hr />
            <div class="row">
              <div class="col-12 text-end">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="exportUncompletePotensi()"
                >
                  Export
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <!-- <button v-if="selected_peserta.length > 0" class="btn btn-info btn-sm me-2" @click="checkAllPeserta()">
                Pilih Peserta
              </button> -->
                <router-link
                  class="btn btn-success btn-sm"
                  v-if="selected_peserta.length > 0"
                  :to="{
                    name: 'add-jadwal-potensi',
                    params: {
                      id: idjadwal,
                      // data_peserta : selected_peserta
                      // dataPeserta: JSON.stringify(selected_peserta),
                    },
                    state: {
                      data_peserta: JSON.stringify(selected_peserta),
                      data_jadwal: JSON.stringify(data_jadwal),
                    },
                  }"
                  ><i class="fa fa-edit"></i> Buat Jadwal Asesmen
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                        style="width: 100%"
                        v-model="tableData2.length"
                        @change="getDataTable2()"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-5">&nbsp;</div>
              <div class="col-md-3">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="searchTable"
                  placeholder="Cari Data ..."
                  v-model="tableData2.search"
                  @input="searchDatatable2"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <table class="table mb-0 table-bordered table-condensed table-hover">
            <thead class="bg-dark text-center text-white">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    v-model="checkedAll"
                    @change="checkPeserta($event)"
                  />
                </th>
                <th>No</th>
                <th>Nip</th>
                <th>Nama</th>
                <th>Unit</th>
                <th>Jabatan</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loadingTable">
                <td colspan="6">
                  <i class="fas fa-spinner fa-spin"></i> Loading...
                </td>
              </tr>
              <tr v-else-if="table_data2 == ''">
                <td class="text-center" colspan="6">Data Tidak Tersedia</td>
              </tr>
              <tr
                v-else
                v-for="(row_data, key_data) in table_data2"
                :key="key_data"
              >
                <td>
                  <div v-if="row_data?.status_jadwal_ap == false">
                    <input
                      type="checkbox"
                      :id="row_data.id_jadwalasesi"
                      v-model="selected_peserta"
                      :value="row_data"
                    />
                  </div>
                </td>
                <td class="text-center">
                  <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                  <div v-else>
                    {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                    }}{{ key_data + 1 }}.
                  </div>
                </td>
                <td>{{ row_data.ja_nip }}</td>
                <td>{{ row_data.ja_nama }}</td>
                <td>{{ row_data.ja_unit }}</td>
                <td>{{ row_data.ja_jabatan }}</td>
                <td class="text-center">
                  <template v-if="row_data?.status_jadwal_ap == false">
                    <span class="badge bg-danger"
                      >Jadwal Asesmen Psikogologi Belum Dibuat</span
                    >
                  </template>
                  <template v-else>
                    <span class="badge bg-success">
                      Jadwal Asesmen Psikogologi Sudah Dibuatt</span
                    >
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <pagination :pagination="pagination2" @to-page="toPage2"></pagination>
        </div>
      </div>
    </div>
  </div>
  <b-modal
    v-model="showDetailPotensi"
    id="modal-lg"
    size="lg"
    :title="'Detail Nilai Potensi ' + selected_nama"
    title-class="font-18"
    hide-footer
  >
    <DetailPotensi
      v-if="showDetailPotensi"
      :nip="selected_nip"
      :id_jadwal="idjadwal"
      :idjadwal_peserta="this.idjadwal_peserta"
      :id_jenis_asesmen="this.selected_asesmen_grade.id_jenis_asesmen"
    />
  </b-modal>
</template>
