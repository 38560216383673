<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { decryptData } from "@/helpers/crypt.js";
export default {
  components: {
    Datatable,
    Pagination,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_kompetensi_peg" },
      {
        width: "auto",
        label: "Nip",
        name: "inisial_kompetensi_group",
      },
      {
        width: "auto",
        label: "Nama",
        name: "nama_jenisasesmen_source",
      },
      {
        width: "auto",
        label: "Level",
        name: "inisial_kompetensi_source",
      },
      {
        width: "auto",
        label: "Unit",
        name: "inisial_kompetensi_source",
      },
      {
        width: "auto",
        label: "Detail",
        name: "inisial_kompetensi_source",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    let sortOrders2 = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns2 = [
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      {
        width: "auto",
        label: "NIP",
        name: "inisial_kompetensi_target",
      },
      {
        width: "auto",
        label: "Nama",
        name: "nama_alat_tes",
      },

      {
        width: "auto",
        label: "Tanggal Mulai",
        name: "nama_alat_tes",
      },
      {
        width: "auto",
        label: "Status",
        name: "nama_alat_tes",
      },
      { width: "auto", label: "Aksi", name: "nama_aspek" },
    ];

    // cek sorting
    columns2.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      idjadwal: decryptData(this.$route.params.id),

      showModalAddPeserta: false,
      option_user: [],
      selected_user: [],

      table_data: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        m_dirkom_idm_dirkom: this.data_jadwal.m_dirkom_idm_dirkom,
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      table_data2: [],
      loadingTable2: true,
      columns2: columns2,
      sortKey2: "id_role", // sort key awal
      sortOrders2: sortOrders2,
      currentTablePage2: "",
      // parsing data ke table data
      tableData2: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        idjadwal: "",
        m_dirkom_idm_dirkom: this.data_jadwal.m_dirkom_idm_dirkom,
      },
      // config untuk paginasi
      pagination2: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      path_file_import: "",
    };
  },
  mounted() {
    this.getUser();
    this.getDataTable();
    this.getDataTable2();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/penjadwalan/detail-rekrutmen/get-asesor"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.idjadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDataTable2(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/penjadwalan/detail-rekrutmen/get-asesor-request"
    ) {
      let self = this;
      self.loadingTable2 = true;
      self.tableData2.idjadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData2,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data2 = response_data_fix.list_data.data;
            self.configPagination2(response_data_fix.list_data);
            self.currentTablePage2 = response_data_fix.list_data.current_page;
            self.loadingTable2 = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    configPagination2(data) {
      let self = this;
      self.pagination2.lastPage = data.last_page;
      self.pagination2.currentPage = data.current_page;
      self.pagination2.total = data.total;
      self.pagination2.lastPageUrl = data.last_page_url;
      self.pagination2.nextPageUrl = data.next_page_url;
      self.pagination2.prevPageUrl = data.prev_page_url;
      self.pagination2.from = data.from;
      self.pagination2.to = data.to;
      self.pagination2.links = data.links;
    },
    sortBy2(key) {
      let self = this;
      self.sortKey2 = key;
      self.sortOrders2[key] = self.sortOrders2[key] * -1;
      self.tableData2.column = self.getIndex(self.columns, "name", key);
      self.tableData2.dir = self.sortOrders2[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex2(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage2: function (str) {
      let self = this;
      self.getDataTable(str);
    },

    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    myFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return (
        option.u_nama.toLowerCase().indexOf(temp) > -1 ||
        option.u_nip.toLowerCase().indexOf(temp) > -1
      );
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/asesor-ac",
        params: {
          keyword: String(search),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_user = response.data.data.referensi;

          loading(false);
        }
      });
    },
    getUser() {
      let self = this;
      var config = {
        method: "get",
        params: {
          keyword: "",
        },
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/asesor-ac",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_user = response.data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    submitAsesor() {
      let self = this;
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/penjadwalan/rekrutmen/store-asesor",
        data: {
          asesor: self.selected_user,
          id_jadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Menambahkan Tester",
              timer: 500,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                self.getDataTable();
                self.getDataTable2();
                self.showModalAddPeserta = false;
              }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/penjadwalan/rekrutmen/delete-asesor",
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                  self.getDataTable2();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <span class="h4">Asesor</span>
          <div class="d-inline-block" v-if="data_jadwal.j_status == 'Draft'">
            <a
              class="btn btn-sm btn-primary"
              @click="showModalAddPeserta = !showModalAddPeserta"
            >
              <span><i class="fa fa-plus"></i> Tambah Tester Eksternal</span></a
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-2">
          <hr />
          <div class="row">
            <div class="col-md-4">
              <table>
                <tr>
                  <td>Show</td>
                  <td>
                    <select
                      class="form-control form-control-sm"
                      id="showpaginatetable"
                      style="width: 100%"
                      v-model="tableData.length"
                      @change="getDataTable()"
                    >
                      <option value="10" selected>10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </td>
                  <td>Entries</td>
                </tr>
              </table>
            </div>
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-3">
              <input
                type="text"
                class="form-control form-control-sm"
                id="searchTable"
                placeholder="Cari Data ..."
                v-model="tableData.search"
                @input="searchDatatable"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <datatable
          :columns="columns"
          :sortKey="sortKey"
          :sortOrders="sortOrders"
          @sort="sortBy"
        >
          <tbody>
            <tr v-if="loadingTable">
              <td colspan="6">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else-if="table_data == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr
              v-else
              v-for="(row_data, key_data) in table_data"
              :key="key_data"
            >
              <td class="text-center">
                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                <div v-else>
                  {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                  }}{{ key_data + 1 }}.
                </div>
              </td>
              <td>{{ row_data.jr_nip }}</td>
              <td>{{ row_data.jr_nama }}</td>
              <td>{{ row_data.jr_level ?? "-" }}</td>
              <td>{{ row_data.jr_unit }}</td>
              <td class="text-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    v-on:click="deleteData(row_data.id_jadwalasesor)"
                  >
                    <i class="fa fa-trash"></i> Hapus
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </datatable>
      </div>
      <div class="row">
        <pagination :pagination="pagination" @to-page="toPage"></pagination>
      </div>
    </div>
    <div class="row mt-5">
      <div class="row">
        <h5>Asesor Request</h5>
      </div>
      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="row mb-3"></div>
          <hr />
          <div class="row">
            <div class="col-md-4">
              <table>
                <tr>
                  <td>Show</td>
                  <td>
                    <select
                      class="form-control form-control-sm"
                      id="showpaginatetable"
                      style="width: 100%"
                      v-model="tableData2.length"
                      @change="getDataTable2()"
                    >
                      <option value="10" selected>10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </td>
                  <td>Entries</td>
                </tr>
              </table>
            </div>
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-3">
              <input
                type="text"
                class="form-control form-control-sm"
                id="searchTable"
                placeholder="Cari Data ..."
                v-model="tableData2.search"
                @input="searchDatatable2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <datatable
          :columns="columns2"
          :sortKey="sortKey2"
          :sortOrders="sortOrders2"
          @sort="sortBy2"
        >
          <tbody>
            <tr v-if="loadingTable">
              <td colspan="6">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else-if="table_data == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr
              v-else
              v-for="(row_data, key_data) in table_data2"
              :key="key_data"
            >
              <td class="text-center">
                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                <div v-else>
                  {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                  }}{{ key_data + 1 }}.
                </div>
              </td>
              <td>{{ row_data.jar_nip }}</td>
              <td>{{ row_data.jar_nama }}</td>
              <td>{{ row_data.jar_jadwal_tanggal_mulai }}</td>
              <td>{{ row_data.jar_status }}</td>

              <td class="text-center">
                <div class="btn-group" v-if="row_data.jar_status == 'Menunggu'">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    v-on:click="removeCart(index)"
                  >
                    <i class="fa fa-check"></i> Terima
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    v-on:click="removeCart(index)"
                  >
                    <i class="fa fa-times"></i> Tolak
                  </button>
                </div>
                <div v-else>-</div>
              </td>
            </tr>
          </tbody>
        </datatable>
      </div>
      <div class="row">
        <pagination :pagination="pagination2" @to-page="toPage2"></pagination>
      </div>
    </div>
  </div>
  <b-modal
    v-model="showModalAddPeserta"
    id="modal-lg"
    size="lg"
    title="Tambah Tester"
    title-class="font-18"
    hide-footer
  >
    <div class="row">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Asesor" label-for="lokasi">
          <v-select
            :options="option_user"
            multiple
            label="u_nama"
            :filterBy="myFilter"
            placeholder="-Cari NIP atau Nama Tester-"
            v-model="selected_user"
            @search="onSearchUser"
          >
            <template v-slot:option="option">
              <span :class="option.u_nama"></span>
              {{ option.u_nama }} -
              <span :class="option.u_nip"></span>
              {{ option.u_nip }}
            </template></v-select
          >
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="d-grid gap-2 col-6 mx-auto">
        <button class="btn btn-primary" type="button" @click="submitAsesor()">
          Simpan
        </button>
      </div>
    </div>
  </b-modal>
</template>
