import CryptoJS from "crypto-js";

const SECRET_KEY = "123";

export function encryptData(data) {
  var data_for_decrypt = CryptoJS.AES.encrypt(data, SECRET_KEY, {
    mode: CryptoJS.mode.CBC,
  }).toString();
  return encodeURIComponent(data_for_decrypt);
}

export function decryptData(encryptedData) {
  var enkrip_data_url= decodeURIComponent(encryptedData);
  return CryptoJS.AES.decrypt(enkrip_data_url, SECRET_KEY, {
    mode: CryptoJS.mode.CBC,
  }).toString(CryptoJS.enc.Utf8);
}
