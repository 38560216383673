<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
// import Swal from "sweetalert2";
// import $ from "jquery";
import { encryptData } from "@/helpers/crypt.js";
import { decryptData } from '@/helpers/crypt.js';
import { debounce } from "@/helpers/debounce";

export default {
  page: {
    title: "Validasi Asesi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    let sortOrders = []; // select sortOrder

    return {
      table_data: null,
      idjadwal: decryptData(this.$route.params.id),
      loadingTable: true,
      // columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      selected_temp: [],
      selected_temp_new: [],

      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 999,
        search: "",
        column: 0,
        dir: "asc",
        id_jadwal: "",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    this.getDataTemporaryPerbedaan();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTemporaryPerbedaan();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    getDataTemporaryPerbedaan() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/vertifikasi/get-temporary-perbedaan",
        params: {
          idjadwal: self.idjadwal,
          user_id: localStorage.session_user_id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response.data.data[0]
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.data;
            self.selected_temp_new = self.table_data;
            self.pagination = response_data_fix.pagination;
            self.currentTablePage = response_data_fix.current_page;
            self.loadingTable = false;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    updateDataTemporary() {
      let selected_temp_data_old;
      // let selected_temp_data_new;
      let self = this;

      // selected_temp_data_new = self.selected_temp_new;
      selected_temp_data_old = self.selected_temp;


      // selected_temp_data_new = selected_temp_data_new.filter(item1 => {
      //   const matchingItem = selected_temp_data_old.find(item2 => item2.id_tempperbedaan === item1.id_tempperbedaan);
      //   return !matchingItem;
      // });


      var config = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/vertifikasi/update-temporary",
        params: {
          idjadwal: this.idjadwal,
          user_id: localStorage.session_user_id,
          selected_temp: selected_temp_data_old,
          // selected_temp_new: selected_temp_data_new,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      }
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            this.$router.push({
              name: "import-asesi-asesmen-center",
              params: {
                id: this.encryptedUrl(this.idjadwal.toString()),
              },
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="card-title">Validasi Asesi</div>
                <hr />
              </div>
              <div class="col-lg-12">
                <table class="table mb-0 table-bordered table-condensed table-hover">
                  <thead class="bg-dark text-center text-white">
                    <tr>
                      <th class="text-center">No</th>
                      <th class="text-center" style="width:100px;">NIP</th>
                      <th class="text-center" style="width:100px;">Gunakan data lama</th>
                      <th class="text-center" style="width:100px;">Keterangan</th>
                      <th class="text-center" style="width: auto;">NAMA</th>
                      <th class="text-center" style="width: auto;">UNIT</th>
                      <th class="text-center" style="width: auto;">JABATAN SAAT INI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="6">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" :colspan="5 + colspan">Data Tidak Tersedia</td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                      <td class="text-center" style="vertical-align: middle;">
                        <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                        <div v-else>
                          {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                          }}{{ key_data + 1 }}.
                        </div>
                      </td>
                      <td class="text-center" style="vertical-align: middle;">
                        {{ row_data.nip }}
                      </td>
                      <td class="text-center" style="vertical-align: middle;">
                        <input type="checkbox" v-model="selected_temp" :value="row_data" />
                      </td>
                      <td class="text-center" style="vertical-align: middle;">
                        Data Lama :
                        <hr>
                        Data Baru :
                      </td>
                      <td class="text-center" style="vertical-align: middle;">
                        {{ row_data.nama_lama || "&#10240;" }}
                        <hr>
                        {{ row_data.nama || "&#10240;" }}
                      </td>
                      <td class="text-center" style="vertical-align: middle;">
                        {{ row_data.unit_lama || "&#10240;" }}
                        <hr>
                        {{ row_data.unit || "&#10240;" }}
                      </td>
                      <td class="text-center" style="vertical-align: middle;">
                        {{ row_data.level_lama || "&#10240;" }}
                        <hr>
                        {{ row_data.level || "&#10240;" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-lg-12 justify-content-end d-flex mt-3">
                <button class="btn btn-primary mr-2" @click="updateDataTemporary">Proses <i
                    class="fas fa-arrow-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.mr-2 {
  margin-right: 0.5rem !important;
}
</style>
