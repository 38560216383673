<script>
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { decryptData } from "@/helpers/crypt.js";
import { encryptData } from "@/helpers/crypt.js";
export default {
  components: {
    Pagination,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "100px", label: "NIP", name: "ja_nip" },
      { width: "150px", label: "Nama", name: "ja_nama" },
      {
        width: "150px",
        label: "Nomer Telepon",
        name: "rekomendasi_kompetensi",
      },
      { width: "150px", label: "Unit", name: "rekomendasi_potensi" },
      { width: "auto", label: "Jabatan saat ini", name: "rekomendasi_atasan" },
      { width: "200px", label: "Konfirmasi" },
      { width: "50px", label: "kehadiran", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      { width: "50px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      idjadwal: decryptData(this.$route.params.id),
      showModalAddPeserta: false,
      option_user: [],

      selected_user: [],
      selectedId: [],
      table_data: [],
      filter_peserta: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      checkedAll: false,
      selectedIdAsesi: [],
      peserta_diundang: this.data_jadwal.jadwal_unit[0].ju_peserta_diundang,
      jumlah_peserta: 0,
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_jadwal: "",
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      path_file_import: "",
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
    this.getDataTable();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/assesitab") {
      let self = this;
      self.loadingTable = true;
      self.tableData.id_jadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.jumlah_peserta = response_data_fix.count;
            self.table_data.forEach((element) => {
              // insert data ja_keteragan = null
              self.filter_peserta.push(element.ja_idasesi);
              if (element.ja_keterangan == null) {
                element.ja_keterangan = null;
              }
            });
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getLaporan(id) {
      let self = this;
      self.laporanData.id_jadwal = self.idjadwal;
      self.laporanData.nip = id;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/laporan-nilai-pegpog",
        params: self.laporanData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          let filename = response_data_fix.filename;
          const downloadUrl = process.env.VUE_APP_BACKEND_URL + `/storage/pdf_laporan_nilai/${filename}`;
          window.open(downloadUrl, "_blank");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    myFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return option.u_nama.toLowerCase().indexOf(temp) > -1 || option.u_nip.toLowerCase().indexOf(temp) > -1;
    },
    showModalTambbahPeserta() {
      this.showModalAddPeserta = true;
      let self = this;
      var config = {
        method: "get",
        params: {
          keyword: "",
          filter_peserta: self.filter_peserta,
        },
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user-peserta",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_user = response.data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user-peserta",
        params: {
          keyword: search,
          filter_peserta: self.filter_peserta,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_user = response.data.data.referensi;
          loading(false);
        }
      });
    },
    submitPeserta() {
      let self = this;
      var count_add_peserta = self.selected_user.length;
      var sum_peserta = count_add_peserta + self.jumlah_peserta;
      if (sum_peserta > self.peserta_diundang) {
        Swal.fire({
          icon: "error",
          title: "Peringatan!",
          text: `Jumlah Peserta Melebihi Batas, Max ${self.peserta_diundang}`,
        });
        return false;
      }
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/store-peserta",
        data: {
          peserta: JSON.stringify(self.selected_user),
          id_jadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            console.log(this.selected_user);
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Menambahkan Peserta",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                this.getDataTable();
                window.location.reload();
              }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: error.response.data.data.message,
            showCancelButton: false,
            showConfirmButton: true, // Show the "OK" button
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload(); // Refresh the page after 3 seconds
            }
          });
        });
    },
    resetFormAddPeserta() {
      this.formAddPeserta = {
        id_jadwal: this.idjadwal,
        nip: "",
      };
    },

    ConfirmResetAssesi() {
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin mereset Asesi?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetAsesi();
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },

    resetAsesi() {
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/assesitab/reset-asesi",
        data: {
          id_jadwal: this.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Reset Asesi",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                this.getDataTable();
              }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
    },
    getExport() {
      this.tableData.id_jadwal = this.idjadwal;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/assesitab/export-asesi",
        responseType: "blob",
        crossdomain: true,
        params: this.tableData,
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename = "Export-Asesi" + new Date().toISOString().slice(0, 10) + ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },
    ValidasideletePeserta(data) {
      console.log(data);
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus peserta ini?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePeserta(data);
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },
    deletePeserta(id, id_asesi) {
      console.log(id);
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "jadwal-asesi-peserta",
            data: {
              id_jadwalasesi: id,
              idjadwal: self.idjadwal,
              id_asesi: id_asesi,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            if (response.data.meta.code == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Menghapus Data Peserta ",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    konfirmasiKehadiranPeserta(data) {
      var config = {
        method: "POST",
        data: {
          id_jadwal: this.idjadwal,
          asesi: this.table_data,
          id_jadwalasesi: data.id_jadwalasesi,
          user_id: localStorage.session_user_id,
        },
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/assesitab/konfirmasi-kehadiran-asesi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            this.getDataTable();
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
    },
    updateStatus(status) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin Mengirim data jadwal ke PTL?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/update-status",
            data: {
              id: self.idjadwal,
              tipe: "Assesment",
              tipe_jadwal: "AC",
              status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.meta.code === 200) {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Berhasil Kirim PTL ",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.close();
                    window.location.reload();
                  }
                });
              } else {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  text: "Gagal Mengubah Status ",
                  showConfirmButton: true,
                });
              }
            })
            .catch((response) => {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Gagal",
                text: "Terjadi kesalahan: " + response.response.data.data[0],
                showConfirmButton: true,
              });
            });
        } else {
          Swal.close();
        }
      });
    },
    onSubmit() {
      console.log(this.table_data);
    },
    checkPeserta() {
      let self = this;
      if (this.checkedAll) {
        self.table_data.forEach((option) => {
          console.log(option);
          self.selectedId.push(option.id_jadwalasesi);
          self.selectedIdAsesi.push(option.ja_idasesi);
        });
      } else {
        self.selectedId = [];
        self.selectedIdAsesi = [];
      }
    },
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-sm btn-danger" @click="deletePeserta(selectedId, selectedIdAsesi)"
          v-if="selectedId.length > 0 && sessionRoleId != 9"><i class="fa fa-trash"></i> Hapus Terpilih</button>
      </div>
      <div class="col-md-6 mb-2" v-if="data_jadwal.j_status == 'Draft'">
        <div class="d-inline-block d-flex justify-content-end">
          <a class="btn btn-sm btn-danger mr-2" @click="ConfirmResetAssesi"><i class="fas fa-redo-alt mr-2"></i>Reset
            Asesi</a>
          <a class="btn btn-sm btn-success mr-2" @click="getExport"><i class="fas fa-file-excel mr-2"></i>Export
            Asesi</a>
          <router-link :to="{
            name: 'import-asesi-asesmen-center',
            params: {
              id: this.encryptedUrl(idjadwal.toString()),
            },
          }" class="btn btn-sm btn-success mr-2"><i class="fas fa-file-import mr-2"></i>Import Asesi
          </router-link>
          <a class="btn btn-sm btn-info" @click="showModalTambbahPeserta()"> <i class="fa fa-plus"></i> Tambah Peserta
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-2">
        <div class="row mb-3"></div>
        <hr />
        <div class="row">
          <div class="col-md-4">
            <table>
              <tr>
                <td>Show</td>
                <td>
                  <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%"
                    v-model="tableData.length" @change="getDataTable()">
                    <option value="10" selected>10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
          </div>
          <div class="col-md-5">&nbsp;</div>
          <div class="col-md-3">
            <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..."
              v-model="tableData.search" @input="searchDatatable" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <b-form @submit="konfirmasiKehadiranPeserta" @reset="onReset">
        <table class="table mb-0 table-bordered table-condensed table-hover">
          <thead class="bg-dark text-center text-white">
            <tr>
              <th v-if="data_jadwal.j_status == 'Draft'">
                <input type="checkbox" v-model="checkedAll" @change="checkPeserta($event)" />
              </th>
              <th>No</th>
              <th>Nip</th>
              <th>Nama</th>
              <th>Nomor Telepon</th>
              <th>Unit</th>
              <th>Jabatan Saati Ini</th>
              <th>Konfirmasi</th>
              <th>Kehadiran</th>
              <th v-if="sessionRoleId != 9">Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loadingTable">
              <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
            </tr>
            <tr v-else-if="table_data == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
              <td v-if="data_jadwal.j_status == 'Draft'">
                <div>
                  <input type="checkbox" :id="row_data.id_jadwalasesi" v-model="selectedId"
                    :value="row_data.id_jadwalasesi" />
                </div>
              </td>
              <td class="text-center">
                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
              </td>
              <td>{{ row_data.ja_nip }}</td>
              <td>{{ row_data.ja_nama }}</td>
              <td>{{ row_data.u_no_whatsapp }}</td>
              <td>{{ row_data.ja_unit }}</td>
              <td>
                <input type="text" class="form-control form-control-sm" v-model="row_data.u_level" />
              </td>
              <td style="background: #ccc">
                <div style="display: flex">
                  <select class="form-control mx-2" style="width: 30%" v-model="row_data.ja_kehadiran">
                    <option value="0">TH</option>
                    <option value="1">H</option>
                  </select>
                  <input type="text" style="width: 70%" class="form-control" v-model="row_data.ja_keterangan" />
                </div>
              </td>
              <td class="text-center">
                <i v-if="row_data.ja_kehadiran == 1" class="fas fa-check-circle text-success"></i>
                <i v-else class="fas fa-times-circle text-danger"></i>
              </td>
              <td class="text-center">
                <a v-if="data_jadwal.j_status == 'Draft'" class="btn btn-sm btn-danger"
                  @click="deletePeserta([row_data.id_jadwalasesi])">
                  <i class="fas fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style="background: #ccc" class="text-center">
                <button type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> Simpan
                  Konfirmasi</button>
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </b-form>
    </div>
    <div class="row">
      <pagination :pagination="pagination" @to-page="toPage"></pagination>
    </div>
    <div class="row">
      <div class="col-12 text-end">
        <button class="btn btn-success btn-md" @click="updateStatus('proses_ptl')"
          v-if="sessionRoleId != 9 && data_jadwal.j_status == 'Draft'">Kirim Ke PTL</button>
      </div>
    </div>
  </div>
  <!-- modal tambah asesi -->
  <b-modal v-model="showModalAddPeserta" id="modal-lg" size="lg" title="Tambah Peserta" title-class="font-18"
    hide-footer>
    <div class="row">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Masukkan Peserta" label-for="lokasi">
          <v-select :options="option_user" multiple label="u_nama" placeholder="-Cari NIP atau Nama Peserta-"
            v-model="selected_user" @search="onSearchUser" :filterBy="myFilter">
            <template v-slot:option="option">
              <span :class="option.u_nama"></span>
              {{ option.u_nama }} -
              <span :class="option.u_nip"></span>
              {{ option.u_nip }}
            </template></v-select>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="d-grid gap-2 col-6 mx-auto">
        <button class="btn btn-primary" type="button" @click="submitPeserta()">Simpan</button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}
</style>
