<script>
import appConfig from "@/app.config";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Penilaian PEG POG",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Datatable,
    Pagination,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      { width: "auto", label: "NIP", name: "ja_nip" },
      { width: "auto", label: "Nama", name: "ja_nama" },
      { width: "auto", label: "Jabatan", name: "ja_jabatan" },
      { width: "auto", label: "Jenjang Jabatan", name: "ja_jenjangjabatan" },
      { width: "150px", label: "Nilai", name: "-" },
      { width: "150px", label: "Status", name: "status_nilai_atasan" },
      { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Penilaian PEG POG",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Penilaian PEG POG",
          active: true,
        },
      ],
      option_asesmen_grade: this.data_jadwal.asesmen_grade,
      selected_id_asesmen_grade: "",
      selected_asesmen_grade: [],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      showModalUpdateNilai: false,
      nilai: "",
      id_survey: "",
      // option_jenis_asesmen: [],

      selected_jenis_asesmen: [],
      // parsing data ke table data

      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_nip: JSON.parse(localStorage.getItem('session')).user.username,
        idjadwal: this.$route.params.id,
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    // let self = this;
    // self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    cekAksesMenu() {
      let self = this;
      var config_axios = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
        params: {
          role_id: self.sessionRoleId,
          link_name: self.namePathUrl,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_axios)
        .then((response) => {
          let response_data_fix = response.data.data.status_access;
          if (response.data.meta.code == 200) {
            if (response_data_fix == false) {
              Swal.fire({
                icon: "warning",
                title: "Oppss",
                text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({ name: "dashboard" });
                }
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onChangeAsesmenGrade() {
      this.selected_id_asesmen_grade =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.tableData.m_dirkom_idm_dirkom =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.getDataTable();
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/penilaian-pegpog"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;
      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            if (self.tableData.draw == response_data_fix.draw) {
              self.table_data = response_data_fix.list_data.data;
              self.configPagination(response_data_fix.list_data);
              self.currentTablePage = response_data_fix.list_data.current_page;
            }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    jumperLogin(params) {
      const baseUrl = process.env.VUE_APP_GLOBAL_URL + 'Login_jump/index/';
      const queryString = params;
      return `${baseUrl}${queryString}`;
    },
    checkDate(date) {
      const targetDate = new Date(date);
      const currentDate = new Date();
      return targetDate > currentDate;
    },
    updateNilai() {
      let self = this;
      var id = self.id_survey;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin mengubah nilai?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/penilaian-pegpog/edit-nilai",
            data: {
              id: id,
              nilai: self.nilai,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            if (response.data.meta.code == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Mengubah Nilai",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="row">
          <div class="col-4">
            <label for="Batch">Pilih Asesmen Grade</label>
            <v-select :options="option_asesmen_grade" label="nama_jenis_asesmen" v-model="selected_asesmen_grade"
              @update:modelValue="onChangeAsesmenGrade()" placeholder="-Pilih Jenis Asesmen-"
              class="text-secondary"></v-select>
          </div>
        </div>
        <div class="row mt-4" v-if="selected_id_asesmen_grade == ''">
          <b-alert variant="primary" show>Silahkan pilih salah satu Asesmen Grade</b-alert>
        </div>
        <div v-else>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%"
                            v-model="tableData.length" @change="getDataTable()">
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..."
                      v-model="tableData.search" @input="getDataTable()" />
                  </div>
                </div>
              </div>
              <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="6">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                  </tr>
                  <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                    <td class="text-center">
                      <div v-if="key_data + 1 == 10">
                        {{ currentTablePage }}0.
                      </div>
                      <div v-else>
                        {{
              currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
            }}{{ key_data + 1 }}.
                      </div>
                    </td>
                    <td>
                      {{ row_data.ja_nip }}
                    </td>
                    <td>
                      {{ row_data.ja_nama }}
                    </td>
                    <td>
                      {{ row_data.ja_jabatan }}
                    </td>
                    <td>
                      {{ row_data.ja_jenjangjabatan }}
                    </td>
                    <td>
                      {{ (row_data.survey_atasan[0]?.nilai) ? row_data.survey_atasan[0].nilai : "-" }}
                    </td>
                    <td v-if="row_data.status_nilai_atasan == true" class="text-center">
                      Sudah Dinilai
                    </td>
                    <td v-else class="text-center">
                      Belum Dinilai
                    </td>
                    <td class="text-center">
                      <div>
                        <div class="btn-group" v-if="checkDate(data_jadwal.j_tanggal_sampai)">
                          <a target="_blank" :href="jumperLogin(row_data.encoded_nip)" class="btn btn-info btn-sm"><i
                              class="fa fa-edit"></i>Nilai</a>
                          <!-- <button v-if="row_data.status_nilai_atasan == true" class="btn btn-warning btn-sm" @click="() => {
                          showModalUpdateNilai = true;
                          id_survey = row_data.survey_atasan[0]?.id_survey;
                          nilai = row_data.survey_atasan[0]?.nilai;
                        }">
                          Edit Nilai
                        </button> -->
                        </div>
                        <!-- <a v-else class="btn btn-info btn-sm disabled"><i class="fa fa-edit"></i>Nilai</a> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-modal v-model="showModalUpdateNilai" id="modal-lg" size="lg" title="Edit Nilai" title-class="font-18" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <label for="formImport" class="form-label">Edit Nilai</label>
          <input type="text" class="form-control" v-model="nilai">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button type="button" class="btn btn-primary" @click="updateNilai()">
            <i class="fa fa-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
