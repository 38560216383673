<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import $ from "jquery";
import { debounce } from "@/helpers/debounce";
import axios from "axios";
import Swal from "sweetalert2";
import { decryptData } from "@/helpers/crypt.js";
export default {
  components: {
    Datatable,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "Keterangan", name: "-" },
      { width: "auto", label: "File", name: "-" },

    ];

    let columns2 = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "Target", name: "-" },
      { width: "auto", label: "Tanggal", name: "-" },
      { width: "auto", label: "No Surat", name: "-" },
      { width: "auto", label: "Keterangan", name: "-" },
      { width: "auto", label: "File", name: "-" },

    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    columns2.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      idjadwal: decryptData(this.$route.params.id),
      loadingTable: true,
      table_surat_permohonan_asesmen: [],
      table_surat_pelaksanaan_asesmen: [],
      table_laporan_pelaksanaan_asesmen: [],
      table_berita_acara: [],
      columns: columns,
      columns2: columns2,
      sortOrders: sortOrders,
      currentTablePage: "",
      showModalSuratPermohonanAsesmen: false,
      selected_slug: "",
      path_file_import: "",
      keterangan: "",
      tanggal: "",
      no_surat: "",
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_jadwal: "",
      },
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
    this.getDokumen();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDokumen();
    }, 500);
  },
  methods: {
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDokumen();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    modalSuratPermohonanAsesmen(slug) {
      this.showModalSuratPermohonanAsesmen = !this.showModalSuratPermohonanAsesmen;
      this.selected_slug = slug;
    },
    getDokumen() {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/penjadwalan/detail-center/get-laporan",
        params: {
          idjadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            var all_laporan = response_data_fix.list_data;
            all_laporan.forEach((element) => {
              if (element.jl_slug == "SURAT PERMOHONAN ASESMEN") {
                self.table_surat_permohonan_asesmen.push(element);
              } else if (element.jl_slug == "BERITA ACARA") {
                self.table_berita_acara.push(element);
              } else if (element.jl_slug == "LAPORAN LENGKAP") {
                self.table_surat_pelaksanaan_asesmen.push(element);
              } else if (element.jl_slug == "SURAT PELAKSANAAN ASESMEN") {
                self.table_laporan_pelaksanaan_asesmen.push(element);
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    inputFile() {
      let self = this;
      if ($("#formImport")[0].files[0]) {
        if ($("#formImport")[0].files[0].size < 2242880) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formImport")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.path_file_import = path_file;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    submitDokumen() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/penjadwalan/asesmen-center/store-dokumen",
        data: {
          file: self.path_file_import,
          jadwal_idjadwal: self.data_jadwal.idjadwal,
          jl_slug: self.selected_slug,
          jl_created_by: localStorage.getItem("session_user_id"),
          jl_keterangan: self.keterangan,
          jl_tanggalrealisasi: self.tanggal,
          jl_nosurat: self.no_surat,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Berhasil Menambahkan " + self.selected_slug,
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              window.location.reload();
            }
          });
        }
      });
    },
    getFile(data) {
      var url = process.env.VUE_APP_BACKEND_URL + data;
      window.open(url, '_blank');
    },
    rekapDate(slug) {
      var tanggal = "-";

      let weekdaysToAdd;
      let max = 0;
      var date;
      var data;
      data = this.data_jadwal;
      if (data.j_tanggal_sampai.length != 0) {
        date = data.j_tanggal_sampai;
        if (slug == 'rekap') {
          max = 5;
        } else if (slug == 'pelaksanaan') {
          max = 3;
        } else {
          max = 10;
        }
        date = new Date(date);
        weekdaysToAdd = max;

        while (weekdaysToAdd > 0) {
          date.setDate(date.getDate() + 1);
          // Check if the current date falls on a weekend (Saturday or Sunday)
          if (date.getDay() !== 0 && date.getDay() !== 6) {
            weekdaysToAdd--;
          }
        }
        tanggal = date.toISOString().split('T')[0];
        tanggal = this.convertDate(tanggal);
      }
      return tanggal
    },
    convertDate(date) {
      const resultDate = new Date(date);
      const day = String(resultDate.getDate()).padStart(2, '0');
      const month = String(resultDate.getMonth() + 1).padStart(2, '0');
      const year = resultDate.getFullYear();
      let tanggal;

      tanggal = `${day}-${month}-${year}`;

      return tanggal;
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/delete-dokumen",
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Surat Permohonan Asesmen</span>
            <a class="btn btn-sm btn-success ms-2" @click='modalSuratPermohonanAsesmen("SURAT PERMOHONAN ASESMEN")'>
              <span><i class="fa fa-plus"></i> Tambah Surat Permohonan Asesmen</span></a>
          </div>
        </div>

        <div class="row mt-2">
          <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
            <tbody>
              <tr v-if="table_surat_permohonan_asesmen.length < 1">
                <td class="text-center" colspan="6">Data Tidak Tersedia</td>
              </tr>
              <tr v-else v-for="(row_data, key_data) in table_surat_permohonan_asesmen" :key="key_data">
                <td style="width: 10px">{{ key_data + 1 }}</td>
                <td>{{ row_data.jl_keterangan }}</td>
                <td class="text-center">
                  <div class="row" v-for="(detail_file, key) in row_data.jadwal_laporan_detail " :key="key">
                    <div class="col-12 p-1">
                      <div class="btn-group">
                        <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                          <span><i class="fa fa-eye"></i> Lihat File</span>
                        </a>
                        <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                          <i class="fas fa-trash"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </datatable>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Berita Acara</span>
            <a class="btn btn-sm btn-success ms-2" @click='modalSuratPermohonanAsesmen("BERITA ACARA")'>
              <span><i class="fa fa-plus"></i> Tambah Berita Acara</span></a>
          </div>
        </div>
        <div class="row mt-2">
          <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
            <tbody>
              <tr v-if="table_berita_acara.length < 1">
                <td class="text-center" colspan="6">Data Tidak Tersedia</td>
              </tr>
              <tr v-else v-for="(row_data, key_data) in table_berita_acara" :key="key_data">
                <td style="width: 10px">{{ key_data + 1 }}</td>
                <td>{{ row_data.jl_keterangan }}</td>
                <td class="text-center">
                  <div class="row" v-for="(detail_file, key) in row_data.jadwal_laporan_detail " :key="key">
                    <div class="col-12 p-1">
                      <div class="btn-group">
                        <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                          <span><i class="fa fa-eye"></i> Lihat File</span>
                        </a>
                        <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                          <i class="fas fa-trash"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </datatable>
        </div>
      </div>
    </div>
    <hr class="mt-5" />
    <br />
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Surat Pelaksanaan Asesmen</span>
            <a class="btn btn-sm btn-success ms-2" @click='modalSuratPermohonanAsesmen("LAPORAN LENGKAP")'>
              <span><i class="fa fa-plus"></i> Tambah Surat Pelaksanaan Asesmen</span></a>
          </div>
        </div>
        <div class="row mt-2">
          <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
            <tbody>
              <tr v-if="table_surat_pelaksanaan_asesmen.length < 1">
                <td class="text-center" colspan="6">Data Tidak Tersedia</td>
              </tr>
              <tr v-else v-for="(row_data, key_data) in table_surat_pelaksanaan_asesmen" :key="key_data">
                <td style="width: 10px">{{ key_data + 1 }}</td>
                <td>{{ row_data.jl_keterangan }}</td>
                <td class="text-center">
                  <div class="row" v-for="(detail_file, key) in row_data.jadwal_laporan_detail " :key="key">
                    <div class="col-12 p-1">
                      <div class="btn-group">
                        <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                          <span><i class="fa fa-eye"></i> Lihat File</span>
                        </a>
                        <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                          <i class="fas fa-trash"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </datatable>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="h5">Laporan Pelaksanaan Asesmen</span>
            <a class="btn btn-sm btn-success ms-2" @click='modalSuratPermohonanAsesmen("SURAT PELAKSANAAN ASESMEN")'>
              <span><i class="fa fa-plus"></i> Tambah Laporan Pelaksanaan Asesmen</span></a>
          </div>
        </div>
        <div class="row mt-2">
          <datatable :columns="columns2" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
            <tbody>
              <tr v-if="table_laporan_pelaksanaan_asesmen.length < 1">
                <td class="text-center" colspan="6">Data Tidak Tersedia</td>
              </tr>
              <tr v-else v-for="(row_data, key_data) in table_laporan_pelaksanaan_asesmen" :key="key_data">
                <td style="width: 10px">{{ key_data + 1 }}</td>
                <td v-html="rekapDate('rekap')"></td>
                <td>{{ row_data.jl_tanggalrealisasi }}</td>
                <td>{{ row_data.jl_nosurat }}</td>
                <td>{{ row_data.jl_keterangan }}</td>
                <td class="text-center">
                  <div class="row" v-for="(detail_file, key) in row_data.jadwal_laporan_detail " :key="key">
                    <div class="col-12 p-1">
                      <div class="btn-group">
                        <a class="btn btn-sm btn-info" @click="getFile(detail_file.jld_file)">
                          <span><i class="fa fa-eye"></i> Lihat File</span>
                        </a>
                        <a class="btn btn-sm btn-danger" @click="deleteData(detail_file.jld_id)">
                          <i class="fas fa-trash"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </datatable>
        </div>
      </div>
    </div>
  </div>

  <b-modal v-model="showModalSuratPermohonanAsesmen" id="modal-lg" size="lg" :title="'Form Tambah ' + selected_slug"
    title-class="font-18" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">Pilih File</label>
          <input class="form-control" type="file" id="formImport" v-on:change="inputFile()" />
          <div class="respond-input-file float-left" id="uploadLoading"></div>
          <small class="float-right" style="color: red">*) Max File 2 MB</small>
        </div>
      </div>
    </div>
    <div class="row" v-if="selected_slug == 'SURAT PELAKSANAAN ASESMEN'">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">Tanggal</label>
          <input v-model="tanggal" class="form-control" type="date" />
        </div>
      </div>
    </div>
    <div class="row" v-if="selected_slug == 'SURAT PELAKSANAAN ASESMEN'">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">No Surat</label>
          <input v-model="no_surat" class="form-control" type="text" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">Keterangan</label>
          <textarea v-model="keterangan" class="form-control" type="text" />
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button type="button" class="btn btn-primary" @click="submitDokumen()">
            <i class="fa fa-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
