<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import DetailChecklist from "./detail-checklist.vue";
import { decryptData } from '@/helpers/crypt.js';
export default {
  components: {
    Datatable,
    Pagination,
    DetailChecklist,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_kompetensi_peg" },
      {
        width: "auto",
        label: "Tipe",
        name: "inisial_kompetensi_group",
      },

      {
        width: "auto",
        label: "Aksi",
        name: "inisial_kompetensi_source",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    let sortOrders2 = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns2 = [
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      {
        width: "auto",
        label: "NIP",
        name: "inisial_kompetensi_target",
      },
      {
        width: "auto",
        label: "Nama",
        name: "nama_alat_tes",
      },

      {
        width: "auto",
        label: "Tanggal Mulai",
        name: "nama_alat_tes",
      },
      {
        width: "auto",
        label: "Status",
        name: "nama_alat_tes",
      },
      { width: "auto", label: "Aksi", name: "nama_aspek" },
    ];

    // cek sorting
    columns2.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      idjadwal: decryptData(this.$route.params.id),
      showChecklistTipe: true,
      showDetailChecklist: false,
      showModalAddPeserta: false,
      option_checklist: [],
      selected_checklist: [],
      filter_checklist: [],
      table_data: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        m_dirkom_idm_dirkom: this.data_jadwal.m_dirkom_idm_dirkom,
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      jc_tk_id_temp : "",
      table_data2: [],
      loadingTable2: true,
      columns2: columns2,
      sortKey2: "id_role", // sort key awal
      sortOrders2: sortOrders2,
      currentTablePage2: "",
      // parsing data ke table data
      tableData2: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        idjadwal: "",
        m_dirkom_idm_dirkom: this.data_jadwal.m_dirkom_idm_dirkom,
      },
      // config untuk paginasi
      pagination2: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      path_file_import: "",
    };
  },
  mounted() {
    this.getDataTable();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/penjadwalan/detail-center/get-checklist"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.idjadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          console.log(response_data_fix.list_data);
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            self.loadingTable = false;
            self.table_data.forEach((element) => {
              self.filter_checklist.push(element.jct_tk_id);
            });
            this.getChecklist();
            console.log(self.table_data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    submitChecklist() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/penjadwalan/asesmen-center/store-checklist",
        data: {
          checklist: self.selected_checklist,
          idjadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Menambahkan Checklist",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                window.location.reload();
              }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
    },
    showDetailChecklistForm(data) {
      let self = this;
      if (self.showDetailChecklist != true) {
        this.jc_tk_id_temp = data.jct_id
        console.log(this.jc_tk_id_temp);
      }
      self.showDetailChecklist = !self.showDetailChecklist;
      self.showChecklistTipe = !self.showChecklistTipe;
    },

    getChecklist() {
      let self = this;
      var config = {
        method: "get",
        params: {
          keyword: "",
          filter_checklist: self.filter_checklist,
        },
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/checklist",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_checklist = response.data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    onSearchChecklist(search, loading) {
      if (search.length) {
        loading(true);
        this.searchChecklist(loading, search, this);
      }
    },
    searchChecklist: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/checklist",
        params: {
          keyword: String(search),
          filter_checklist: self.filter_checklist,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_checklist = response.data.data.referensi;

          loading(false);
        }
      });
    },

    deleteChecklist(id_checklist) {
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/penjadwalan/asesmen-center/delete-checklist",
        data: {
          jct_id: id_checklist,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      Swal.fire({
        title: "Apakah Anda Yakin?",
        text: "Data Akan Dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          axios(config)
            .then((response) => {
              if (response.data.meta.code == 200) {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Berhasil Menghapus Data Checklist",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.close();
                    window.location.reload();
                  }
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.data.message,
              });
            });
        }
      });
    },
    //search & get jenis asesme
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row" v-if="showChecklistTipe">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <span class="h4">Checklist</span>
          <div class="d-inline-block">
            <a
              class="btn btn-sm btn-success"
              @click="showModalAddPeserta = !showModalAddPeserta"
            >
              <span><i class="fa fa-plus"></i> Tambah Checklist</span></a
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-2">
          <hr />
          <div class="row">
            <div class="col-md-4">
              <table>
                <tr>
                  <td>Show</td>
                  <td>
                    <select
                      class="form-control form-control-sm"
                      id="showpaginatetable"
                      style="width: 100%"
                      v-model="tableData.length"
                      @change="getDataTable()"
                    >
                      <option value="10" selected>10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </td>
                  <td>Entries</td>
                </tr>
              </table>
            </div>
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-3">
              <input
                type="text"
                class="form-control form-control-sm"
                id="searchTable"
                placeholder="Cari Data ..."
                v-model="tableData.search"
                @input="searchDatatable"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <datatable
          :columns="columns"
          :sortKey="sortKey"
          :sortOrders="sortOrders"
          @sort="sortBy"
        >
          <tbody>
            <tr v-if="loadingTable">
              <td colspan="6">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else-if="table_data == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr
              v-else
              v-for="(row_data, key_data) in table_data"
              :key="key_data"
            >
              <td class="text-center">
                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                <div v-else>
                  {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""}}{{ key_data + 1 }}.
                </div>
              </td>
              <td>{{ row_data.jct_nama }}</td>
              <td class="text-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="showDetailChecklistForm(row_data)"
                  >
                    <i class="fa fa-eye"></i> Detail
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="deleteChecklist(row_data.jct_id)"
                  >
                    <i class="fa fa-trash"></i> Hapus
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </datatable>
      </div>
      <div class="row">
        <pagination :pagination="pagination" @to-page="toPage"></pagination>
      </div>
    </div>
    <div v-if="showDetailChecklist">
      <div class="row">
        <div class="col-6">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="showDetailChecklistForm()"
          >
            <i class="fa fa-angle-left"></i> Kembali
          </button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12 d-flex justify-content-between">
          <span class="h4">Detail Checklist</span>
        </div>
      </div>
      <DetailChecklist :jct_id="jc_tk_id_temp" :idjadwal="idjadwal" />
    </div>
  </div>
  <b-modal  
    v-model="showModalAddPeserta"
    id="modal-lg"
    size="lg"
    title="Tambah Checklist"
    title-class="font-18"
    hide-footer
  >
    <div class="row">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Checklist" label-for="lokasi">
          <v-select
            :options="option_checklist"
            multiple
            label="nama_checklist"
            placeholder="-Cari Checklist-"
            v-model="selected_checklist"
            @search="onSearchChecklist"
          />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="d-grid gap-2 col-6 mx-auto">
        <button
          class="btn btn-primary"
          type="button"
          @click="submitChecklist()"
        >
          Simpan
        </button>
      </div>
    </div>
  </b-modal>
</template>
