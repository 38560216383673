<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: ["nip", "id_jadwal", "idjadwal_peserta", "id_jenis_asesmen"],
  data() {
    return {
      table_data: [],
    };
  },
  mounted() {
    let self = this;
    self.getData();
  },
  methods: {
    getData() {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/detail-potensi",
        params: {
          nip: this.nip,
          idjadwal: this.id_jadwal,
          idjadwalpeserta: this.idjadwal_peserta,
          id_jenis_asesmen: this.id_jenis_asesmen
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix;
            console.log(self.table_data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<template>
  <div>
    <b>{{table_data[0]?.alat_tes_aspek.psikotes_detail[0].jadwal_new.j_txt_dirkom}}</b> - {{table_data[0]?.alat_tes_aspek.psikotes_detail[0].jadwal_new.j_tanggal_mulai}} 
    <div class="table-responsive">
      <table class="table table-bordered mb-0 mt-2">
        <thead class="bg-dark text-white" style="padding: 10px">
          <th style="padding: 5px">No</th>
          <th style="padding: 5px">Alat tes</th>
          <th style="padding: 5px">Aspek</th>
          <th style="padding: 5px">Nilai</th>
        </thead>
        <tbody>
          <tr v-for="(row_data, key) in table_data" :key="key">
            <td>{{ key + 1 }}</td>
            <td>{{ row_data.nama_alat_tes }}</td>
            <td>{{ row_data.nama_aspek }}</td>
            <td>{{ row_data.alat_tes_aspek.psikotes_detail[0]?.nilai }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <table
      border="1"
      class="table-responsive"
      style="border-collapse: collapse"
    >
      <thead>
        <th>Product</th>
        <th>Month</th>
        <th>Unit price</th>
        <th>No. sold</th>
      </thead>
      <tbody>
        <template v-for="mSale in salesData.monthlySales" :key="mSale">
          <tr v-for="(month, key) in mSale.months" :key="key">
            <td v-if="key == 0" :rowspan="mSale.months.length">
              {{ mSale.product }}
            </td>
            <td>{{ month.month }}</td>
            <td>{{ month.unitPrice }}</td>
            <td>{{ month.unitsSold }}</td>
          </tr>
        </template>
      </tbody>
    </table> -->
  </div>
</template>
