<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";
import { encryptData } from "@/helpers/crypt.js";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "JADWAL HYGIENE",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "70px", label: "ID", name: "idjadwal" },
      { width: "100px", label: "Aksi", name: "-" },
      { width: "100px", label: "Grub", name: "j_txt_ptl" },
      { width: "auto", label: "Tanggal Mulai", name: "j_tanggal_mulai" },
      { width: "auto", label: "Tanggal Selesai", name: "j_tanggal_sampai" },
      { width: "auto", label: "Kegiatan", name: "j_txt_dirkom" },
      { width: "auto", label: "PTL", name: "j_txt_dirkom" },
      { width: "300px", label: "Petugas", name: "j_txt_dirkom" },
      { width: "auto", label: "Jumlah Peserta Diundang", name: "-" },
      { width: "auto", label: "Jumlah Peserta Hadir", name: "-" },
      { width: "auto", label: "Keterangan", name: "j_keterangan" },
      { width: "auto", label: "Lokasi", name: "j_txt_lokasi" },
      { width: "auto", label: "Kelengkapan Data", name: "j_keterangan" },
      { width: "auto", label: "Status", name: "j_keterangan" },
      { width: "auto", label: "Surat Permohonan Asesmen", name: "j_keterangan" },
      { width: "auto", label: "Berita Acara", name: "j_keterangan" },
      { width: "auto", label: "Surat Pelaksanaan Asesmen", name: "j_keterangan" },
      // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "JADWAL HYGIENE",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "JADWAL HYGIENE",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      loadingExport: false,
      table_data: [],
      columns: columns,
      sortKey: "idjadwal", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",


      option_tahun: [],
      option_bulan: "",
      option_status: "",
      option_kelengkapan: "",

      selected_tahun: "",
      selected_bulan: "",
      selected_status: "",
      selected_kelengkapan: "",
      // parsing data ke table data
      target_tanggal: "",
      showModalUpdateTarget: false,
      selectedIdJadwal: "",
      selectedSlug: "",
      update_file: null,
      update_tanggal_realisasi: null,
      update_no_surat: null,
      update_keterangan: null,
      file_temp: null,

      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "desc",
        filter_tahun: "",
        filter_bulan: "",
        status_penjadwalan: localStorage.status,
        id_nip: JSON.parse(localStorage.getItem('session')).user.username,
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

    };
  },
  mounted() {
    let self = this;
    self.cekAksesMenu();
    self.getDataTable();
    self.generateFilter();
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    generateFilter() {

      let self = this;
      const currentYear = new Date().getFullYear();
      const startYear = 2013;

      for (let year = currentYear; year >= startYear; year--) {
        self.option_tahun.push(year);
      }

      const months = [
        { key: '01', name: 'January' },
        { key: '02', name: 'February' },
        { key: '03', name: 'March' },
        { key: '04', name: 'April' },
        { key: '05', name: 'May' },
        { key: '06', name: 'June' },
        { key: '07', name: 'July' },
        { key: '08', name: 'August' },
        { key: '09', name: 'September' },
        { key: '10', name: 'October' },
        { key: '11', name: 'November' },
        { key: '12', name: 'December' }
      ];

      self.option_bulan = months;

      const statusfilter = [
        { key: 'Draft', name: 'Draft' },
        { key: 'verifikasi', name: 'Verifikasi Jadwal' },
        { key: 'proses_ptl', name: 'Proses Jadwal ke PTL' },
        { key: 'void', name: 'Batal' },
        { key: 'selesai', name: 'Selesai' }
      ];

      self.option_status = statusfilter;

      const kelangkapanfilter = [
        { key: 'ya', name: 'Lengkap' },
        { key: 'tidak', name: 'Belum Lengkap' },
      ];

      self.option_kelengkapan = kelangkapanfilter;


    },
    cekAksesMenu() {
      let self = this;
      var config_axios = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
        params: {
          role_id: self.sessionRoleId,
          link_name: self.namePathUrl,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_axios)
        .then((response) => {
          let response_data_fix = response.data.data.status_access;
          if (response.data.meta.code == 200) {
            if (response_data_fix == false) {
              Swal.fire({
                icon: "warning",
                title: "Oppss",
                text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({ name: "dashboard" });
                }
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    fileLaporan(data, slug) {
      var result = "";
      if (data.jadwal_laporan) {
        data.jadwal_laporan.map((item) => {
          if (item.jl_slug == slug) {
            // var jsonArray = JSON.parse(`[${item.jl_file}]`);
            // jsonArray[0].map((item) => {
            result += `<a href="${process.env.VUE_APP_BACKEND_URL + item.jl_file
              }" target="_blank" class="btn btn-sm btn-info">Download</a>`;
            // });
          }
        });
      } else {
        result = "-";
      }
      return result;
    },
    modalRekap(id, slug) {
      this.showModalUpdateTarget = true;
      this.selectedIdJadwal = id;
      this.selectedSlug = slug;
      // console.log(this.selectedSlug);
    },
    noSurat(data, slug) {
      var result = "-";
      if (data.jadwal_laporan) {
        data.jadwal_laporan.map((item) => {
          if (item.jl_slug == slug) {
            result = item.jl_nosurat;
          }
        });
      } else {
        result = "-";
      }
      return result;
    },
    rekapDate(data, slug) {
      var tanggal = "-";

      let weekdaysToAdd;
      let max = 0;
      var date;
      if (data.jadwal_laporan.length == 0) {
        date = data.j_tanggal_sampai;
        if (slug == 'rekap') {
          max = 5;
        } else {
          max = 10;
        }
        date = new Date(date);
        weekdaysToAdd = max;

        while (weekdaysToAdd > 0) {
          date.setDate(date.getDate() + 1);
          // Check if the current date falls on a weekend (Saturday or Sunday)
          if (date.getDay() !== 0 && date.getDay() !== 6) {
            weekdaysToAdd--;
          }
        }
        tanggal = date.toISOString().split('T')[0];
        tanggal = this.convertDate(tanggal);
      } else {
        data.jadwal_laporan.map((item) => {
          if (item.jl_slug == slug) {
            date = item.data.j_tanggal_sampai;
            if (slug == 'rekap') {
              max = 5;
            } else {
              max = 10;
            }
            date = new Date(date);
            weekdaysToAdd = max;

            while (weekdaysToAdd > 0) {
              date.setDate(date.getDate() + 1);
              // Check if the current date falls on a weekend (Saturday or Sunday)
              if (date.getDay() !== 0 && date.getDay() !== 6) {
                weekdaysToAdd--;
              }
            }
            tanggal = date.toISOString().split('T')[0];
            tanggal = this.convertDate(tanggal);
          }
        });
      }
      return tanggal
    },
    realisasiDate(data, slug) {
      var result = "-";
      if (data.jadwal_laporan) {
        data.jadwal_laporan.map((item) => {
          if (item.jl_slug == slug) {
            result = item.jl_tanggalrealisasi;
            result = this.convertDate(result);
          }
        });
      } else {
        result = "-";
      }
      return result;
    },
    convertDate(date) {
      const resultDate = new Date(date);
      const day = String(resultDate.getDate()).padStart(2, '0');
      const month = String(resultDate.getMonth() + 1).padStart(2, '0');
      const year = resultDate.getFullYear();
      let tanggal;

      tanggal = `${day}-${month}-${year}`;

      return tanggal;
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/penjadwalan-hygiene"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;
      self.tableData.filter_tahun =
        self.selected_tahun;

      self.tableData.filter_bulan =
        self.selected_bulan.key;

      self.tableData.filter_status =
        self.selected_status.key;

      self.tableData.filter_kelengkapan =
        self.selected_kelengkapan.key;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            if (self.tableData.draw == response_data_fix.draw) {
              self.table_data = response_data_fix.list_data.data;
              self.configPagination(response_data_fix.list_data);
              self.currentTablePage = response_data_fix.list_data.current_page;
            }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog",
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    updateRekap() {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin update rekap?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/store-dokumen",
            data: {
              file: self.file_temp,
              jadwal_idjadwal: self.selectedIdJadwal,
              jl_slug: self.selectedSlug,
              jl_created_by: localStorage.getItem("session_user_id"),
              jl_keterangan: self.update_keterangan,
              jl_nosurat: self.update_no_surat,
              jl_tanggalrealisasi: self.update_tanggal_realisasi
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              this.showModalUpdateTarget = false;
              this.update_tanggal_realisasi = null,
                this.update_no_surat = null,
                this.update_keterangan = null,
                self.file_temp = null,
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Data berhasil diupdate.",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    self.getDataTable();
                  }
                });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    importFile() {
      let self = this;
      if ($("#update_file")[0].files[0]) {
        // if ($("#update_file")[0].files[0].size < 2242880) {
        // $("#uploadLoading").html(
        //     '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
        // );

        var FormData = require("form-data");
        var data = new FormData();
        data.append("file", $("#update_file")[0].files[0]);
        var config = {
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/upload",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
          data: data,
        };
        axios(config).then(function (response) {
          self.file_temp = response.data.data.path_file;
        });
      }
    },
    getExport(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/penjadwalan/asesmen-psikologi/export-ap"
    ) {
      let self = this;
      self.loadingExport = true;
      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.loadingExport = false;
            var url = process.env.VUE_APP_BACKEND_URL + response_data_fix.filename;
            window.open(url, "_blank");
          } else {
            self.loadingExport = false;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          self.loadingExport = false;
          console.log(errors);
        });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">List Penjadwalan Hygiene</div>
              </div>
              <div class="col-md-6">
                <router-link :to="{ name: 'add-jadwal-psikologi' }">
                  <div class="text-end" style="display: none;">
                    <a class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Tambah Penjadwalan Hygiene</a>
                  </div>
                </router-link>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">

                  <div class="form-group col-md-2">
                    <label for="Batch">Tahun</label>
                    <v-select :options="option_tahun" v-model="selected_tahun" @update:modelValue="getDataTable()"
                      placeholder="-Pilih Tahun-" class="text-secondary"></v-select>
                  </div>

                  <div class="form-group col-md-2">
                    <label for="Batch">Bulan</label>
                    <v-select :options="option_bulan" label="name" v-model="selected_bulan"
                      @update:modelValue="getDataTable()" placeholder="-Pilih Bulan-" class="text-secondary"></v-select>
                  </div>


                  <div class="form-group col-md-2">
                    <label for="Batch">Status</label>
                    <v-select :options="option_status" label="name" v-model="selected_status"
                      @update:modelValue="getDataTable()" placeholder="Semua Status" class="text-secondary"></v-select>
                  </div>

                  <div class="form-group col-md-2">
                    <label for="Batch">Dokumen Lengkap</label>
                    <v-select :options="option_kelengkapan" v-model="selected_kelengkapan" label="name"
                      @update:modelValue="getDataTable()" placeholder="-Pilih Kelengkapan-"
                      class="text-secondary"></v-select>
                  </div>

                  <div class="form-group col-md-2">
                    <label style="opacity: 0">Tipe</label><br />
                    <button
                      v-if="(selected_tahun != '' || selected_bulan != '' || selected_status != '' || selected_kelengkapan != '') && loadingExport == false"
                      class="btn btn-success" v-on:click="getExport()">
                      <i class="fas fa-file-excel"></i> Export
                    </button>
                    <button v-else-if="loadingExport == true" class="btn btn-success disabled">
                      <i class="fas fa-file-excel"></i> Processing...
                    </button>
                    <button v-else class="btn btn-success disabled">
                      <i class="fas fa-file-excel"></i> Export
                    </button>
                  </div>

                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%"
                            v-model="tableData.length" @change="getDataTable()">
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..."
                      v-model="tableData.search" @input="getDataTable()" />
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table mb-0 table-bordered table-condensed table-hover">
                  <thead class="bg-dark text-center text-white">
                    <tr>
                      <th class="text-center" rowspan="2">No</th>
                      <th class="text-center" rowspan="2">ID Jadwal</th>
                      <th class="text-center" rowspan="2">Aksi</th>
                      <th class="text-center" rowspan="2">Nama Grup</th>
                      <th class="text-center" colspan="2">Tanggal</th>
                      <th class="text-center" rowspan="2">Kegiatan</th>
                      <th class="text-center" rowspan="2">PTL</th>
                      <th class="text-center" rowspan="2">Petugas</th>
                      <th class="text-center" colspan="2">Peserta</th>
                      <th class="text-center" rowspan="2">Keterangan</th>
                      <th class="text-center" rowspan="2">Lokasi</th>
                      <th class="text-center" rowspan="2">Kelengkapan Data</th>
                      <th class="text-center" rowspan="2">Status</th>
                      <th class="text-center" rowspan="2">Surat Permohonan Asesmen</th>
                      <th class="text-center" rowspan="2">Berita Acara</th>
                      <th class="text-center" rowspan="2">Surat Pelaksanaan Asesmen</th>
                      <th class="text-center" colspan="4">Laporan Pelaksanaan Asesmen</th>
                    </tr>
                    <tr>

                      <th class="text-center">Mulai</th>
                      <th class="text-center">Selesai</th>
                      <th class="text-center">Undang</th>
                      <th class="text-center">Hadir</th>
                      <th class="text-center" style="width: 100px;">Target</th>
                      <th class="text-center">Tanggal</th>
                      <th class="text-center">No.Surat</th>
                      <th class="text-center">File</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="6">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                      <td class="text-center">
                        <div v-if="key_data + 1 == 10">
                          {{ currentTablePage }}0.
                        </div>
                        <div v-else>
                          {{
                            currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                          }}{{ key_data + 1 }}.
                        </div>
                      </td>
                      <td class="text-center">
                        #{{ row_data.idjadwal }}
                      </td>
                      <td class="text-center">
                        <div>
                          <div class="btn-group">
                            <router-link :to="{
                              name: 'detail-penjadwalan-hygiene',
                              params: {
                                id: encryptedUrl(row_data.idjadwal.toString()),
                              },
                            }" class="btn btn-success btn-sm"><i class="fa fa-eye"></i> Detail
                            </router-link>
                          </div>
                        </div>
                      </td>
                      <td>
                        {{ row_data.nama_grup }}
                      </td>
                      <td>
                        {{ row_data.j_tanggal_mulai }}
                      </td>
                      <td>
                        {{ row_data.j_tanggal_sampai }}
                      </td>
                      <td>
                        {{ row_data.j_txt_kegiatan }}
                      </td>
                      <td>
                        {{ row_data.j_txt_ptl }}
                      </td>
                      <td>
                        <!-- {{ 
                       row_data.j_json_petugas.map(function(val) {
                            return val.jp_txt_petugas;
                          }).join(',')
                      }} -->
                        <span style="white-space:nowrap;" v-for="(petugas, i) in JSON.parse(row_data.j_json_petugas)"
                          :key="i">
                          - {{ petugas.jp_txt_petugas }}<br>
                        </span>
                      </td>
                      <td>
                        {{ row_data.ju_peserta_diundang }}
                      </td>
                      <td>{{ row_data.jadwal_asesi_p_sum_ja_kehadiran ? row_data.jadwal_asesi_p_sum_ja_kehadiran : "0" }}
                      </td>
                      <td>
                        {{ row_data.j_keterangan }}
                      </td>
                      <td>
                        {{ row_data.j_txt_lokasi }}
                      </td>
                      <td>
                        {{ row_data.surat_tugas }}
                        <span style="white-space:nowrap;" class="badge bg-danger" v-if="(row_data.surat_tugas == null || row_data.surat_tugas == '[]')
                          && (row_data.berita_acara == null || row_data.berita_acara == '[]')
                          && (row_data.laporan_lengkap == null || row_data.laporan_lengkap == '[]')
                          && (row_data.laporan_pelaksanaan == null || row_data.laporan_pelaksanaan == '[]')">
                          Dokumen Belum Diisi <br>
                        </span>

                        <span style="white-space:nowrap;" class="p-0"
                          v-if="row_data.surat_tugas != null && row_data.surat_tugas != '[]'">
                          - Surat Penugasan <br>
                        </span>
                        <span style="white-space:nowrap;" class="p-0"
                          v-if="row_data.berita_acara != null && row_data.berita_acara != '[]'">
                          - Berita Acara <br>
                        </span>
                        <span style="white-space:nowrap;" class="p-0"
                          v-if="row_data.laporan_lengkap != null && row_data.laporan_lengkap != '[]'">
                          - Surat Pelaksanaan Asesmen <br>
                        </span>
                        <span style="white-space:nowrap;" class="p-0"
                          v-if="row_data.laporan_pelaksanaan != null && row_data.laporan_pelaksanaan != '[]'">
                          - Laporan Pelaksanaan Asesmen <br>
                        </span>
                      </td>
                      <td>

                        <span style="white-space:nowrap;" class="badge bg-danger" v-if="row_data.j_status == 'void'">
                          Void
                          </span>
                          <span style="white-space:nowrap;" class="badge bg-info" v-if="row_data.j_status == 'Draft'">
                            Draft
                          </span>
                          <span style="white-space:nowrap;" class="badge bg-warning"
                            v-else-if="row_data.j_status == 'verifikasi'">
                            Verifikasi Jadwal
                          </span>
                          <span style="white-space:nowrap;" class="badge bg-success"
                            v-else-if="row_data.j_status == 'proses_ptl'">
                            Proses Jadwal ke PTL
                          </span>
                          <span style="white-space:nowrap;" class="badge bg-primary"
                            v-else-if="row_data.j_status == 'selesai'">
                            Selesai
                          </span>
                          <span style="white-space:nowrap;" class="badge bg-info"
                            v-else-if="row_data.j_status == 'MK Fix'">
                            MK Fix
                          </span>
                          <span style="white-space:nowrap;" class="badge bg-danger"
                            v-else-if="row_data.j_status == 'batal'">
                            Batal
                          </span>
                          <span v-else>
                            {{ row_data.j_status }}
                          </span>
                      </td>
                      <td v-html='fileLaporan(row_data, "SURAT PERMOHONAN ASESMEN")'></td>
                      <td v-html="fileLaporan(row_data, 'BERITA ACARA')"></td>
                      <td v-html="fileLaporan(row_data, 'LAPORAN LENGKAP')"></td>
                      <td v-html="rekapDate(row_data, 'rekap')"></td>
                      <td class="text-center">
                        <button class="btn btn-success btn-sm" @click='modalRekap(row_data.idjadwal, "LAPORAN LENGKAP")'>
                          Edit
                        </button>
                        {{ realisasiDate(row_data, 'LAPORAN LENGKAP') }}
                      </td>
                      <td v-html="noSurat(row_data, 'LAPORAN REKAP')"></td>
                      <td v-html="fileLaporan(row_data, 'LAPORAN REKAP')"></td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="showModalUpdateTarget" id="modal-lg" size="lg" title="Update Target" title-class="font-18"
    hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <div class="form-group">
            <label for="file">File <small>*kosongi jika tidak ingin merubah</small></label>
            <input type="file" class="form-control" id="update_file" @change="importFile" />
          </div>
          <div class="form-group">
            <label for="tanggalRealisasi">Tanggal Realisasi</label>
            <input type="date" class="form-control" v-model="update_tanggal_realisasi" />
          </div>
          <div class="form-group">
            <label for="noSurat">No Surat</label>
            <input type="text" class="form-control" v-model="update_no_surat" />
          </div>
          <div class="form-group">
            <label for="keterangan">Keterangan</label>
            <textarea class="form-control" v-model="update_keterangan"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button type="button" class="btn btn-primary" @click="updateRekap(this.selectedIdJadwal, this.selectedSlug)">
            <i class="fa fa-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
