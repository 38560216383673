<script>
// import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { decryptData } from '@/helpers/crypt.js';
export default {
  components: {
    // Datatable,
    // Pagination,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      idjadwal: decryptData(this.$route.params.id),
      showModalAddPeserta: false,
      option_user: [],
      selected_nip: [],
      data_pd_asesi: [],

      selected_user: [],
      selected_temp: [],
      selectedId: [],
      table_data: [],
      table_data2: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: null,
      collumJenisAsesmen: [],
      colspan: 0,

      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_jadwal: "",
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      path_file_import: "",
      processed_data: [],
    };
  },
  mounted() {
    this.getCollumJenisAsesmen();
    this.getDataTable();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getCollumJenisAsesmen(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/get-collumn-jenisasesmen") {
      var config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            this.collumJenisAsesmen = response_data_fix
            this.colspan = response_data_fix.length;

          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/vertifikasi/get-temporary") {
      let self = this;
      self.loadingTable = true;
      self.tableData.user_id = localStorage.session_user_id;
      self.tableData.method = "jenis asesmen";

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          // console.log(response_data_fix);
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix;
            self.selected_nip = self.table_data.map((item) => item.nip);
            // self.configPagination(response_data_fix.data);
            self.currentTablePage = response_data_fix.current_page;
            self.loadingTable = false;
            this.processData();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    processData() {
      let self = this;
      let data_table = self.table_data;
      // let data_ja = self.collumJenisAsesmen;

      const groupedData = data_table.reduce((result, item) => {
        const key = `${item.id_temp}-${item.nip}-${item.nama}-${item.unit}-${item.level}`;

        if (!result[key]) {
          result[key] = {
            ...item,
            jenis_asesmen_id: [item.jenis_asesmen_id],
            tanggal_mulai_pelaksanaan: [item.tanggal_mulai_pelaksanaan]
          };
        } else {
          result[key].jenis_asesmen_id.push(item.jenis_asesmen_id);
          result[key].tanggal_mulai_pelaksanaan.push(item.tanggal_mulai_pelaksanaan);
        }

        return result;
      }, {});

      const groupedArray = Object.values(groupedData);

      self.table_data2 = groupedArray;
    },

    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    ConfirmResetAssesi() {
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin mereset Asesi?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetAsesi();
        } else if (result.isDenied) {
          Swal.close();

        }
      });
    },

    resetAsesi() {
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/assesitab/reset-asesi",
        data: {
          id_jadwal: this.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Reset Asesi",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            })
              .then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  this.getDataTable();
                }
              });

          }
        }).catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });


    },
    selectedTemp() {
      this.$emit('selected-temp', this.selected_temp);
    },
    checkDataJenisAsesmen(id, jenis_asesmen) {
      let key = null;

      for (let i = 0; i < jenis_asesmen.length; i++) {
        if (jenis_asesmen[i] === id) {
          key = i;
          break;
        }
      }
      return key;
    },
    getColumnStyle(date) {
      const currentDate = new Date();
      const assessmentDate = new Date(date);
      const oneYearFromNow = new Date().setFullYear(currentDate.getFullYear() - 1);
      const twoYearsFromNow = new Date().setFullYear(currentDate.getFullYear() - 2);

      if (date == null) {
        return {
          backgroundColor: '#ffffff',
        };
      } else if (assessmentDate >= oneYearFromNow) {
        return {
          backgroundColor: '#c8ff88',
        };
      } else if (assessmentDate >= twoYearsFromNow) {
        return {
          backgroundColor: '#d4d4d4',
        };
      } else {
        return {
          backgroundColor: '#ffceca',
        };
      }
    },
    selectAll() {
      if (this.checkAll) {
        // If "Check All" is checked, set selected_temp to all id_temp values
        this.selected_temp = this.table_data2.map((row) => row.id_temp);
        this.$emit('selected-temp', this.selected_temp);
      } else {
        // If "Check All" is unchecked, clear selected_temp
        this.selected_temp = [];
      }
    },
  }
};
</script>
<template>
  <div class="mt-4">

    <div class="row">
      <div class="col-md-12 mb-2">
        <div class="row mb-3"></div>
        <hr />
        <div class="row">
          <div class="col-md-4">
            <table>
              <tr>
                <td>Show</td>
                <td>
                  <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%"
                    v-model="tableData.length" @change="getDataTable()">
                    <option value="10" selected>10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
          </div>
          <div class="col-md-5">&nbsp;</div>
          <div class="col-md-3">
            <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..."
              v-model="tableData.search" @input="searchDatatable" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table mb-0 table-bordered table-condensed table-hover">
            <thead class="bg-dark text-center text-white">
              <tr>
                <th rowspan="2" class="text-center">
                  <input type="checkbox" v-model="checkAll" @change="selectAll" />
                </th>
                <th rowspan="2" class="text-center">No</th>
                <th rowspan="2" class="text-center" style="width:100px;">NIP</th>
                <th rowspan="2" class="text-center" style="width: auto;">NAMA</th>
                <th rowspan="2" class="text-center" style="width: auto;">UNIT</th>
                <th rowspan="2" class="text-center" style="width: auto;">JABATAN SAAT INI</th>
                <th :colspan="colspan" class="text-center" style="width: auto;">Jenis Asesmen</th>
              </tr>
              <tr>
                <th class="colspan-style" v-for="(row_data, key_data) in collumJenisAsesmen" :key="key_data">
                  {{ row_data.nama_jenisasesmen }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loadingTable">
                <td colspan="6">
                  <i class="fas fa-spinner fa-spin"></i> Loading...
                </td>
              </tr>
              <tr v-else-if="table_data2 == ''">
                <td class="text-center" :colspan="5 + colspan">Data Tidak Tersedia</td>
              </tr>
              <tr v-else v-for="(row_data, key_data) in table_data2" :key="key_data">
                <td class="text-center">
                  <input type="checkbox" v-model="selected_temp" :value="row_data.id_temp" @change="selectedTemp" />
                </td>
                <td class="text-center">
                  <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                  <div v-else>
                    {{ key_data + 1 }}.
                  </div>
                </td>
                <td>{{ row_data.nip }}</td>
                <td>{{ row_data.nama }}</td>
                <td>{{ row_data.unit }}</td>
                <td>{{ row_data.level }} </td>

                <template class="colspan-style" v-for="(row_data2, key_data2) in collumJenisAsesmen" :key="key_data2">
                  <td>
                    <div v-if="checkDataJenisAsesmen(row_data2.id, row_data.jenis_asesmen_id) != null" class="text-center" :id="row_data2.id"
                    :style="getColumnStyle(row_data.tanggal_mulai_pelaksanaan[checkDataJenisAsesmen(row_data2.id, row_data.jenis_asesmen_id)])">
                      {{ row_data.tanggal_mulai_pelaksanaan[checkDataJenisAsesmen(row_data2.id, row_data.jenis_asesmen_id)] }}
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <pagination :pagination="pagination" @to-page="toPage"></pagination>
    </div> -->
  </div>
  <!-- modal tambah asesi -->
  <b-modal v-model="showModalAddPeserta" id="modal-lg" size="lg" title="Tambah Peserta" title-class="font-18" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Masukkan Peserta" label-for="lokasi">
          <v-select :options="option_user" multiple label="u_nama" :filterBy="myFilter"
            placeholder="-Cari NIP atau Nama Peserta-" v-model="selected_user" @search="onSearchUser">
            <template v-slot:option="option">
              <span :class="option.u_nama"></span>
              {{ option.u_nama }} -
              <span :class="option.u_nip"></span>
              {{ option.u_nip }}
            </template></v-select>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="d-grid gap-2 col-6 mx-auto">
        <button class="btn btn-primary" type="button" @click="submitPeserta()">
          Simpan
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.colspan-style {
  width: auto !important;
  text-align: center !important;
  font-size: 11px !important;
}
</style>
