<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Edit Pohon Profesi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      title: "Edit Pohon Profesi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Pohon Profesi",
          href: "/master/Pohon Profesi",
        },
        {
          text: "Edit Pohon Profesi",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      id: this.data.id,
      nama_pohon_profesi: null,
      kode_pohon_profesi: null,
    };
	},
  mounted() {
    let self = this;
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    var config = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/pohon-profesi",
      params: {
        id: self.id,
      },
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data[0];
        if (response_data.meta.code == 200) {
          var data_edit = response_data_fix;
          self.nama_pohon_profesi = data_edit.nama_pohon_profesi;
          self.kode_pohon_profesi = data_edit.kode_pohon_profesi;
          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.close();
      });
  },
  methods: {
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/pohon-profesi",
        data: {
          id: self.id,
          nama_pohon_profesi: self.nama_pohon_profesi,
          kode_pohon_profesi: self.kode_pohon_profesi,
            },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master Pohon Profesi segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: 'render', query: { page: 'Pohon Profesi' } });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
}
</script>

<template>
  <PageHeader :title="title" :items="items" />
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <b-form class="p-2" @submit.prevent="StoreData">
          <div class="card-body">
            <div v-if="axiosCatchError">
              <div
                class="alert alert-danger"
                v-if="
                  typeof axiosCatchError === 'string' ||
                  axiosCatchError instanceof String
                "
              >
                {{ axiosCatchError }}
              </div>
              <div class="alert alert-danger" role="alert" v-else>
                <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                  <div v-for="(allErrors, idx) in errorArray" :key="idx">
                    <span class="text-danger">{{ allErrors[0] }} </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-form-group
                  class="mb-3"
                  label="Nama Pohon Profesi"
                  label-for="formrow-nama_pohon_profesi-input"
                >
                  <b-form-input
                    id="formrow-nama_pohon_profesi-input"
                    placeholder="Masukkan Nama Pohon Profesi..."
                    type="text"
                    v-model="nama_pohon_profesi"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group
                  class="mb-3"
                  label="Kode Pohon Profesi"
                  label-for="formrow-kode_pohon_profesi-input"
                >
                  <b-form-input
                    id="formrow-kode_pohon_profesi-input"
                    placeholder="Masukkan Kode Pohon Profesi..."
                    type="text"
                    v-model="kode_pohon_profesi"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-6">
                <div class="text-start">
                  <router-link
                    :to="{ name: 'render', query: { page: 'Pohon Profesi' } }"
                    class="btn btn-warning"
                    ><i class="fa fa-arrow-left"></i> Kembali</router-link
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  <b-button type="reset" variant="danger"
                    ><i class="fa fa-redo-alt"></i> Reset</b-button
                  >
                  &nbsp;
                  <b-button type="submit" variant="primary"
                    ><i class="fa fa-save"></i> Simpan</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>



