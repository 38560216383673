<script>
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { decryptData } from '@/helpers/crypt.js';
export default {
  components: {
    // Datatable,
    Pagination,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      {
        width: "auto",
        label: "NIP",
        name: "ja_nip",
      },
      {
        width: "auto",
        label: "Nama",
        name: "ja_nama",
      },
      { width: "150px", label: "Rekomendasi Soft Competency", name: "rekomendasi_kompetensi" },
      { width: "150px", label: "Rekomendasi Potensi", name: "rekomendasi_potensi" },
      { width: "150px", label: "Rekomendasi Atasan", name: "rekomendasi_atasan" },
      { width: "150px", label: "Rekomendasi PEG POG", name: "rekomendasi_akhir" },
      { width: "175px", label: "Download", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      option_asesmen_grade: this.data_jadwal.asesmen_grade,
      selected_id_asesmen_grade: "",
      selected_asesmen_grade: [],

      idjadwal: '',
      showModalAddPeserta: false,
      showModalImport: false,
      option_user: [],
      selected_user: [],
      selectedId: [],
      checkedAll: false,
      table_data: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      list_nip: [],
      sessionRoleId: localStorage.session_role_active_id,
      laporanData: {
        id_jadwal: "",
        nip: "",
        id_jenisasesmen: "",
      },
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_jadwal: "",
        role_id: localStorage.session_role_active_id,
        username: localStorage.session_username,
        id_jenisasesmen: "",
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      path_file_import: "",
      isLoadingZip: false,
      countNip: 0,
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
    // this.getDataTable();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/nilai-pegpog"
    ) {
      let self = this;
      self.getCountNip();
      self.loadingTable = true;
      self.tableData.id_jadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.selectedId = self.table_data
              .filter(item => item.RekomendasiKompetensi == null && item.RekomendasiPotensi == null && item.RekomendasiAkhir == null)
              .map(item => item.ja_nip);
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            
            if (response_data_fix.list_data.data.length < self.countNip) {
              this.getNip();
            }
            if (self.selectedId.length > 0) {
              this.hitungRekomendasiAll();
            }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getCountNip(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/nip-nilai-pegpog"
    ) {
      let self = this;
      self.tableData.id_jadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.list_nip = response_data_fix.list_data.data;
            self.selectedId = response_data_fix.list_data.map(item => item.ja_nip)
            self.countNip = response_data_fix.list_data.length;
          
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getNip(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/nip-nilai-pegpog"
    ) {
      let self = this;
      self.tableData.id_jadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.list_nip = response_data_fix.list_data.data;
            self.selectedId = response_data_fix.list_data.map(item => item.ja_nip)
            self.countNip = response_data_fix.list_data.length;
            if (self.selectedId.length != 0) {
              this.hitungRekomendasiAll();
            }
            if (response_data_fix.list_data.length == 0) {
              self.loadingTable = false;
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    HitungRekomendasi(data) {
      if (data != null) {
        this.selected_user = data.ja_nip;
      }
      if (data.rekomendasi_atasan == null) {
        Swal.fire({
          icon: "info",
          text: "Survey Atasan Belum Diisi",
        });
        return false;
      }


      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/hitung-nilai-pegpog",
        data: {
          selected_nip: this.selected_user,
          id_jadwal: this.idjadwal,
          id_jenisasesmen: this.data_jadwal.m_dirkom_idm_dirkom,
          idUserLogin: localStorage.session_user_id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      }

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 203) {
            Swal.fire({
              icon: "info",
              text: response_data.data.message,
            });
          }
          if (response_data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: response_data.data.message,
            });
            this.getDataTable();
          }

        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    hitungRekomendasiAll() {
      let self = this;
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/hitung-nilai-pegpog-multiple",
        data: {
          selected_nip: self.selectedId,
          id_jadwal: self.idjadwal,
          id_jenisasesmen: self.selected_asesmen_grade.id_jenis_asesmen,
          idUserLogin: localStorage.session_user_id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      }

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 203) {
            Swal.fire({
              icon: "info",
              text: response_data.data.message,
            });
          }
          if (response_data.meta.code == 200 && response_data.data[0].data.status != "Error") {
            // Swal.fire({
            //   icon: "success",
            //   title: "Berhasil",
            //   text: response_data.data.message,
            // });
            this.getDataTable();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Beberapa Tidak Dapat Menghitung",
            });
            self.loadingTable = false;
          }

        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    checkPeserta() {
      let self = this;
      if (this.checkedAll) {
        self.table_data.forEach((option) => {
          if (option.rekomendasi_atasan) {
            self.selectedId.push(option.ja_nip);
          }
        });
      } else {
        self.selectedId = [];
      }
    },

    getLaporan(id) {
      let self = this;
      self.laporanData.id_jadwal = self.idjadwal;
      self.laporanData.nip = id;
      self.laporanData.id_jenisasesmen = this.tableData.id_jenisasesmen;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/laporan-nilai-pegpog",
        params: self.laporanData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];

          let filename = response_data_fix.filename;
          const downloadUrl = process.env.VUE_APP_BACKEND_URL + `/storage/pdf_laporan_nilai/${filename}`;
          window.open(downloadUrl, '_blank');

        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Tidak Dapat Mengunduh Laporan",
            text: "Data tidak lengkap",
          });
        });
    },

    exportExcel() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/excel-nilai-pegpog",
        params: {
          id_jadwal: self.idjadwal,
          role_id: self.sessionRoleId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          let filename = response_data_fix.filename;
          const downloadUrl = process.env.VUE_APP_BACKEND_URL + `/storage/excel_penilaian_pegpog/${filename}`;
          window.open(downloadUrl, '_blank');
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    exportZip() {
      let self = this;
      self.isLoadingZip = true;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/zip-nilai-pegpog",
        params: {
          id_jadwal: self.idjadwal,
          id_jenisasesmen: this.tableData.id_jenisasesmen,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          let filename = response_data_fix.filename;
          const downloadUrl = process.env.VUE_APP_BACKEND_URL + `${filename}`;
          window.open(downloadUrl, '_blank');
          self.isLoadingZip = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    myFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return (
        option.u_nama.toLowerCase().indexOf(temp) > -1 ||
        option.u_nip.toLowerCase().indexOf(temp) > -1
      );
    },

    onChangeAsesmenGrade() {
      this.selected_id_asesmen_grade =
        this.selected_asesmen_grade?.id_jenis_asesmen;
      this.tableData.id_jenisasesmen =
        this.selected_asesmen_grade?.id_jenis_asesmen;
      this.getDataTable();
    },
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-4">
        <label for="Batch">Pilih Asesmen Grade</label>
        <v-select :options="option_asesmen_grade" label="nama_jenis_asesmen" v-model="selected_asesmen_grade"
          @update:modelValue="onChangeAsesmenGrade()" placeholder="-Pilih Jenis Asesmen-"
          class="text-secondary"></v-select>
      </div>
    </div>
    <div class="row mt-4" v-if="selected_id_asesmen_grade == ''">
      <b-alert variant="primary" show>Silahkan pilih salah satu Asesmen Grade</b-alert>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-md-12 mb-2">
          <div class="d-inline-block" v-if="sessionRoleId != 9">
            <a class="btn btn-sm btn-info me-2" @click="exportExcel()">Export Excel</a>
            <a class="btn btn-sm btn-success me-2" v-if="!isLoadingZip" @click="exportZip()">Download Semua Laporan</a>
            <a class="btn btn-sm btn-success me-2 disabled" v-else>Proses...</a>
            <!-- <a class="btn btn-sm btn-danger" @click="hitungRekomendasiAll()">Hitung Rekomendasi</a> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="row mb-3"></div>
          <hr />
          <div class="row">
            <div class="col-md-4">
              <table>
                <tr>
                  <td>Show</td>
                  <td>
                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%"
                      v-model="tableData.length" @change="getDataTable()">
                      <option value="10" selected>10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </td>
                  <td>Entries</td>
                </tr>
              </table>
            </div>
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-3">
              <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..."
                v-model="tableData.search" @input="searchDatatable" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table mb-0 table-bordered table-condensed table-hover">
              <thead class="bg-dark text-center text-white">
                <tr>
                  <!-- <th rowspan="2" class="text-center"><input type="checkbox" v-model="checkedAll"
                      @change="checkPeserta($event)" /></th> -->
                  <th rowspan="2" class="text-center">No</th>
                  <th rowspan="2" class="text-center" style="width:100px;">NIP</th>
                  <th rowspan="2" class="text-center" style="width: auto;">NAMA</th>
                  <th rowspan="2" class="text-center" style="width: auto;">SURVEY</th>
                  <th colspan="3" class="text-center" style="width: auto;">REKOMENDASI</th>
                  <th rowspan="2" class="text-center" style="width: auto;">AKSI</th>
                </tr>
                <tr>
                  <th class="text-center" style="width: auto;">SOFT COMPETENCY</th>
                  <th class="text-center" style="width: auto;">POTENSI</th>
                  <th class="text-center" style="width: auto;">AKHIR</th>
                </tr>
                <!-- <tr>
                  </tr> -->

              </thead>
              <tbody>
                <tr v-if="loadingTable">
                  <td colspan="8">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="table_data == ''">
                  <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                </tr>
                <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                  <!-- <td class="text-center">
                    <div>
                      <input v-if="row_data.rekomendasi_atasan" type="checkbox" :id="row_data.ja_nip" v-model="selectedId"
                        :value="row_data.ja_nip" />
                    </div>
                  </td> -->
                  <td class="text-center">
                    <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                    <div v-else>
                      {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                      }}{{ key_data + 1 }}.
                    </div>
                  </td>
                  <td class="text-center">{{ row_data.ja_nip }}</td>
                  <td class="text-center">{{ row_data.ja_nama }}</td>
                  <td class="text-center">{{ row_data.rekomendasi_atasan }}</td>
                  <td class="text-center">{{ row_data.RekomendasiKompetensi }}</td>
                  <td class="text-center">{{ row_data.RekomendasiPotensi }}</td>
                  <td class="text-center">{{ row_data.RekomendasiAkhir }}</td>

                  <td class="text-center">
                    <button v-if="row_data.rekomendasi_atasan &&
                      row_data.RekomendasiKompetensi == null &&
                      row_data.RekomendasiPotensi == null &&
                      row_data.RekomendasiAkhir == null" type="button" class="btn btn-danger btn-sm me-2"
                      @click="HitungRekomendasi(row_data)">
                      Hitung rekomendasi
                    </button>
                    <!-- <button v-else type="button" class="btn btn-danger btn-sm me-2" disabled>
                      Hitung rekomendasi
                    </button> -->
                    <button
                      v-if="row_data.RekomendasiKompetensi && row_data.RekomendasiPotensi && row_data.RekomendasiAkhir"
                      type="button" class="btn btn-info btn-sm" @click="getLaporan(row_data.ja_nip)">
                      Lihat Laporan
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <pagination :pagination="pagination" @to-page="toPage"></pagination>
      </div>
    </div>
  </div>
</template>