<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: ["data_peserta","id_asesi","jct_id","idjadwal"],
  data() {
    return {
      table_data: [],
      detail_checklist: {
        idjadwal_checklist: [],
        text: [],
      },
    };
  },
  mounted() {
    let self = this;
    self.getData();
  },
  methods: {
    getData() {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/penjadwalan/detail-center/get-detail-checklist",
        params: {
          id_asesi: this.$props.id_asesi,
          jct_id: this.$props.jct_id,
          idjadwal: this.$props.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data;
            if (response_data_fix.list_data.length > 0) {
              response_data_fix.list_data.forEach((element) => {
                element.jadwal_checklist.forEach((element2) => {
                  if (element2.jc_check == "YES") {
                    element2.checked = true;
                  } else {
                    element2.checked = false;
                  }
                });
              });
            }
            this.checkboxChange();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    checkboxChange() {
      let self = this;
      let data = self.table_data;
      let data_fix = [];
      data.forEach((element) => {
        element.jadwal_checklist.forEach((element2) => {
          if (element2.checked) {
            data_fix.push({
              idjadwal_checklist: element2.idjadwal_checklist,
              keterangan: element2.jc_keterangan,
            });
          }
        });
      });
      self.detail_checklist = data_fix;
      // console.log(self.detail_checklist);
    },
    submit(){
      var config = {
        url : process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/store-checklist",
        method : "post",
        data : {
          idjadwal : this.$props.idjadwal,
          id_asesi : this.$props.id_asesi,
          jct_id : this.$props.jct_id,
          detail_checklist : this.detail_checklist
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        }
      }
      axios(config).then((response) => {
        let response_data = response.data;
        let response_data_fix = response_data.data[0];
        if (response_data.meta.code == 200) {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: response_data.meta.message,
          });
          this.getData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
    }
  },
};
</script>
<template>
  <div class="row">
    <div class="table-responsive">
      <table class="table table-bordered mb-0 mt-2">
        <thead class="bg-dark text-white" style="padding: 10px">
          <th style="padding: 5px" >No</th>
          <th style="padding: 5px">Tipe</th>
          <th style="padding: 5px">Cek</th>
          <th style="padding: 5px">Keterangan</th>
        </thead>
        <tbody>
          <template v-for="row_data in table_data" :key="row_data">
            <td style="padding: 10px;" class="bg-info text-white h6" :colspan="4">
                {{ row_data.kk_nama }}
              </td>
            <tr v-for="(checklist, key) in row_data.jadwal_checklist" :key="key">
                <td style="width: 5px;">{{ key + 1 }}</td>
                <td>{{ checklist.jc_txt_nama }}</td>
                <td v-if="checklist.jc_check === 'NO'">
                  <input type="checkbox" v-model="checklist.checked" @change="checkboxChange()" />
                </td>
                <td v-else>
                  <input type="checkbox" v-model="checklist.checked" @change="checkboxChange()" checked />
                </td>
                <td>
                  <input type="text" v-model="checklist.jc_keterangan" @change="checkboxChange() " />
                </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12 text-end">
      <button class="btn btn-primary" @click="submit"> <span><i class="fa fa-save"></i> Simpan</span></button>
    </div>
    
  </div>
</template>
