<script>
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import { decryptData } from "@/helpers/crypt.js";
import FormAddPesertaSuksesi from "./tambah-peserta-suksesi.vue";
export default {
  components: {
    Pagination,
    FormAddPesertaSuksesi,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "20px", label: "", name: "-" },
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      {
        width: "auto",
        label: "NIP",
        name: "ja_nip",
      },
      {
        width: "auto",
        label: "Nama",
        name: "ja_nama",
      },
      { width: "150px", label: "Unit", name: "ja_unit" },
      { width: "150px", label: "Jabatan", name: "ja_jabatan" },
      { width: "150px", label: "Jenjang Jabatan", name: "ja_jenjangjabatan" },
      {
        width: "150px",
        label: "Kode Posisi Atasan",
        name: "ja_kodeposisi_atasanlangsung",
      },
      { width: "150px", label: "Nama Atasan", name: "user_by_kode" },

      { width: "175px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      idjadwal: "",
      showModalAddPeserta: false,

      showModalAddPesertaSuksesi: false,

      showModalImport: false,
      sessionUsername: localStorage.session_username,
      sessionRoleId: localStorage.session_role_active_id,
      option_user: [],
      selected_user: [],
      selectedId: [],
      selectedIdAsesi: [],
      table_data: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      checkedAll: false,
      filter_peserta: [],
      peserta_diundang: this.data_jadwal.jadwal_unit[0].ju_peserta_diundang,
      jumlah_peserta: 0,
      option_status: [
        {
          label: "Draft",
          status: "Draft",
        },
        {
          label: "Batal",
          status: "batal",
        },
        {
          label: "Verifikasi Asesi",
          status: "verifikasi",
        },
        {
          label: "Proses Verifikasi PTL",
          status: "proses_ptl",
        },
        {
          label: "MK Draft",
          status: "MK Draft",
        },
        {
          label: "MK Fix",
          status: "MK Fix",
        },
        {
          label: "Selesai",
          status: "selesai",
        },
      ],
      selected_status: "",

      showModalUpdateStatus: false,
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_jadwal: "",
        username: "",
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      path_file_import: "",
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
    this.tableData.id_jadwal = decryptData(this.$route.params.id);
    this.getDataTable();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    konfirmasiKehadiranPeserta(data) {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "POST",
        data: {
          id_jadwal: this.idjadwal,
          asesi: this.table_data,
          id_jadwalasesi: data.id_jadwalasesi,
          user_id: localStorage.session_user_id,
        },
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/assesitab/konfirmasi-kehadiran-asesi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            this.getDataTable();
            Swal.close();
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "jadwal-asesi-peserta") {
      let self = this;
      self.loadingTable = true;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.jumlah_peserta = response_data_fix.count;
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    checkAll() {
      let self = this;
      self.table_data.forEach((option) => {
        self.selectedId.push(option.id_jadwalasesi);
        self.selectedIdAsesi.push(option.ja_idasesi);
      });
    },
    //search & get jenis asesme
    getPeserta() {
      let self = this;
      var config = {
        method: "get",
        params: {
          keyword: "",
          filter_peserta: self.filter_peserta,
        },
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user-peserta",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_user = response.data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user-peserta",
        params: {
          keyword: String(search),
          filter_peserta: self.filter_peserta,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_user = response.data.data.referensi;

          loading(false);
        }
      });
    },
    submitPeserta() {
      let self = this;
      var count_add_peserta = self.selected_user.length;
      var sum_peserta = count_add_peserta + self.jumlah_peserta;
      if (sum_peserta > self.peserta_diundang) {
        Swal.fire({
          icon: "error",
          title: "Peringatan!",
          text: `Jumlah Peserta Melebihi Batas, Max ${self.peserta_diundang}`,
        });
        return false;
      }
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/store-peserta",
        data: {
          peserta: JSON.stringify(self.selected_user),
          id_jadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Menambahkan Peserta",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                window.location.reload();
              }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
    },
    inputFileImport() {
      let self = this;

      if ($("#formImport")[0].files[0]) {
        if ($("#formImport")[0].files[0].size < 2242880) {
          $("#uploadLoading").html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
          var urlres = process.env.VUE_APP_BACKEND_URL;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#formImport")[0].files[0]);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;
            urlres += path_file;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.path_file_import = path_file;
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    submitImport() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/import-peserta",
        data: {
          path_file: self.path_file_import,
          id_jadwal: this.idjadwal,
          max_peserta: self.peserta_diundang,
          jumlah_sekarang: self.jumlah_peserta,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Menambahkan Data Peserta ",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                window.location.reload();
              }
            });
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.data?.error || "Data Gagal Di Import";
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errorMessage,
            timerProgressBar: true,
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              window.location.reload();
            }
          });
        });
    },
    deletePeserta(id, id_asesi) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "jadwal-asesi-peserta",
            data: {
              id_jadwalasesi: id,
              idjadwal: self.idjadwal,
              id_asesi: id_asesi,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            if (response.data.meta.code == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Menghapus Data Peserta ",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    myFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return option.u_nama.toLowerCase().indexOf(temp) > -1 || option.u_nip.toLowerCase().indexOf(temp) > -1;
    },
    updateStatus(status) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin Mengirim data jadwal ke PTL?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/update-status",
            data: {
              id: self.data_jadwal.idjadwal,
              tipe: "Survey",
              tipe_jadwal: "PEGPOG",
              status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            if (response.data.meta.code == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Mengubah Status ",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    downloadTemplate() {
      const url = process.env.VUE_APP_BACKEND_URL + "/storage/template_import_peserta.xlsx";
      window.location.href = url;
    },
    checkPeserta() {
      let self = this;
      if (this.checkedAll) {
        self.table_data.forEach((option) => {
          console.log(option);
          self.selectedId.push(option.id_jadwalasesi);
          self.selectedIdAsesi.push(option.ja_idasesi);
        });
      } else {
        self.selectedId = [];
        self.selectedIdAsesi = [];
      }
    },
    showFormModalAddPeserta() {
      let self = this;
      self.showModalAddPeserta = !self.showModalAddPeserta;
      this.getPeserta();
    },
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-md-12 mb-2" v-if="data_jadwal.j_status == 'Draft'">
        <button class="btn btn-info btn-sm me-2" v-if="selectedId.length == 0 && sessionRoleId != 9" @click="checkAll()">Pilih Semua</button>
        <button class="btn btn-info btn-sm me-2" v-if="selectedId.length > 0 && sessionRoleId != 9" @click="selectedId = []">Batlkan Pilihan</button>
        <button class="btn btn-danger btn-sm me-2" v-if="selectedId.length > 0 && sessionRoleId != 9" @click="deletePeserta(selectedId, selectedIdAsesi)">Hapus Terpilih</button>
        <div class="d-inline-block">
          <a class="btn btn-sm btn-success me-2" @click="showModalImport = !showModalImport" v-if="sessionRoleId != 9"><i class="fa fa-plus"></i> Import Peserta</a>
          <a class="btn btn-sm btn-success me-2" @click="showFormModalAddPeserta()" v-if="sessionRoleId != 9"><i class="fa fa-plus"></i> Tambah Peserta</a>
          <a class="btn btn-sm btn-success" @click="showModalAddPesertaSuksesi = !showModalAddPesertaSuksesi" v-if="data_jadwal.idjadwal_psikologi != null"
            ><i class="fa fa-plus"></i> Tambah Peserta Asesmen Suksesi</a
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-2">
        <div class="row mb-3"></div>
        <hr />
        <div class="row">
          <div class="col-md-4">
            <table>
              <tr>
                <td>Show</td>
                <td>
                  <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                    <option value="10" selected>10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
          </div>
          <div class="col-md-5">&nbsp;</div>
          <div class="col-md-3">
            <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="searchDatatable" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <b-form @submit="konfirmasiKehadiranPeserta" @reset="onReset">
        <table class="table mb-0 table-bordered table-condensed table-hover">
          <thead class="bg-dark text-center text-white">
            <tr>
              <th>
                <input type="checkbox" v-model="checkedAll" @change="checkPeserta($event)" />
              </th>
              <th>No</th>
              <th>Nip</th>
              <th>Nama</th>
              <th>Unit</th>
              <th>Jabatan</th>
              <th>Jenjang Jabatan</th>
              <th>Kode Atasan</th>
              <th>Nama atasan</th>
              <th>Konfirmasi</th>
              <th>Kehadiran</th>
              <th v-if="sessionRoleId != 9">Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loadingTable">
              <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
            </tr>
            <tr v-else-if="table_data == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
              <td>
                <div>
                  <input type="checkbox" :id="row_data.id_jadwalasesi" v-model="selectedId" :value="row_data.id_jadwalasesi" />
                </div>
              </td>
              <td class="text-center">
                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
              </td>
              <td>{{ row_data.ja_nip }}</td>
              <td>{{ row_data.ja_nama }}</td>
              <td>{{ row_data.ja_unit }}</td>
              <td>{{ row_data.ja_jabatan }}</td>
              <td>{{ row_data.ja_jenjangjabatan }}</td>
              <td>{{ row_data.ja_kodeposisi_atasanlangsung }}</td>
              <td>{{ row_data.user_by_kode }}</td>
              <td style="background: #ccc">
                <div style="display: flex">
                  <select class="form-control mx-2" style="width: 30%" v-model="row_data.ja_kehadiran">
                    <option value="0">TH</option>
                    <option value="1">H</option>
                  </select>
                  <input type="text" style="width: 70%" class="form-control" v-model="row_data.ja_keterangan" />
                </div>
              </td>
              <td class="text-center">
                <i v-if="row_data.ja_kehadiran == 1" class="fas fa-check-circle text-success"></i>
                <i v-else class="fas fa-times-circle text-danger"></i>
              </td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  v-if="data_jadwal.j_status == 'Draft' && sessionRoleId != 9"
                  v-on:click="deletePeserta([row_data.id_jadwalasesi], [row_data.ja_idasesi])"
                >
                  <i class="fa fa-trash"></i> Hapus
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style="background: #ccc" class="text-center">
                <button type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> Simpan Konfirmasi</button>
              </th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </b-form>
    </div>
    <div class="row">
      <pagination :pagination="pagination" @to-page="toPage"></pagination>
    </div>
    <div class="row">
      <div class="col-12 text-end">
        <button class="btn btn-success btn-md" @click="updateStatus('proses_ptl')" v-if="sessionRoleId != 9 && data_jadwal.j_status == 'Draft'">Kirim Ke PTL</button>
      </div>
    </div>
  </div>

  <b-modal v-model="showModalAddPeserta" id="modal-lg" size="lg" title="Tambah Peserta" title-class="font-18" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <b-form-group class="mb-3" label="Masukkan Peserta" label-for="lokasi">
          <v-select :options="option_user" multiple label="u_nama" :filterBy="myFilter" placeholder="-Cari NIP atau Nama Peserta-" v-model="selected_user" @search="onSearchUser">
            <template v-slot:option="option">
              <span :class="option.u_nama"></span>
              {{ option.u_nama }} -
              <span :class="option.u_nip"></span>
              {{ option.u_nip }}
            </template></v-select
          >
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="d-grid gap-2 col-6 mx-auto">
        <button class="btn btn-primary" type="button" @click="submitPeserta()">Simpan</button>
      </div>
    </div>
  </b-modal>
  <b-modal v-model="showModalImport" id="modal-lg" size="lg" title="Tambah Peserta" title-class="font-18" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <label for="formImport" class="form-label">Pilih Excel</label>
          <input class="form-control" type="file" id="formImport" v-on:change="inputFileImport()" />
          <div class="respond-input-file float-left" id="uploadLoading"></div>
          <small class="float-right" style="color: red">*) Max File 2 MB</small>
          <div>
            <button type="button" class="btn btn-sm btn-success" @click.prevent="downloadTemplate()"><i class="bx bx-import"></i> Download Template Import</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button type="button" class="btn btn-primary" @click="submitImport()"><i class="fa fa-save"></i> Simpan</button>
        </div>
      </div>
    </div>
  </b-modal>
  <b-modal v-model="showModalUpdateStatus" id="modal-lg" size="lg" title="Tambah Peserta" title-class="font-18" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <label for="formImport" class="form-label">Update Status Jadwal</label>
          <v-select :options="option_status" v-model="selected_status" label="label" placeholder="-Pilih Status-"></v-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button type="button" class="btn btn-primary" @click="updateStatus(selected_status.status)"><i class="fa fa-save"></i> Simpan</button>
        </div>
      </div>
    </div>
  </b-modal>
  <b-modal v-model="showModalAddPesertaSuksesi" id="modal-lg" size="lg" title="Tambah Peserta" title-class="font-18" hide-footer>
    <FormAddPesertaSuksesi v-if="showModalAddPesertaSuksesi" :idjadwal="idjadwal" :idjadwal_psikologi="data_jadwal.idjadwal_psikologi" />
  </b-modal>
</template>
