<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { encryptData } from '@/helpers/crypt.js';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Jadwal Potensi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {
    dataPeserta: {
      type: String,
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      data_peserta: [],
      data_jadwal: [],

      form_data: {
        j_metode: "Priskologi",
        m_unit_idm_unit: "",
        j_txt_unit: "",
        m_lokasi_idm_lokasi: "",
        j_txt_lokasi: "", //
        j_metode_jadwal: "",
        m_user_id_ptl: "",
        m_user_nip_ptl: "",
        j_txt_ptl: "",
        j_json_petugas: "",
        j_tanggal_mulai: "", //
        j_tanggal_sampai: "", //
        j_jam_mulai: "", //
        j_jam_sampai: "", //
        j_mulai_penilaian: "",
        j_akhir_penilaian: "",
        j_impact: "",
        j_keterangan: "",
        j_agreement: "",
        m_jadwal_kegiatan_idm_kegiatan: "", //
        j_txt_kegiatan: "",
        m_dirkom_idm_dirkom: "",
        j_txt_dirkom: "",
        j_tipe: "",
        m_batre_test_bt_id: "",
        j_txt_batre_test: "",
        j_materi: "",
        j_bentuk_dinamika: "",
        j_bentuk_penilaian: "",
        j_json_alattes: "",
        user_id : localStorage.getItem("session_user_id"),
        user_name : localStorage.getItem("session_name"),
      },

      option_unit: [],
      selected_unit: "",

      option_lokasi: [],
      checkedLokasi: false,
      selected_lokasi: "",

      temp_id_jadwal : "",
      selected_metode: "",

      option_kegiatan: [],
      selected_kegiatan: "",

      option_jenis_asesmen: [],
      seleceted_jenis_asesmen: "",

      option_batre_test: [],
      seleceted_batre_test: "",

      option_alat_test: [],

      option_materi: [],
      seleceted_materi: "",

      option_checklist: [],
      selected_checklist: "",

      json_fix_peserta: [],

      form_unit: [
        {
          m_unit_slug: "",
          m_unit_idm_unit: "",
          jadwal_idjadwal: null,
          ju_txt_unit: "",
          jumlah_diundang: "",
        },
      ],
      selected_unit_peserta: [],
      selected_jumlah_peserta: [],

      lokasi_text: "",
      json_petugas: "",
      showbatre: false,
    };
  },
 
  mounted() {
    let self = this;
    self.searchUnit(true);
    self.searchLokasi(true);
    self.searchKegiatan(true);
    self.searchJenisAsesmen(true);
    self.searchBatreTest(true);
    self.searchMateri(true);
    console.log(self.data_peserta);
  },

  created() {
    this.data_peserta = JSON.parse(window.history.state.data_peserta);
    this.data_jadwal = JSON.parse(window.history.state.data_jadwal);

    this.form_data.m_user_id_ptl = this.data_jadwal.m_user_id_ptl;
    this.form_data.m_user_nip_ptl = this.data_jadwal.m_user_nip_ptl;
    this.form_data.j_txt_ptl = this.data_jadwal.j_txt_ptl;
    this.form_data.j_json_petugas = this.data_jadwal.j_json_petugas;
    this.json_petugas = JSON.parse(this.data_jadwal.j_json_petugas);
    this.selected_kegiatan = {
      id : this.data_jadwal.m_jadwal_kegiatan_idm_kegiatan,
      kegiatan : this.data_jadwal.j_txt_kegiatan
    }

    this.form_data.m_jadwal_kegiatan_idm_kegiatan = this.data_jadwal.m_jadwal_kegiatan_idm_kegiatan;
    this.form_data.j_txt_kegiatan = this.data_jadwal.j_txt_kegiatan;

  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    //Dropdown Unit
    onSearchUnit(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUnit(loading, search, this);
      }
    },
    searchUnit: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/unit",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_unit = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeUnit() {
      let self = this;
      self.form_data.m_unit_idm_unit = self.selected_unit.m_unit_idm_unit;
      self.form_data.j_txt_unit = self.selected_unit.ju_txt_unit;
    },

    //Pilih Lokasi
    onSearchLokasi(search, loading) {
      if (search.length) {
        loading(true);
        this.searchLokasi(loading, search, this);
      }
    },
    searchLokasi: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-lokasi",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_lokasi = response.data.data.referensi;
          loading(false);
        }
      });
    },
    checkLokasi() {
      let self = this;
      this.$nextTick(() => {
        console.log(self.checkedLokasi);
      });
    },
    onChangeLokasi() {
      let self = this;
      self.form_data.m_lokasi_idm_lokasi = self.selected_lokasi.ml_id;
      self.form_data.j_txt_lokasi = self.selected_lokasi.ml_nama;
    },
    // submitForm() {
    //   console.log(this.form_data);
    // },

    //Kegiatan
    onSearchKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchKegiatan(loading, search, this);
      }
    },
    searchKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeKegiatan() {
      let self = this;
      self.form_data.m_jadwal_kegiatan_idm_kegiatan = self.selected_kegiatan.id;
      self.form_data.j_txt_kegiatan = self.selected_kegiatan.kegiatan;
    },

    //Jenis Asesmen
    onSearchJenisAsesmen(search, loading) {
      if (search.length) {
        loading(true);
        this.searchJenisAsesmen(loading, search, this);
      }
    },
    searchJenisAsesmen: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        params: {
          keyword: search,
          id_kegiatan : self.form_data.m_jadwal_kegiatan_idm_kegiatan
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_jenis_asesmen = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeJenisAsesmen() {
      let self = this;
      self.form_data.m_dirkom_idm_dirkom = self.seleceted_jenis_asesmen.id;
      self.form_data.j_txt_dirkom =
        self.seleceted_jenis_asesmen.nama_jenisasesmen;
      self.searchCheklist();
    },

    //Batre Test
    onSearchBatreTest(search, loading) {
      if (search.length) {
        loading(true);
        this.searchBatreTest(loading, search, this);
      }
    },
    searchBatreTest: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-test",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_batre_test = response.data.data.referensi;

          loading(false);
        }
      });
    },
    onChangeBatreTest() {
      let self = this;
      self.form_data.m_batre_test_bt_id = self.seleceted_batre_test.bt_id;
      self.form_data.j_txt_batre_test = self.seleceted_batre_test.bt_nama;
      self.getAlatTes();
    },

    //alattes
    getAlatTes() {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-alat-test",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          id_batretest: self.seleceted_batre_test.bt_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_alat_test = response_data.data.referensi;
            self.form_data.j_json_alattes = self.option_alat_test;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //materi
    onSearchMateri(search, loading) {
      if (search.length) {
        loading(true);
        this.searchMateri(loading, search, this);
      }
    },
    searchMateri: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_materi = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeMateri() {
      let self = this;
      self.form_data.j_materi = self.seleceted_materi.mm_nama;
    },

    //checklist
    onSearchCheklist(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCheklist(loading, search, this);
      }
    },
    searchCheklist: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/checklist-asesmen",
        params: {
          keyword: search,
          id_jenis_asesmen: self.form_data.m_dirkom_idm_dirkom,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_checklist = response.data.data.referensi;
          loading(false);
        }
      });
    },

    submitForm() {
      let self = this;
      console.log(self.form_data);
        Swal.fire({ 
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
        });
        var config = {
          method: "post",
          url:
            process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/jadwal-potensi",
          data: self.form_data,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        };
        axios(config)
          .then((response) => {
            if (response.data.meta.code == 200) {
              // self.storeDataPetugas(response.data.data[0].idjadwal);
              self.temp_id_jadwal = response.data.data[0].idjadwal;
              self.submitPeserta(response.data.data[0].idjadwal);
              
            }
          })
          .catch((error) => {
            Swal.close();
            console.log(error);
          });
    },

    onChangeUnitPeserta(index) {
      let self = this;
      self.form_unit[index].ju_txt_unit =
        self.selected_unit_peserta[index].ju_txt_unit;
      self.form_unit[index].m_unit_idm_unit =
        self.selected_unit_peserta[index].m_unit_idm_unit;
      self.form_unit[index].m_unit_slug =
        self.selected_unit_peserta[index].m_unit_slug;
    },

    submitPeserta(idjadwal) {
      let self = this;

      self.data_peserta.forEach((element) => {
        self.json_fix_peserta.push({
          iduser : element.ja_idasesi,
          u_nip: element.ja_nip,
          u_nama: element.ja_nama,
          u_unit: element.ja_unit,
          u_kodeposisi_atasanlangsung: element.ja_kodeposisi_atasanlangsung,
          u_namajenjangjabatan: element.ja_jenjangjabatan,
          u_jenisjabatan: element.ja_jabatan,
        });
      });

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/store-peserta",
        data: {
          peserta: JSON.stringify(self.json_fix_peserta),
          id_jadwal: idjadwal,
          id_jadwal_old: self.data_jadwal.idjadwal,
          tipe_jadwal : "AP",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            // self.storeDataUnit(self.temp_id_jadwal);
            self.storeChecklist();

          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
      console.log(this.selected_user);
    },
    addFormUnit() {
      this.form_unit.push({
        m_unit_slug: "",
        m_unit_idm_unit: "",
        jadwal_idjadwal: null,
        ju_txt_unit: "",
        jumlah_diundang: "",
      });
    },
    clearFormUnit(index) {
      this.form_unit[index].m_unit_slug = "";
      this.form_unit[index].m_unit_idm_unit = "";
      this.form_unit[index].jadwal_idjadwal = null;
      this.form_unit[index].ju_txt_unit = "";
      this.form_unit[index].jumlah_diundang = "";
      this.selected_unit_peserta.splice(index, 1);
      this.selected_jumlah_peserta.splice(index, 1);
      this.form_unit.splice(index, 1);
    },
    storeDataUnit(idjadwal) {
      let self = this;
      for (let index = 0; index < self.form_unit.length; index++) {
        self.form_unit[index].jadwal_idjadwal = idjadwal;
      }
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/store-unit",
        data: {
          idjadwal,
          json_unit: self.form_unit,

        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.storeChecklist();
        }
      });
    },
    storeChecklist(idjadwal) {
      let self = this;

      for (let index = 0; index < self.form_unit.length; index++) {
        self.form_unit[index].jadwal_idjadwal = idjadwal;
      }
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/store-checklist",
        data: {
          id_jadwal : self.temp_id_jadwal,
          json_checklist: self.option_checklist,

        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Berhasil Menambahkan Jadwal",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "detail-penjadwalan",params: { id: encryptData(self.data_jadwal.idjadwal).toString() } });
            }
          });
        }
      });
    },
    onChangeTipe(){
      if(this.form_data.j_tipe == 'Kompetensi & Potensi' || this.form_data.j_tipe == 'Potensi'){
        this.showbatre = true;
      }else(
        this.showbatre = false
      )
      return this.showbatre
    },
    validasi(){
      if (this.form_data.j_txt_unit && this.form_data.m_unit_idm_unit== "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unit Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_lokasi == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Lokasi Jadwal Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_metode_jadwal == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Metode Jadwal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_ptl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ptl Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_txt_tgl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Tidak Boleh Kosong",
        });
        return false;
      } 

      if(this.form_data.j_tanggal_mulai == ""){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Mulai Tidak Boleh Kosong",
        });
        return false;
      }

      if(this.form_data.j_tanggal_sampai == ""){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Sampai Tidak Boleh Kosong",
        });
        return false;
      }

      if(this.form_data.j_jam_mulai == ""){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Mulai Tidak Boleh Kosong",
        });
        return false;
      }
      if(this.form_data.j_jam_sampai == ""){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Sampai Tidak Boleh Kosong",
        });
        return false;
      } 
      if(this.form_data.j_mulai_penilaian == ""  && this.form_data.j_akhir_penilaian == ""){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "jam Penilaian Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_impact == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Adverse Impact Tidak Boleh Kosong",
        });
        return false;
      }
      // j_keterangan
      if (this.form_data.j_keterangan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Keterangan Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_agreement == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Agreement Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_kegiatan
      if (this.form_data.j_txt_kegiatan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Kegiatan Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_dirkom
      if (this.form_data.j_txt_dirkom == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "jenis assesmen Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_tipe == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "tipe Ap Tidak Boleh Kosong",
        });
        return false;
      }
      else {
        Swal.fire({
          title: "Apakah Anda Yakin?",
          text: "Data Akan Disimpan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Simpan!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm()
          }
        });
      }
    },
    updateTanggalPenilaian(){
      let self = this;
      console.log(self.form_data.j_tanggal_sampai);
      var mulai_penilaian = moment(self.form_data.j_tanggal_sampai, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
      var selesai_penilaian = moment(self.form_data.j_tanggal_sampai, "YYYY-MM-DD").add(4, 'days').format("YYYY-MM-DD");

      self.form_data.j_mulai_penilaian = mulai_penilaian;
      self.form_data.j_akhir_penilaian = selesai_penilaian;
    }

  },

};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card-body"></div>
    <div class="row">
      <h4>Tambah Jadwal Asesmen Center</h4>
    </div>
    <div class="row mt-4">
      <div class="col-5">
        <!-- Form Metode -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group class="mb-3" label="Metode" label-for="form-metode">
              <b-form-input
                id="form-metode"
                class="bg-secondary text-white"
                type="text"
                readonly
                v-model="form_data.j_metode"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <!-- Form Unit Pelaksana -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Unit Pelaksana"
              label-for="form-unit-pelaksana"
            >
              <v-select
                :options="option_unit"
                label="m_unit_slug"
                v-model="selected_unit"
                placeholder="-Pilih Unit-"
                @search="onSearchUnit"
                @update:modelValue="onChangeUnit()"
              ></v-select>
            </b-form-group>
          </div>
        </div>
        <!-- Form Lokasi -->
        <div class="row">
          <div class="col-md-2">
            <b-form-group class="mb-3" label="Lokasi" label-for="lokasi">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="checkedLokasi"
                  value="true"
                  id="autoSizingCheck"
                  @change="checkLokasi($event)"
                />
                <label class="form-check-label" for="autoSizingCheck">
                  Isi Text
                </label>
              </div>
            </b-form-group>
          </div>
          <div class="col-md-10">
            <b-form-group
              class="mb-3"
              v-if="!checkedLokasi"
              label="Pilih Lokasi"
              label-for="lokasi"
            >
              <v-select
                :options="option_lokasi"
                label="ml_nama"
                v-model="selected_lokasi"
                placeholder="-Pilih Lokasi-"
                @search="onSearchLokasi"
                @update:modelValue="onChangeLokasi()"
              ></v-select>
            </b-form-group>
            <b-form-group
              class="mb-3"
              v-else
              label="Lokasi"
              label-for="lokasiText"
            >
              <b-form-input
                id="lokasiText"
                placeholder="Masukkan Nama Lokasi"
                type="text"
                v-model="form_data.j_txt_lokasi"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <!-- Form Lokasi -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Metode Jadwal"
              label-for="form-metode"
            >
              <v-select
                :options="['Online', 'Offline']"
                v-model="form_data.j_metode_jadwal"
                placeholder="-Pilih Metode Jadwal-"
              ></v-select>
            </b-form-group>
          </div>
        </div>
        <!-- Form Metode -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group class="mb-3" label="PTL" label-for="form-ptl">
              <b-form-input
                id="form-ptl"
                class="bg-secondary text-white"
                type="text"
                readonly
                v-model="form_data.j_txt_ptl"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <!-- Form Metode -->
        <div class="row" v-for="(form, k) in json_petugas" :key="k">
          <div class="col-md-6">
            <b-form-group class="mb-3" label="Petugas" label-for="form-petugas">
              <b-form-input
                id="tgl-petugas"
                class="bg-secondary text-white"
                type="text"
                readonly
                v-model="form.jp_txt_petugas"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group class="mb-3" label="Tugas" label-for="form-tugas">
              <b-form-input
                id="form-tugas"
                class="bg-secondary text-white"
                type="text"
                readonly
                v-model="form.jp_txt_tugas"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              class="mb-3"
              label="Tanggal Mulai"
              label-for="tgl-mulai"
            >
              <b-form-input
                id="tgl-mulai"
                type="date"
                v-model="form_data.j_tanggal_mulai"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              class="mb-3"
              label="Tanggal Selesai"
              label-for="tgl-selesai"
            >
              <b-form-input
                id="tgl-selesai"
                placeholder="Tanggal Selesai"
                type="date"
                v-model="form_data.j_tanggal_sampai"
                @update:modelValue = "updateTanggalPenilaian()"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <!-- Form Jam -->
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              class="mb-3"
              label="Jam Mulai"
              label-for="form-jam-mulai"
            >
              <b-form-input
                id="form-jam-mulai"
                type="time"
                v-model="form_data.j_jam_mulai"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              class="mb-3"
              label="Jam Selesai"
              label-for="form-jam-selesai"
            >
              <b-form-input
                id="form-jam-selesai"
                type="time"
                v-model="form_data.j_jam_sampai"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <!-- mulai dan akhir penilaian -->
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              class="mb-3"
              label="Mulai Penilaian"
              label-for="form-jam-mulai"
            >
              <b-form-input
                id="form-jam-mulai"
                type="date"
                v-model="form_data.j_mulai_penilaian"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              class="mb-3"
              label="Jam Selesai"
              label-for="form-jam-selesai"
            >
              <b-form-input
                id="form-jam-selesai"
                type="date"
                v-model="form_data.j_akhir_penilaian"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <!-- Form Adverse Impact -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Adverse Impact"
              label-for="form-adverse"
            >
              <v-select
                :options="['Yes', 'No']"
                v-model="form_data.j_impact"
                placeholder="-Pilih Adverse impact-"
              ></v-select>
            </b-form-group>
          </div>
        </div>

        <!-- Form Keterangan -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Keterangan"
              label-for="keterangan"
            >
              <b-form-textarea
                id="keterangan"
                type="textarea"
                v-model="form_data.j_keterangan"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <!-- Form Agreement -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group class="mb-3" label="Agreement" label-for="agreement">
              <b-form-textarea
                id="agreement"
                type="textarea"
                v-model="form_data.j_agreement"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Kegiatan"
              label-for="form-unit-pelaksana"
            >
              <v-select
                :options="option_kegiatan"
                label="kegiatan"
                v-model="selected_kegiatan"
                placeholder="-Pilih Kegiatan-"
                @search="onSearchKegiatan"
                disabled
                @update:modelValue="onChangeKegiatan()"
              ></v-select>
            </b-form-group>
          </div>
        </div>
        <!-- Form Jenis Asesmen -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Jenis Asesmen"
              label-for="form-jenis-asesmen"
            >
              <v-select
                :options="option_jenis_asesmen"
                label="nama_jenisasesmen"
                v-model="seleceted_jenis_asesmen"
                placeholder="-Pilih Jenis Asesmen-"
                @search="onSearchJenisAsesmen"
                @update:modelValue="onChangeJenisAsesmen()"
              ></v-select>
            </b-form-group>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-12">
            <b-form-group class="mb-3" label="Tipe AP " label-for="form-tipe">
              <v-select
                :options="['Kompetensi & Potensi', 'Kompetensi', 'Potensi']"
                v-model="form_data.j_tipe"
                placeholder="-Pilih Tipe AP-"
                @update:modelValue="onChangeTipe()"
              ></v-select>
            </b-form-group>
          </div>
        </div>
        <!-- Form Batre Test -->
        <div class="row" v-if="showbatre == true" >
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Batre Test"
              label-for="form-batre-test"
            >
              <v-select
                :options="option_batre_test"
                label="bt_nama"
                v-model="seleceted_batre_test"
                placeholder="-Pilih Batre Test-"
                @search="onSearchBatreTest"
                @update:modelValue="onChangeBatreTest()"
              ></v-select>
            </b-form-group>
          </div>
        </div>
        <!-- Form Alat Tes -->
        <div class="row row-cols-2" v-if="seleceted_batre_test != ''">
          <b-form-group
            class="mb-3"
            label="Metode"
            label-for="form-metode"
            v-for="alattes in option_alat_test"
            :key="alattes.m_alat_tes_id"
          >
            <b-form-input
              id="form-metode"
              class="bg-secondary text-white"
              type="text"
              readonly
              v-model="alattes.m_alat_tes_nama"
            ></b-form-input>
          </b-form-group>
        </div>
        <!-- Form Materi -->
        <!-- <div class="row">
          <div class="col-md-12">
            <b-form-group class="mb-3" label="Materi" label-for="form-materi">
              <v-select
                :options="option_materi"
                label="mm_nama"
                v-model="seleceted_materi"
                placeholder="-Pilih Materi-"
                @search="onSearchMateri"
                @update:modelValue="onChangeMateri()"
              ></v-select>
            </b-form-group>
          </div>
        </div> -->
        
    
        <div class="row row-cols-2" v-if="seleceted_jenis_asesmen != ''">
          <b-form-group
            class="mb-3"
            label="Checklist"
            label-for="form-metode"
            v-for="checklist in option_checklist"
            :key="checklist.id_ca"
          >
            <b-form-input
              id="form-metode"
              class="bg-secondary text-white"
              type="text"
              readonly
              v-model="checklist.nama_checklist"
            ></b-form-input>
          </b-form-group>
        </div>
        <!-- Form Bentuk Penilaian
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Bentuk Penilaian "
              label-for="form-bentuk-penilaian"
            >
              <v-select
                :options="['Level', 'Rating']"
                v-model="form_data.j_bentuk_penilaian"
                placeholder="-Pilih Bentuk Penilaian-"
              ></v-select>
            </b-form-group>
          </div>
        </div> -->
        <div class="table-responsive bg-white">
          <table class="table table-bordered mb-0 mt-2">
            <thead class="bg-dark text-white" style="padding: 10px">
              <th style="padding: 5px">Nomor</th>
              <th style="padding: 5px">Nip</th>
              <th style="padding: 5px">Nama</th>
              <th style="padding: 5px">Unit</th>
              <th style="padding: 5px">Jabatan</th>
              <th style="padding: 5px">Jenjang Jabatan</th>
              <th style="padding: 5px">Kode Atasan</th>
            </thead>
            <tbody>
              <tr v-for="(peserta, k) in data_peserta" :key="peserta.ja_nip">
                <td>{{ k + 1 }}</td>
                <td>{{ peserta.ja_nip }}</td>
                <td>{{ peserta.ja_nama }}</td>
                <td>{{ peserta.ja_unit }}</td>
                <td>{{ peserta.ja_jabatan }}</td>
                <td>{{ peserta.ja_jenjangjabatan }}</td>
                <td>{{ peserta.ja_kodeposisi_atasanlangsung }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row col-12">
      <button
        class="btn btn-success mt-2 m-4"
        type="button"
        @click="validasi()"
      >
        <span><i class="fa fa-plus"></i> Submit Data </span>
      </button>
      <!-- <button
        class="btn btn-success mt-2 m-4"
        type="button"
        > 
        <span><i class="fa fa-plus"></i> Submit Data </span>
      </button> -->
    </div>
  </Layout>
</template>
