<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

import { debounce } from "@/helpers/debounce";
import Mk from "./detail-mk/mk.vue";
import { decryptData } from '../../../../helpers/crypt';

export default {
  page: {
    title: "Atur MK",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Mk,
  },
  data() {
    return {
      data_jadwal: [],
      data_simulasi: [],
      idjadwal: this.$route.params.id,
      data_petugas: [],
      showModalUpdateStatus: false,
      currentActiveIndex: 0,
      sessionRoleId: localStorage.session_role_active_id,
      option_status: [
        {
          label: "Draft",
          status: "Draft",
        },
        {
          label: "Batal",
          status: "batal",
        },
        {
          label: "Verifikasi Asesi",
          status: "verifikasi",
        },
        {
          label: "Proses Verifikasi PTL",
          status: "proses_ptl",
        },
        {
          label: "MK Draft",
          status: "MK Draft",
        },
        {
          label: "MK Fix",
          status: "MK Fix",
        },
        {
          label: "Selesai",
          status: "selesai",
        },
      ],
      selected_status: "",
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.idjadwal);
    this.getDataJadwal();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    getDataJadwal() {
      let self = this;
      let config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/mk/" +
          self.idjadwal,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          let response_data = response.data;
          self.data_jadwal = response_data.data.jadwal;
          self.data_simulasi = response_data.data.simulasi;
        })
        .catch((errors) => {
          console.log(errors);
          // console.log(errors.response.data);
          let response_error =
            errors.response == undefined ? "" : errors.response.data.data.error;
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: response_error,
          });
        });
    },
    updateStatus(status) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin Mengirim data jadwal ke PTL?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/jadwal-pegpog/update-status",
            data: {
              id: self.data_jadwal.idjadwal,
              status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            if (response.data.meta.code == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Mengubah Status ",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Atur MK</div>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td scope="row" style="width: 20%">ID Jadwal</td>
                        <td style="width: 5%">:</td>
                        <td class="d-flex justify-content-between">
                          <span>{{ data_jadwal.idjadwal }}</span>
                          <div>
                            <span
                              class="text-end badge bg-info"
                              v-if="data_jadwal.j_status == 'Draft'"
                            >
                              Draft
                            </span>
                            <span
                              class="text-end badge bg-danger"
                              v-else-if="data_jadwal.j_status == 'void'"
                            >
                              Void
                            </span>
                            <span
                              class="text-end badge bg-primary"
                              v-else-if="data_jadwal.j_status == 'verifikasi'"
                            >
                              Verifikasi
                            </span>
                            <span
                              class="text-end badge bg-success"
                              v-else-if="data_jadwal.j_status == 'proses_ptl'"
                            >
                              Proses Jadwal Ke PTL
                            </span>
                            <span class="text-end badge bg-primary" v-else>
                              {{ data_jadwal.j_status }}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Tanggal Mulai</td>
                        <td>:</td>
                        <td>{{ data_jadwal.j_tanggal_mulai }}</td>
                      </tr>
                      <tr>
                        <td>Tanggal Selesai</td>
                        <td>:</td>
                        <td>{{ data_jadwal.j_tanggal_sampai }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Kegiatan / Jabatan</td>
                        <td>:</td>
                        <td>{{ data_jadwal.j_txt_kegiatan }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Jenis Asesmen</td>
                        <td>:</td>
                        <td>{{ data_jadwal.j_txt_kegiatan }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Keterangan</td>
                        <td>:</td>
                        <td>{{ data_jadwal.j_keterangan }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td scope="row">PTL</td>
                        <td>:</td>
                        <td>
                          {{ data_jadwal.j_txt_ptl }} ({{
                            data_jadwal.m_user_nip_ptl
                          }})
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Materi</td>
                        <td>:</td>
                        <td>{{ data_jadwal.j_materi }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Simulasi</td>
                        <td>:</td>
                        <td>
                          <span
                            v-for="(simulasi, no) in data_simulasi"
                            :key="no"
                          >
                            {{ no + 1 }}. {{ simulasi.nama_simulasi }} <br />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Petugas</td>
                        <td>:</td>
                        <td>
                          <span
                            v-for="(petugas, no) in data_jadwal.petugas"
                            :key="no"
                          >
                            {{ no + 1 }}. {{ petugas.jp_txt_petugas }} (
                            {{ petugas.jp_txt_tugas }} ) <br />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg">
        <div class="card">
          <div class="card-body">
            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted text-secondary"
              v-model="currentActiveIndex"
            >
              <b-tab active>
                <template>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">MK</span>
                </template>
                <Mk :data_jadwal="data_jadwal" />
                <!-- <DataPeserta
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 0"
                  :data_jadwal="data_jadwal"
                /> -->
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal
    v-model="showModalUpdateStatus"
    id="modal-lg"
    size="lg"
    title="Tambah Peserta"
    title-class="font-18"
    hide-footer
  >
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <label for="formImport" class="form-label">
            Update Status Jadwal
          </label>
          <v-select
            :options="option_status"
            v-model="selected_status"
            label="label"
            placeholder="-Pilih Status-"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button
            type="button"
            class="btn btn-primary"
            @click="updateStatus(selected_status.status)"
          >
            <i class="fa fa-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
