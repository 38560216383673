<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { encryptData } from "@/helpers/crypt.js";
import { decryptData } from "@/helpers/crypt.js";
import { containsDisallowedCharacters } from "../../../../../utils/check-disallowed-character";
/**
 * Dashboard Component
 */

export default {
  page: {
    title: "Edit Jadwal Asesmen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  props: {
    dataPeserta: {
      type: String,
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      data_peserta: [],
      data_jadwal: [],
      idjadwal: decryptData(this.$route.params.id),
      form_data: {
        idjadwal: decryptData(this.$route.params.id),
        j_metode: "Psikologi",
        m_unit_idm_unit: "",
        j_txt_unit: "",
        m_lokasi_idm_lokasi: "",
        j_txt_lokasi: "", //
        j_metode_jadwal: "",
        m_user_id_ptl: "",
        m_user_nip_ptl: "",
        j_txt_ptl: "",
        j_json_petugas: "",
        j_tanggal_mulai: "", //
        j_tanggal_sampai: "", //
        j_jam_mulai: "", //
        j_jam_sampai: "", //
        j_mulai_penilaian: "",
        j_akhir_penilaian: "",
        j_impact: "",
        j_keterangan: "",
        j_agreement: "",
        m_jadwal_kegiatan_idm_kegiatan: "", //
        j_txt_kegiatan: "",
        m_dirkom_idm_dirkom: "",
        j_txt_dirkom: "",
        j_tipe: "",
        m_id_materi: "",
        m_batre_test_bt_id: "",
        j_txt_batre_test: "",
        j_materi: "",
        user_id: localStorage.getItem("session_user_id"),
        user_name: localStorage.getItem("session_name"),
        j_json_alattes: "",
        form_petugas: [
          {
            jp_txt_petugas: "",
            jp_txt_tugas: "",
            jadwal_idjadwal: null,
            m_user_id_petugas: "",
            m_user_nip_petugas: "",
          },
        ],
        form_unit: "",
        m_id_subkegiatan: "",
        m_nama_kegiatan: "",
      },

      option_unit: [],
      selected_unit: "",

      option_lokasi: [],
      checkedLokasi: false,
      selected_lokasi: "",

      selected_petugas: [],
      selected_tugas: [],

      option_tugas: [
        {
          tugas: "Petugas Kelas",
        },
        { tugas: "Tester" },
      ],

      temp_id_jadwal: "",
      selected_metode: "",

      option_kegiatan: [],
      selected_kegiatan: "",

      option_sub_kegiatan: [],
      selected_sub_kegiatan: "",

      option_jenis_asesmen: [],
      seleceted_jenis_asesmen: "",

      option_batre_test: [],
      seleceted_batre_test: "",

      option_alat_test: [],

      option_materi: [],
      seleceted_materi: "",

      option_checklist: "",
      selected_checklist: "",

      option_ptl: [],
      selected_ptl: [],

      json_fix_peserta: [],

      selected_unit_peserta: [],
      selected_jumlah_peserta: [],

      //Jenis Asesmen
      readOnlyJA: false,
      //Materi
      readOnlyMateri: false,

      lokasi_text: "",
      json_petugas: "",
    };
  },

  mounted() {
    let self = this;
    self.getDataJadwal();
    self.searchUnit(true);
    self.searchLokasi(true);
    self.searchKegiatan(true);
    self.searchSubKegiatan(true);

    self.searchBatreTest(true);
    // self.searchMateri(true);
    self.searchPTL(true);
  },

  created() {
    // this.data_peserta = JSON.parse(window.history.state.data_peserta);
    // this.data_jadwal = JSON.parse(window.history.state.data_jadwal);
    // this.form_data.m_user_id_ptl = this.data_jadwal.m_user_id_ptl;
    // this.form_data.m_user_nip_ptl = this.data_jadwal.m_user_nip_ptl;
    // this.form_data.j_txt_ptl = this.data_jadwal.j_txt_ptl;
    // this.form_data.j_json_petugas = this.data_jadwal.j_json_petugas;
    // this.json_petugas = JSON.parse(this.data_jadwal.j_json_petugas);
    // console.log(this.data_peserta);
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    getDataJadwal() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/show-jadwal-ac",
        params: {
          idjadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          var response_data = response.data.data[0];
          self.selected_unit = {
            m_unit_idm_unit: response_data.m_unit_idm_unit,
            m_unit_slug: response_data.j_txt_unit,
          };
          self.form_data.j_metode_jadwal = response_data.j_metode_jadwal;
          self.selected_ptl = {
            iduser: response_data.m_user_id_ptl,
            u_nip: response_data.m_user_nip_ptl,
            u_nama: response_data.j_txt_ptl,
            label: response_data.j_txt_ptl + " - " + response_data.m_user_nip_ptl,
          };
          self.form_data.form_unit = response_data.jadwal_unit;
          self.selected_unit_peserta = response_data.jadwal_unit;

          self.form_data.j_tanggal_mulai = response_data.j_tanggal_mulai;
          self.form_data.j_tanggal_sampai = response_data.j_tanggal_sampai;
          self.form_data.j_jam_mulai = response_data.j_jam_mulai;
          self.form_data.j_jam_sampai = response_data.j_jam_sampai;
          self.form_data.j_mulai_penilaian = response_data.j_mulai_penilaian;
          self.form_data.j_akhir_penilaian = response_data.j_akhir_penilaian;
          self.form_data.j_impact = response_data.j_impact;
          self.form_data.j_keterangan = response_data.j_keterangan;
          self.form_data.j_agreement = response_data.j_agreement;
          self.form_data.j_txt_lokasi = response_data.j_txt_lokasi;
          self.form_data.m_lokasi_idm_lokasi = response_data.m_lokasi_idm_lokasi;
          self.form_data.m_user_id_ptl = response_data.m_user_id_ptl;
          self.form_data.m_user_nip_ptl = response_data.m_user_nip_ptl;
          self.form_data.j_txt_ptl = response_data.j_txt_ptl;
          self.form_data.m_jadwal_kegiatan_idm_kegiatan = response_data.m_jadwal_kegiatan_idm_kegiatan;
          self.form_data.j_txt_kegiatan = response_data.j_txt_kegiatan;
          self.form_data.j_txt_ptl = response_data.j_txt_ptl;
          self.form_data.j_materi = response_data.j_materi;
          self.form_data.m_unit_idm_unit = response_data.m_unit_idm_unit;
          self.form_data.j_txt_unit = response_data.j_txt_unit;
          self.form_data.m_batre_test_bt_id = response_data.m_batre_test_bt_id;
          self.form_data.j_txt_batre_test = response_data.j_txt_batre_test;
          self.form_data.m_id_subkegiatan = response_data.m_id_subkegiatan;
          self.form_data.m_nama_kegiatan = response_data.m_nama_kegiatan;

          self.selected_kegiatan = {
            id: response_data.m_jadwal_kegiatan_idm_kegiatan,
            kegiatan: response_data.j_txt_kegiatan,
          };

          self.selected_sub_kegiatan = {
            id_subkegiatan: response_data.m_id_subkegiatan,
            nama_subkegiatan: response_data.m_nama_kegiatan,
          };
          self.form_data.m_dirkom_idm_dirkom = response_data.m_dirkom_idm_dirkom;
          self.form_data.j_txt_dirkom = response_data.j_txt_dirkom;

          self.seleceted_jenis_asesmen = {
            id: response_data.m_dirkom_idm_dirkom,
            nama_jenisasesmen: response_data.j_txt_dirkom,
          };
          self.form_data.j_tipe = response_data.j_tipe;
          self.seleceted_batre_test = {
            bt_id: response.m_batre_test_bt_id,
            bt_nama: response_data.j_txt_batre_test,
          };
          self.seleceted_materi = {
            mm_nama: response_data.j_materi,
          };

          if (response_data.jadwal_alat_tes) {
            response_data.jadwal_alat_tes.forEach((item) => {
              this.option_alat_test.push({
                m_alat_tes_id: item.id_alattes,
                m_alat_tes_nama: item.nama_alattes,
              });
            });
          }

          self.form_data.j_bentuk_dinamika = response_data.j_bentuk_dinamika;
          self.form_data.j_bentuk_penilaian = response_data.j_bentuk_penilaian;

          self.form_data.form_petugas = response_data.jadwal_petugas;
          for (let index = 0; index < self.form_data.form_petugas.length; index++) {
            self.selected_petugas[index] = {
              iduser: self.form_data.form_petugas[index].m_user_id_petugas,
              u_nip: self.form_data.form_petugas[index].m_user_nip_petugas,
              u_nama: self.form_data.form_petugas[index].jp_txt_petugas,
              label: self.form_data.form_petugas[index].jp_txt_petugas + " - " + self.form_data.form_petugas[index].m_user_nip_petugas,
            };
            self.selected_tugas[index] = {
              tugas: self.form_data.form_petugas[index].jp_txt_tugas,
            };
          }
          for (let index = 0; index < self.form_data.form_petugas.length; index++) {
            self.form_data.form_petugas[index].jadwal_idjadwal = self.data_jadwal.idjadwal;
          }

          self.selected_lokasi = {
            ml_id: response_data.m_lokasi_idm_lokasi,
            ml_nama: response_data.j_txt_lokasi,
          };
          if (response_data.m_lokasi_idm_lokasi == null) {
            self.checkedLokasi = true;
            self.lokasi_text = response_data.j_txt_lokasi;
          }

          self.option_checklist = response_data.jadwal_checklist_tipe.map((item) => {
            return {
              nama_checklist: item.jct_nama,
            };
          });

          self.searchJenisAsesmen(true);
        }
      });
    },
    addForm() {
      this.form_data.form_petugas.push({
        jp_txt_petugas: "",
        jp_txt_tugas: "",
        jadwal_idjadwal: null,
        m_user_id_petugas: "",
        m_user_nip_petugas: "",
      });
    },
    clearForm(index) {
      this.form_data.form_petugas[index].jp_txt_petugas = "";
      this.form_data.form_petugas[index].jp_txt_tugas = "";
      this.form_data.form_petugas[index].jadwal_idjadwal = null;
      this.form_data.form_petugas[index].m_user_id_petugas = "";
      this.form_data.form_petugas[index].m_user_nip_petugas = "";
      this.selected_petugas.splice(index, 1);
      this.selected_tugas.splice(index, 1);
      this.form_data.form_petugas.splice(index, 1);
    },
    onChangePetugas(index) {
      this.form_data.form_petugas[index].m_user_id_petugas = this.selected_petugas[index].iduser;
      this.form_data.form_petugas[index].m_user_nip_petugas = this.selected_petugas[index].u_nip;
      this.form_data.form_petugas[index].jp_txt_petugas = this.selected_petugas[index].u_nama;
    },
    onChangeTugas(index) {
      this.form_data.form_petugas[index].jp_txt_tugas = this.selected_tugas[index].tugas;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    //Dropdown Unit
    onSearchUnit(search, loading) {
      if (search.length) {
        //loading(true);
        this.searchUnit(loading, search, this);
      }
    },
    searchUnit: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/unit",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_unit = response.data.data.referensi;
          //loading(false);
        }
      });
    },
    onChangeUnit() {
      let self = this;
      self.form_data.m_unit_idm_unit = self.selected_unit.m_unit_idm_unit;
      self.form_data.j_txt_unit = self.selected_unit.ju_txt_unit;
    },

    //Pilih Lokasi
    onSearchLokasi(search, loading) {
      if (search.length) {
        //loading(true);
        this.searchLokasi(loading, search, this);
      }
    },
    searchLokasi: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-lokasi",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_lokasi = response.data.data.referensi;
          //loading(false);
        }
      });
    },
    checkLokasi() {
      let self = this;
      this.$nextTick(() => {
        console.log(self.checkedLokasi);
      });
    },
    onChangeLokasi() {
      let self = this;
      self.form_data.m_lokasi_idm_lokasi = self.selected_lokasi.ml_id;
      self.form_data.j_txt_lokasi = self.selected_lokasi.ml_nama;
    },
    // submitForm() {
    //   console.log(this.form_data);
    // },

    //Kegiatan
    onSearchKegiatan(search, loading) {
      if (search.length) {
        //loading(true);
        this.searchKegiatan(loading, search, this);
      }
    },
    searchKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_kegiatan = response.data.data.referensi;
          //loading(false);
        }
      });
    },
    onChangeKegiatan() {
      let self = this;
      if (self.selected_kegiatan?.id) {
        self.form_data.m_jadwal_kegiatan_idm_kegiatan = self.selected_kegiatan.id;
        self.form_data.j_txt_kegiatan = self.selected_kegiatan.kegiatan;
        self.searchJenisAsesmen(true);
      }
      self.option_checklist = null;
      self.form_data.m_dirkom_idm_dirkom = null;
      self.seleceted_jenis_asesmen = null;
      self.readOnlyJA = false;
    },

    // sub Kegiatan
    onSearchSubKegiatan(search, loading) {
      if (search.length) {
        //loading(true);
        this.searchSubKegiatan(loading, search, this);
      }
    },
    searchSubKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/sub-kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_sub_kegiatan = response.data.data.referensi;
          //loading(false);
        }
      });
    },
    onChangeSubKegiatan() {
      let self = this;
      if (self.selected_sub_kegiatan?.id_subkegiatan) {
        self.form_data.m_id_subkegiatan = self.selected_sub_kegiatan.id_subkegiatan;
        self.form_data.m_nama_kegiatan = self.selected_sub_kegiatan.nama_subkegiatan;
      }
    },
    //Jenis Asesmen
    onSearchJenisAsesmen(search, loading) {
      if (search.length) {
        //loading(true);
        this.searchJenisAsesmen(loading, search, this);
      }
    },
    searchJenisAsesmen: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        params: {
          keyword: search,
          id_kegiatan: self.selected_kegiatan.id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_jenis_asesmen = response.data.data.referensi;
          // loading(false);
        }
      });
    },
    onChangeJenisAsesmen() {
      let self = this;
      self.form_data.m_dirkom_idm_dirkom = self.seleceted_jenis_asesmen.id;
      self.form_data.j_txt_dirkom = self.seleceted_jenis_asesmen.nama_jenisasesmen;
      self.searchCheklist();
      // self.onSearchMateri();
      // self.form_data.j_materi = null;
      // self.seleceted_materi = null;
      self.readOnlyMateri = false;
    },

    //Batre Test
    onSearchBatreTest(search, loading) {
      if (search.length) {
        //loading(true);
        this.searchBatreTest(loading, search, this);
      }
    },
    searchBatreTest: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-test",
        params: {
          keyword: search,
          // tipe: "AC",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_batre_test = response.data.data.referensi;

          //loading(false);
        }
      });
    },
    onChangeBatreTest() {
      let self = this;
      self.form_data.m_batre_test_bt_id = self.seleceted_batre_test.bt_id;
      self.form_data.j_txt_batre_test = self.seleceted_batre_test.bt_nama;
      self.getAlatTes();
    },

    //alattes
    getAlatTes() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-alat-test",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          id_batretest: self.seleceted_batre_test.bt_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_alat_test = response_data.data.referensi;
            self.form_data.j_json_alattes = self.option_alat_test;
            self.form_data.alat_tes = self.option_alat_test;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //materi
    onSearchMateri(search, loading) {
      if (search && search.length) {
        //loading(true);
      }
      this.searchMateri(loading, search, this);
    },
    searchMateri: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_materi = response.data.data.referensi;
          //loading(false);
        }
      });
    },
    onChangeMateri() {
      let self = this;
      self.form_data.j_materi = self.seleceted_materi.mm_nama;
      self.form_data.m_id_materi = self.seleceted_materi.mm_id;
    },

    //checklist
    onSearchCheklist(search, loading) {
      if (search.length) {
        //loading(true);
        this.searchCheklist(loading, search, this);
      }
    },
    searchCheklist: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/checklist-asesmen",
        params: {
          keyword: search,
          id_jenis_asesmen: self.form_data.m_dirkom_idm_dirkom,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_checklist = response.data.data.referensi;
          self.form_data.j_json_checklist = self.option_checklist;
          //loading(false);
        }
      });
    },

    submitForm() {
      let self = this;
      self.form_data.j_json_petugas = JSON.stringify(self.form_data.form_petugas);
      self.form_data.j_json_alattes = JSON.stringify(self.option_alat_test);

      if (self.selected_sub_kegiatan) {
        self.form_data.m_id_subkegiatan = self.selected_sub_kegiatan.id_subkegiatan;
        self.form_data.m_nama_kegiatan = self.selected_sub_kegiatan.nama_subkegiatan;
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-psikologi/update-jadwal",
        data: self.form_data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Mengubah Data Jadwal",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              self.$router.push({ name: "detail-asesmen-psikologi", params: { id: this.encryptedUrl(self.idjadwal.toString()) } });
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
              }
            });
          }
        })
        .catch((error) => {
          Swal.close();
          console.log(error);
        });
    },
    addFormUnit() {
      this.form_data.form_unit.push({
        m_unit_slug: "",
        m_unit_idm_unit: "",
        jadwal_idjadwal: null,
        ju_txt_unit: "",
        jumlah_diundang: "",
      });
    },
    clearFormUnit(index) {
      this.form_data.form_unit[index].m_unit_slug = "";
      this.form_data.form_unit[index].m_unit_idm_unit = "";
      this.form_data.form_unit[index].jadwal_idjadwal = null;
      this.form_data.form_unit[index].ju_txt_unit = "";
      this.form_data.form_unit[index].jumlah_diundang = "";
      this.selected_unit_peserta.splice(index, 1);
      this.selected_jumlah_peserta.splice(index, 1);
      this.form_data.form_unit.splice(index, 1);
    },

    onSearchPTL(search, loading) {
      if (search.length) {
        //loading(true);
        this.searchPTL(loading, search, this);
      }
    },
    searchPTL: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_ptl = response.data.data.referensi;
          //loading(false);
        }
      });
    },

    validasi() {
      if (this.form_data.j_txt_unit && this.form_data.m_unit_idm_unit == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unit Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_lokasi == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Lokasi Jadwal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_metode_jadwal == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Metode Jadwal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_ptl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ptl Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_txt_tgl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_tanggal_mulai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Mulai Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_tanggal_sampai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Sampai Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_jam_mulai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Mulai Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_jam_sampai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Sampai Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_mulai_penilaian == "" && this.form_data.j_akhir_penilaian == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "jam Penilaian Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_impact == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Adverse Impact Tidak Boleh Kosong",
        });
        return false;
      }
      // j_keterangan
      if (this.form_data.j_keterangan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Keterangan Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_agreement == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Agreement Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_kegiatan
      if (this.form_data.j_txt_kegiatan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Kegiatan Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_dirkom

      if (this.form_data.j_tipe == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "tipe psikologi Tidak Boleh Kosong",
        });
        return false;
      }
      if (containsDisallowedCharacters(this.form_data.j_keterangan)) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return false; // Exit the function if validation fails
      }
      if (containsDisallowedCharacters(this.form_data.j_agreement)) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return false; // Exit the function if validation fails
      }
      if (this.j_json_alattes == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Alat Tes Tidak Boleh Kosong",
        });
        return false;
      } else {
        Swal.fire({
          title: "Apakah Anda Yakin?",
          text: "Data Akan Disimpan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Simpan!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm();
          }
        });
      }
    },

    onChangeUnitPeserta(index) {
      let self = this;
      self.form_data.form_unit[index].ju_txt_unit = self.selected_unit_peserta[index].ju_txt_unit;
      self.form_data.form_unit[index].m_unit_idm_unit = self.selected_unit_peserta[index].m_unit_idm_unit;
      self.form_data.form_unit[index].m_unit_slug = self.selected_unit_peserta[index].m_unit_slug;
      self.form_data.form_unit[index].ju_peserta_diundang = self.selected_unit_peserta[index].ju_peserta_diundang;
    },

    onChangePTL() {
      let self = this;
      self.form_data.m_user_id_ptl = self.selected_ptl.iduser;
      self.form_data.m_user_nip_ptl = self.selected_ptl.u_nip;
      self.form_data.j_txt_ptl = self.selected_ptl.u_nama;
    },

    onChangeTglSampai(date) {
      const currentDate = new Date(date);
      currentDate.setDate(currentDate.getDate() + 4);
      this.form_data.j_akhir_penilaian = currentDate.toISOString().slice(0, 10);

      const currentDate2 = new Date(date);
      currentDate2.setDate(currentDate2.getDate() + 1);
      this.form_data.j_mulai_penilaian = currentDate2.toISOString().slice(0, 10);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h4>Edit Jadwal Asesmen Psikologi</h4>
            </div>
            <div class="row mt-4">
              <div class="col-5">
                <!-- Form Metode -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Metode" label-for="form-metode">
                      <b-form-input id="form-metode" class="bg-secondary text-white" type="text" readonly v-model="form_data.j_metode"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Unit Pelaksana -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Unit Pelaksana" label-for="form-unit-pelaksana">
                      <v-select :options="option_unit" label="m_unit_slug" v-model="selected_unit" placeholder="-Pilih Unit-" @search="onSearchUnit" @update:modelValue="onChangeUnit()"></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Lokasi -->
                <div class="row">
                  <div class="col-md-2">
                    <b-form-group class="mb-3" label="Lokasi" label-for="lokasi">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="checkedLokasi" value="true" id="autoSizingCheck" @change="checkLokasi($event)" />
                        <label class="form-check-label" for="autoSizingCheck"> Isi Text </label>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-10">
                    <b-form-group class="mb-3" v-if="!checkedLokasi" label="Pilih Lokasi" label-for="lokasi">
                      <v-select
                        :options="option_lokasi"
                        label="ml_nama"
                        v-model="selected_lokasi"
                        placeholder="-Pilih Lokasi-"
                        @search="onSearchLokasi"
                        @update:modelValue="onChangeLokasi()"
                      ></v-select>
                    </b-form-group>
                    <b-form-group class="mb-3" v-else label="Lokasi" label-for="lokasiText">
                      <b-form-input id="lokasiText" placeholder="Masukkan Nama Lokasi" type="text" v-model="form_data.j_txt_lokasi"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Lokasi -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Metode Jadwal" label-for="form-metode">
                      <v-select :options="['Online', 'Offline']" v-model="form_data.j_metode_jadwal" placeholder="-Pilih Metode Jadwal-"></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form PTL -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="PTL" label-for="ptl">
                      <v-select :options="option_ptl" label="label" v-model="selected_ptl" placeholder="-Pilih PTL-" @search="onSearchPTL" @update:modelValue="onChangePTL()"></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Metode -->
                <div class="row" v-for="(form, k) in form_data.form_unit" :key="k">
                  <div class="col-md-5">
                    <b-form-group class="mb-3" label="Unit Peserta" label-for="ptl">
                      <v-select
                        :options="option_unit"
                        label="m_unit_slug"
                        v-model="selected_unit_peserta[k]"
                        placeholder="-Pilih Unit-"
                        @search="onSearchUnit"
                        @update:modelValue="onChangeUnitPeserta(k)"
                      ></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-5">
                    <b-form-group class="mb-3" label="Peserta Diundang" label-for="pesertaDiundang">
                      <b-form-input id="pesertaDiundang" placeholder="Masukkan jumlah peserta diundang" type="text" v-model="form_data.form_unit[k].ju_peserta_diundang"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-2 d-flex align-items-center" v-if="k == 0">
                    <button class="btn btn-success mt-2" type="button" @click="addFormUnit()">
                      <span><i class="fa fa-plus"></i> </span>
                    </button>
                  </div>
                  <div class="col-md-2 d-flex align-items-center" v-if="k > 0">
                    <button class="btn btn-danger mt-2" @click="clearFormUnit(k)" type="button">
                      <span><i class="fa fa-minus"></i> </span>
                    </button>
                  </div>
                </div>
                <!-- <div class="row" v-for="(form, k) in form_unit" :key="k">
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Unit Peserta"
                      label-for="ptl"
                    >
                      <b-form-input
                        id="pesertaDiundang"
                        placeholder="Masukkan jumlah peserta diundang"
                        type="text"
                        class="bg-secondary text-white"
                        v-model="form_unit[k].ju_txt_unit"
                        readonly
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Peserta Diundang"
                      label-for="pesertaDiundang"
                    >
                      <b-form-input
                        id="pesertaDiundang"
                        class="bg-secondary text-white"
                        placeholder="Masukkan jumlah peserta diundang"
                        type="text"
                        v-model="form_unit[k].ju_peserta_diundang"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div> -->
                <!-- Form Tanggal Mulai dan Selesai -->
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group class="mb-3" label="Tanggal Mulai" label-for="tgl-mulai">
                      <b-form-input id="tgl-mulai" type="date" v-model="form_data.j_tanggal_mulai"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group class="mb-3" label="Tanggal Selesai" label-for="tgl-selesai">
                      <b-form-input
                        id="tgl-selesai"
                        placeholder="Tanggal Selesai"
                        type="date"
                        v-model="form_data.j_tanggal_sampai"
                        @update:modelValue="onChangeTglSampai(form_data.j_tanggal_sampai)"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Jam -->
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group class="mb-3" label="Jam Mulai" label-for="form-jam-mulai">
                      <b-form-input id="form-jam-mulai" type="time" v-model="form_data.j_jam_mulai"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group class="mb-3" label="Jam Selesai" label-for="form-jam-selesai">
                      <b-form-input id="form-jam-selesai" type="time" v-model="form_data.j_jam_sampai"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- mulai dan akhir penilaian -->
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group class="mb-3" label="Mulai Penilaian" label-for="form-jam-mulai">
                      <b-form-input id="form-jam-mulai" type="date" v-model="form_data.j_mulai_penilaian"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group class="mb-3" label="Jam Selesai" label-for="form-jam-selesai">
                      <b-form-input id="form-jam-selesai" type="date" v-model="form_data.j_akhir_penilaian"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Adverse Impact -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Adverse Impact" label-for="form-adverse">
                      <v-select :options="['Yes', 'No']" v-model="form_data.j_impact" placeholder="-Pilih Adverse impact-"></v-select>
                    </b-form-group>
                  </div>
                </div>

                <!-- Form Keterangan -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Keterangan" label-for="keterangan">
                      <b-form-textarea id="keterangan" type="textarea" v-model="form_data.j_keterangan"></b-form-textarea>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Agreement -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Agreement" label-for="agreement">
                      <b-form-textarea id="agreement" type="textarea" v-model="form_data.j_agreement"></b-form-textarea>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-1"></div>
              <div class="col-5">
                <!-- Form Kegiatan -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Kegiatan" label-for="form-unit-pelaksana">
                      <v-select
                        :options="option_kegiatan"
                        label="kegiatan"
                        v-model="selected_kegiatan"
                        placeholder="-Pilih Kegiatan-"
                        @search="onSearchKegiatan"
                        @update:modelValue="onChangeKegiatan()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>

                <!-- Form Sub Kegiatan -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Sub Kegiatan">
                      <v-select
                        :options="option_sub_kegiatan"
                        label="nama_subkegiatan"
                        v-model="selected_sub_kegiatan"
                        placeholder="-Pilih Sub Kegiatan-"
                        @search="onSearchSubKegiatan"
                        @update:modelValue="onChangeSubKegiatan()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Jenis Asesmen -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Jenis Asesmen" label-for="form-jenis-asesmen">
                      <v-select
                        :options="option_jenis_asesmen"
                        label="nama_jenisasesmen"
                        v-model="seleceted_jenis_asesmen"
                        placeholder="-Pilih Jenis Asesmen-"
                        @search="onSearchJenisAsesmen"
                        readonly
                        @update:modelValue="onChangeJenisAsesmen()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Tipe Psikologi -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Tipe Psikologi " label-for="form-tipe">
                      <v-select :options="['Kompetensi & Potensi', 'Kompetensi', 'Potensi']" v-model="form_data.j_tipe" placeholder="-Pilih Tipe Psikologi-"></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Batre Test -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Batre Test" label-for="form-batre-test">
                      <v-select
                        :options="option_batre_test"
                        label="bt_nama"
                        v-model="seleceted_batre_test"
                        placeholder="-Pilih Batre Test-"
                        @search="onSearchBatreTest"
                        @update:modelValue="onChangeBatreTest()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Alat Tes -->
                <div class="row row-cols-2" v-if="seleceted_batre_test != ''">
                  <b-form-group class="mb-3" label="Alat Tes" label-for="form-metode" v-for="alattes in option_alat_test" :key="alattes.m_alat_tes_id">
                    <b-form-input id="form-metode" class="bg-secondary text-white" type="text" readonly v-model="alattes.m_alat_tes_nama"></b-form-input>
                  </b-form-group>
                </div>

                <div class="row row-cols-2" v-if="seleceted_jenis_asesmen != ''">
                  <b-form-group class="mb-3" label="Checklist" label-for="form-metode" v-for="checklist in option_checklist" :key="checklist.id_ca">
                    <b-form-input id="form-metode" class="bg-secondary text-white" type="text" readonly v-model="checklist.nama_checklist"></b-form-input>
                  </b-form-group>
                </div>
                <div class="row" v-for="(form, k) in form_data.form_petugas" :key="k">
                  <div class="col-md-5">
                    <b-form-group class="mb-3" label="Petugas" label-for="ptl">
                      <v-select
                        :options="option_ptl"
                        label="label"
                        v-model="selected_petugas[k]"
                        placeholder="-Pilih Petugas-"
                        @search="onSearchPTL"
                        @update:modelValue="onChangePetugas(k)"
                      ></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-5">
                    <b-form-group class="mb-3" label="Tugas" label-for="tugas">
                      <v-select :options="option_tugas" v-model="selected_tugas[k]" label="tugas" placeholder="-Pilih Tugas-" @update:modelValue="onChangeTugas(k)"></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-2 d-flex align-items-center" v-if="k == 0">
                    <button class="btn btn-success mt-2" type="button" @click="addForm()">
                      <span><i class="fa fa-plus"></i> </span>
                    </button>
                  </div>
                  <div class="col-md-2 d-flex align-items-center" v-if="k > 0">
                    <button class="btn btn-danger mt-2" @click="clearForm(k)" type="button">
                      <span><i class="fa fa-minus"></i> </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-12">
              <button class="btn btn-success mt-2 m-4" type="button" @click="validasi()">
                <span><i class="fa fa-plus"></i> Submit Data </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
