<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: ["data_jadwal"],
  data() {
    return {
      table_data: [],
      detail_checklist: {
        idjadwal_checklist: [],
        text: [],
      },
    };
  },
  mounted() {
    let self = this;
    self.getData();
  },
  methods: {
    getData() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/list-roleplayer",
        params: {
          idjadwal: this.data_jadwal.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<template>
  <h4 class="mt-2">Role Player</h4>
  <div class="row">
    <div class="table-responsive">
      <table class="table table-bordered mb-0 mt-2">
        <thead class="bg-dark text-white" style="padding: 10px">
          <th style="padding: 5px">No</th>
          <th style="padding: 5px">NIP</th>
          <th style="padding: 5px">Nama</th>
        </thead>
        <tbody>
          <template v-for="(row_data, index) in table_data" :key="row_data">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>
                {{ row_data.m_user_nip_petugas }}
              </td>
              <td>
                {{ row_data.jp_txt_petugas }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
