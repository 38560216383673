<script>
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: ["idjadwal", "idjadwal_psikologi"],
  components: {
    Pagination,
  },
  data() {
    let sortOrders = [];

    return {
      table_data: [],
      loadingTable: true,
      selectedId: [],
      selectedIdAsesi: [],
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        idjadwal: this.$props.idjadwal,
        idjadwal_psikologi: this.$props.idjadwal_psikologi,
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/peserta-single-asesmen") {
      let self = this;
      self.loadingTable = true;
      self.tableData.id_jadwal = self.idjadwal;
      //jika login htd area
      if (self.sessionRoleId == 9) {
        self.tableData.username = self.sessionUsername;
      }

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.jumlah_peserta = response_data_fix.count;
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            self.loadingTable = false;
            self.table_data.forEach((element) => {
              self.filter_peserta.push(element.ja_idasesi);
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    checkPeserta() {
      let self = this;
      if (this.checkedAll) {
        self.table_data.forEach((option) => {
          console.log(option);
          self.selectedId.push(option.id_jadwalasesi);
          self.selectedIdAsesi.push(option.ja_idasesi);
        });
      } else {
        self.selectedId = [];
        self.selectedIdAsesi = [];
      }
    },
    submitPeserta() {
      let self = this;
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/store-peserta-single-asesmen",
        data: {
          peserta: self.selectedIdAsesi,
          id_jadwal: this.$props.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Menambahkan Peserta",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                window.location.reload();
              }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
    },
  },
};
</script>
<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-2">
        <div class="row mb-3"></div>
        <hr />
        <div class="row">
          <div class="col-md-4">
            <table>
              <tr>
                <td>Show</td>
                <td>
                  <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                    <option value="10" selected>10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
          </div>
          <div class="col-md-5">&nbsp;</div>
          <div class="col-md-3">
            <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="searchDatatable" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <b-form @submit="konfirmasiKehadiranPeserta" @reset="onReset">
        <table class="table mb-0 table-bordered table-condensed table-hover">
          <thead class="bg-dark text-center text-white">
            <tr>
              <th>
                <input type="checkbox" v-model="checkedAll" @change="checkPeserta($event)" />
              </th>
              <th>No</th>
              <th>Nip</th>
              <th>Nama</th>
              <th>Unit</th>
              <th>Jabatan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loadingTable">
              <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
            </tr>
            <tr v-else-if="table_data == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
              <td>
                <div>
                  <input type="checkbox" :id="row_data.id_jadwalasesi" v-model="selectedIdAsesi" :value="row_data.ja_idasesi" />
                </div>
              </td>
              <td class="text-center">
                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
              </td>
              <td>{{ row_data.ja_nip }}</td>
              <td>{{ row_data.ja_nama }}</td>
              <td>{{ row_data.ja_unit }}</td>
              <td>{{ row_data.ja_jabatan }}</td>
            </tr>
          </tbody>
        </table>
      </b-form>
    </div>
    <div class="row">
      <pagination :pagination="pagination" @to-page="toPage"></pagination>
    </div>
    <div class="row">
      <div class="d-grid gap-2 col-6 mx-auto">
        <button class="btn btn-primary" type="button" @click="submitPeserta()">Simpan</button>
      </div>
    </div>
  </div>
</template>
