<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { encryptData } from '../../../../helpers/crypt';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Draft MK",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "100px", label: "ID Jadwal", name: "idjadwal" },
      { width: "auto", label: "Pelaksanaan", name: "j_tanggal_mulai" },
      { width: "auto", label: "Jenis", name: "j_txt_dirkom" },
      { width: "auto", label: "Tujuan", name: "j_txt_kegiatan" },
      { width: "auto", label: "Lokasi", name: "j_txt_lokasi" },
      { width: "auto", label: "Penilaian", name: "j_txt_penilaian" },
      { width: "auto", label: "PTL", name: "j_txt_ptl" },
      { width: "auto", label: "Asesi", name: "asesi_count" },
      { width: "150px", label: "Status", name: "j_status" },
      { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Draft MK",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Draft MK",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      sessionUserId: localStorage.session_user_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      option_jenis_asesmen: [],

      selected_jenis_asesmen: [],
      // parsing data ke table data
      tableData: {
        draw: 0,
        per_page: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_jenis_asesmen: "",
        status_penjadwalan: localStorage.status,
        // filter_status: "ENABLE", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.cekAksesMenu();
    self.getDataTable();
  },
  methods: {
    encryptedUrl(params) {
      let encryptText = encryptData(params)
      return encryptText;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    cekAksesMenu() {
      let self = this;
      var config_axios = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
        params: {
          role_id: self.sessionRoleId,
          link_name: self.namePathUrl,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_axios)
        .then((response) => {
          let response_data_fix = response.data.data.status_access;
          if (response.data.meta.code == 200) {
            if (response_data_fix == false) {
              Swal.fire({
                icon: "warning",
                title: "Oppss",
                text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$router.push({ name: "dashboard" });
                }
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/mk") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;
      self.tableData.filter_jenis_asesmen =
        self.selected_jenis_asesmen.nama_jenisasesmen;
      self.tableData.role_id = self.sessionRoleId;
      self.tableData.user_id = self.sessionUserId;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            // if (self.tableData.draw == response_data_fix.draw) {
              self.table_data = response_data_fix.list_data.data;
              self.configPagination(response_data_fix.list_data);
              self.currentTablePage = response_data_fix.list_data.current_page;
            // }
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },

    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.sort_field = key;
      self.tableData.sort_order = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog",
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card-title filterdata">
                  <div class="row">
                    <div class="form-group col-md-3">
                      <label for="tanggalMulai">Tanggal Mulai</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="tableData.filter_tanggal_mulai"
                        @change="getDataTable()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="tanggalSampai">Tanggal Sampai</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="tableData.filter_tanggal_sampai"
                        @change="getDataTable()"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-2">
                <hr />
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.per_page"
                            @change="getDataTable()"
                          >
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="getDataTable()"
                    />
                  </div>
                </div>
              </div>
              <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="6">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(row_data, key_data) in table_data"
                    :key="key_data"
                  >
                    <td>{{ key_data + 1 }}</td>
                    <td>#{{ row_data.idjadwal }}</td>
                    <td>{{ row_data.j_tanggal_mulai }} <b>s/d</b> {{ row_data.j_tanggal_sampai }}</td>
                    <td>{{ row_data.j_txt_dirkom }}</td>
                    <td>{{ row_data.j_txt_kegiatan }}</td>
                    <td>{{ row_data.j_txt_lokasi }}</td>
                    <td>{{ row_data.j_akhir_penilaian }}</td>
                    <td>{{ row_data.j_txt_ptl }}</td>
                    <td>{{ row_data.asesi_count }}</td>
                    <td class="text-center">
                      <span class="badge bg-success" v-if="row_data.j_status == 'proses_ptl'">Proses Jadwal Ke PTL</span>
                      <span class="badge bg-primary" v-if="row_data.j_status != 'proses_ptl'">{{ row_data.j_status }}</span>
                    </td>
                    <td class="text-center">
                      <div>
                        <div class="btn-group">
                          <router-link
                            :to="{
                              name: 'detail-mk',
                              params: {
                                id: encryptedUrl(row_data.idjadwal.toString()),
                              },
                            }"
                            class="btn btn-primary btn-sm"
                          >
                            <i class="fa fa-edit"></i> Atur Mk
                          </router-link>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
