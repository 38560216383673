<script>
import MetisMenu from "metismenujs/dist/metismenujs";
// import { menuItems } from "./menu";

/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuItems: JSON.parse(localStorage.session_menu_access),
      menuData: null,
      sessionRoleId: localStorage.session_role_active_id,
    };
  },
  mounted: function () {
    if (document.getElementById("side-menu")) new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <div v-if="sessionRoleId == 2">
        <template v-for="item in menuItems">
          <li class="menu-title" v-if="item.isTitle" :key="item.id">
            {{ $t(item.label) }}
          </li>
          <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
            <a v-if="hasItems(item)" href="javascript:void(0);" class="is-parent" :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }">
              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
              <span>{{ $t(item.label) }}</span>
              <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{ $t(item.badge.text) }}</span>
            </a>

            <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
              <span>{{ $t(item.label) }}</span>
              <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{ $t(item.badge.text) }}</span>
            </router-link>

            <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li v-for="(subitem, index) of item.subItems" :key="index">
                <router-link :to="subitem.link" v-if="!hasItems(subitem)" class="side-nav-link-ref">{{ $t(subitem.label) }}</router-link>
                <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">{{ $t(subitem.label) }}</a>
                <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                  <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                    <router-link :to="subSubitem.link" class="side-nav-link-ref">{{ $t(subSubitem.label) }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </template>
      </div>
      <div v-if="sessionRoleId != 9">
        <li>
          <router-link :to="{ name: 'dashboard' }" class="side-nav-link-ref"> <i class="fas fa-user"></i> Pembinaan Grade </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'all-asesmen-center' }" class="side-nav-link-ref"> <i class="fas fa-calendar-alt"></i> Asesmen Center </router-link>
        </li>
        <li></li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/PenjadwalanKonversi/konversi?tipe=Konversi" class="side-nav-link-ref"><i class="fas fa-calendar-alt"></i><span>Konversi Data</span></a>
        </li>
        <li>
          <router-link :to="{ name: 'all-asesmen-psikologi' }" class="side-nav-link-ref"> <i class="fas fa-calendar-alt"></i> Asesmen Psikologi </router-link>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/Penjadwalan_hygine_factor" class="side-nav-link-ref"> <i class="fas fa-calendar-alt"></i> Hygiene Factor </a>
        </li>
        <li>
          <router-link :to="{ name: 'all-rekrutmen' }" class="side-nav-link-ref"> <i class="fas fa-calendar-alt"></i> Rekrutmen </router-link>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/penjadwalan/talent_report?tipe=Talent%20Report" class="side-nav-link-ref"
            ><i class="fas fa-calendar-alt"></i><span>Talent Report</span></a
          >
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/penjadwalan/feedback_individu" class="side-nav-link-ref"><i class="fas fa-calendar-alt"></i><span>Feedback Individu</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/penjadwalan_asesor/data_asesor/pengelolaan" class="side-nav-link-ref"
            ><i class="fas fa-calendar-alt"></i><span>Penjadwalan Asesor</span></a
          >
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/Request_pelaksanaan_asesmen" class="side-nav-link-ref"><i class="fa fa-users"></i><span>Request Pelaksanaan Asesmen</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/penjadwalan_wo" class="side-nav-link-ref"><i class="fas fa-calendar-alt"></i><span>WO</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/request_assessment/ika" class="side-nav-link-ref"><i class="fas fa-calendar-alt"></i><span>Request Asesmen</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/request_assessment/daftar_assessment" class="side-nav-link-ref"><i class="fas fa-calendar-alt"></i><span>Daftar Asesmen</span></a>
        </li>

        <li class="menu-title">Tools</li>

        <li>
          <a href="https://upac.pln-pusdiklat.co.id/monitoring" class="side-nav-link-ref"><i class="fas fa-desktop"></i><span>Monitoring Asesi</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/verifikasi?reset=yes" class="side-nav-link-ref"><i class="fa fa-check"></i><span>Verifikasi Asesi</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/verifikasi/imporDataPeserta" class="side-nav-link-ref"><i class="fa fa-database"></i><span>Impor Data Peserta</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/intake/activeAssessment" class="side-nav-link-ref"><i class="far fa-folder-open"></i><span>Asesmen Per PTL</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/penjadwalan/history_asesi" class="side-nav-link-ref"><i class="far fa-folder-open"></i><span>Histori Asesi</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/penjadwalan/history_asesor" class="side-nav-link-ref"><i class="far fa-folder-open"></i><span>Histori Asesor</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/tipe_kategori" class="side-nav-link-ref"><i class="fas fa-tasks"></i><span>Checklist</span></a>
        </li>
        <li>
          <router-link :to="{ name: 'rekap-qc' }" class="side-nav-link-ref"> <i class="fas fa-user"></i> Rekap QC </router-link>
        </li>
      </div>
      <div v-if="sessionRoleId == 9">
        <li>
          <router-link :to="{ name: 'dashboard' }" class="side-nav-link-ref"> <i class="fas fa-user"></i> Pembinaan Grade </router-link>
        </li>

        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/request_assessment/ika" class="side-nav-link-ref"><i class="fas fa-calendar-alt"></i><span>Request Asesmen</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/intake/activeAssessment" class="side-nav-link-ref"><i class="far fa-folder-open"></i><span>Asesmen Per PTL</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/penjadwalan/history_asesi" class="side-nav-link-ref"><i class="far fa-folder-open"></i><span>Histori Asesi</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/pelayanan/penjadwalan/history_asesor" class="side-nav-link-ref"><i class="far fa-folder-open"></i><span>Histori Asesor</span></a>
        </li>
        <li>
          <a href="https://upac.pln-pusdiklat.co.id/tipe_kategori" class="side-nav-link-ref"><i class="fas fa-tasks"></i><span>Checklist</span></a>
        </li>
      </div>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
