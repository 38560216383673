<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { encryptData } from "@/helpers/crypt.js";
import { containsDisallowedCharacters } from "../../../../utils/check-disallowed-character";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Penjadwalan Konversi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      form_data: {
        form_petugas: [
          {
            jp_txt_petugas: "",
            jp_txt_tugas: "",
            jadwal_idjadwal: null,
            m_user_id_petugas: "",
            m_user_nip_petugas: "",
          },
        ],
        form_asesmen_grade: [
          {
            id_jenis_asesmen: "",
            nama_jenis_asesmen: "",
          },
        ],
        form_unit: [
          {
            m_unit_slug: "",
            m_unit_idm_unit: "",
            jadwal_idjadwal: null,
            ju_txt_unit: "",
            jumlah_diundang: "",
          },
        ],

        j_tanggal_mulai: "",
        j_tanggal_sampai: "",
        j_jam_mulai: "",
        j_jam_sampai: "",
        j_keterangan: "",
        j_tipe: "",
        j_metode: "Pembinaan Grade",
      },
      option_jenis_asesmen: [],
      selected_jenis_asesmen: [],

      option_lokasi: [],
      selected_lokasi: [],

      option_ptl: [],
      selected_ptl: [],

      option_unit: [],
      selected_unit: [],

      selected_petugas: [],
      selected_tugas: [],

      selected_jumlah_peserta: [],

      option_materi: [],
      selected_materi: [],

      option_batrei_test: [],
      selected_batrei_test: [],

      // option_alat_test: [],
      // selected_alat_test: [],

      option_kegiatan: [],
      selected_kegiatan: [],
      option_sub_kegiatan: [],
      selected_sub_kegiatan: [],
      option_survey_atasan: ['Ya', 'Tidak'],
      selected_survey_atasan: [],

      option_alat_test: [],
      selected_alat_test: [],

      lokasi_text: "",
      checkedLokasi: "",
      option_tugas: [
        {
          tugas: "Petugas Kelas",
        },
        { tugas: "Tester" },
      ],
    };
  },

  mounted() {
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
    });
    // this.getMateri();
    // this.getBatreTest();
    this.getLokasi();
    this.getKegiatan();
    this.getSubKegiatan();
    this.getPTL();
    this.getUnit();
    this.getAlatTes();
    this.getJenisAsesmen();
  },
  methods: {
    validasi() {
      if (this.form_data.form_petugas.length < 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Petugas Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_tanggal_mulai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Mulai Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_tanggal_sampai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Sampai Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_jam_mulai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Mulai Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_jam_sampai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Sampai Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_impact == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Adverse Impact Tidak Boleh Kosong",
        });
        return false;
      }
      // j_keterangan
      if (this.form_data.j_keterangan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Keterangan Tidak Boleh Kosong",
        });
        return false;
      } else {
        this.submitData();
      }
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    addFormUnit() {
      this.form_data.form_unit.push({
        m_unit_slug: "",
        m_unit_idm_unit: "",
        jadwal_idjadwal: null,
        ju_txt_unit: "",
        jumlah_diundang: "",
      });
    },
    clearFormUnit(index) {
      this.form_data.form_unit[index].m_unit_slug = "";
      this.form_data.form_unit[index].m_unit_idm_unit = "";
      this.form_data.form_unit[index].jadwal_idjadwal = null;
      this.form_data.form_unit[index].ju_txt_unit = "";
      this.form_data.form_unit[index].jumlah_diundang = "";
      this.selected_unit.splice(index, 1);
      this.selected_jumlah_peserta.splice(index, 1);
      this.form_data.form_unit.splice(index, 1);
    },
    addForm() {
      this.form_data.form_petugas.push({
        jp_txt_petugas: "",
        jp_txt_tugas: "",
        jadwal_idjadwal: null,
        m_user_id_petugas: "",
        m_user_nip_petugas: "",
      });
    },
    clearForm(index) {
      this.form_data.form_petugas[index].jp_txt_petugas = "";
      this.form_data.form_petugas[index].jp_txt_tugas = "";
      this.form_data.form_petugas[index].jadwal_idjadwal = null;
      this.form_data.form_petugas[index].m_user_id_petugas = "";
      this.form_data.form_petugas[index].m_user_nip_petugas = "";
      this.selected_petugas.splice(index, 1);
      this.selected_tugas.splice(index, 1);
      this.form_data.form_petugas.splice(index, 1);
    },

    addFormAsesmenGrade() {
      this.form_data.form_asesmen_grade.push({
        id_jenis_asesmen: "",
        nama_jenis_asesmen: "",
      });
    },
    clearFormAsesmenGrade(index) {
      this.form_data.form_asesmen_grade[index].id_jenis_asesmen = "";
      this.form_data.form_asesmen_grade[index].jp_txt_tugas = "";
      this.selected_jenis_asesmen.splice(index, 1);
      this.form_data.form_asesmen_grade.splice(index, 1);
    },

    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },

    //search & get jenis asesmen
    async getJenisAsesmen() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        params: {
          tipe: "PEG POG",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_jenis_asesmen = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    async getMateri() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_materi = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    async getUnit() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/unit",
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_unit = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onSearchUnit(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUnit(loading, search, this);
      }
    },
    searchUnit: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/unit",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_unit = response.data.data.referensi;
          loading(false);
        }
      });
    },

    async getBatreTest() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-test",
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_batrei_test = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    async getKegiatan() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kegiatan",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_kegiatan = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    async getSubKegiatan() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/sub-kegiatan",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_sub_kegiatan = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //search & get lokasi
    async getLokasi() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-lokasi",
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_lokasi = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onSearchLokasi(search, loading) {
      if (search.length) {
        loading(true);
        this.searchLokasi(loading, search, this);
      }
    },
    searchLokasi: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-lokasi",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_lokasi = response.data.data.referensi;
          loading(false);
        }
      });
    },

    //search & get user
    async getPTL() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user",
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_ptl = response_data.data.referensi;
            Swal.close();
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onSearchPTL(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPTL(loading, search, this);
      }
    },
    searchPTL: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_ptl = response.data.data.referensi;
          loading(false);
        }
      });
    },
    changeRoute(index) {
      this.form_data.form_petugas[index].m_user_id_petugas = this.selected_petugas[index].iduser;
      this.form_data.form_petugas[index].m_user_nip_petugas = this.selected_petugas[index].u_nip;
      this.form_data.form_petugas[index].jp_txt_petugas = this.selected_petugas[index].u_nama;
    },
    changeRouteAsesmenGrade(index) {
      console.log(this.form_data.j_tipe);
      this.form_data.form_asesmen_grade[index].id_jenis_asesmen = this.selected_jenis_asesmen[index].id;
      this.form_data.form_asesmen_grade[index].nama_jenis_asesmen = this.selected_jenis_asesmen[index].nama_jenisasesmen;
    },
    changeRouteTugas(index) {
      this.form_data.form_petugas[index].jp_txt_tugas = this.selected_tugas[index].tugas;
    },
    changeUnit(index) {
      this.form_data.form_unit[index].ju_txt_unit = this.selected_unit[index].ju_txt_unit;
      this.form_data.form_unit[index].m_unit_idm_unit = this.selected_unit[index].m_unit_idm_unit;
      this.form_data.form_unit[index].m_unit_slug = this.selected_unit[index].m_unit_slug;
    },
    submitData() {
      console.log(this.form_data);
      let self = this;
      //validate disallowed character on form
      if (containsDisallowedCharacters(self.form_data.j_keterangan)) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return; // Exit the function if validation fails
      }
      let lokasi_nama = "";
      let lokasi_id = "";
      if (self.checkedLokasi) {
        lokasi_nama = self.lokasi_text;
        lokasi_id = "";
      } else {
        lokasi_nama = self.selected_lokasi.ml_nama;
        lokasi_id = self.selected_lokasi.ml_id;
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog",
        data: {
          j_tanggal_mulai: self.form_data.j_tanggal_mulai,
          j_tanggal_sampai: self.form_data.j_tanggal_sampai,
          j_jam_mulai: self.form_data.j_jam_mulai,
          j_jam_sampai: self.form_data.j_jam_sampai,
          id_jenis_asesmen: self.selected_jenis_asesmen.id,
          txt_jenis_asesmen: self.selected_jenis_asesmen.nama_jenisasesmen,
          m_jadwal_kegiatan_idm_kegiatan: self.selected_kegiatan.id,
          j_txt_kegiatan: self.selected_kegiatan.kegiatan,
          m_lokasi_idm_lokasi: lokasi_id,
          j_txt_lokasi: lokasi_nama,
          m_user_id_ptl: self.selected_ptl.iduser,
          m_user_nip_ptl: self.selected_ptl.u_nip,
          j_txt_ptl: self.selected_ptl.u_nama,
          j_keterangan: self.form_data.j_keterangan,
          id_alattes_global: self.selected_alat_test.id_alat_test_global,
          form_asesmen_grade: self.form_data.form_asesmen_grade,
          j_tipe: self.form_data.j_tipe,
          // j_materi: self.selected_materi.mm_nama,
          // m_batre_test_bt_id: self.selected_batrei_test.bt_id,
          // j_txt_batre_test: self.selected_batrei_test.bt_nama,
          // j_json_alattes: self.option_alat_test,
          m_id_subkegiatan: self.selected_sub_kegiatan.id_subkegiatan,
          m_nama_kegiatan: self.selected_sub_kegiatan.nama_subkegiatan,
          j_is_survey: self.selected_survey_atasan,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            self.storeDataPetugas(response.data.data[0].idjadwal);
          }
        })
        .catch((error) => {
          Swal.close();
          console.log(error);
        });

      console.log(JSON.stringify(this.form_data.form_petugas));
    },
    storeDataPetugas(idjadwal) {
      let self = this;
      for (let index = 0; index < self.form_data.form_petugas.length; index++) {
        self.form_data.form_petugas[index].jadwal_idjadwal = idjadwal;
      }
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/store-petugas",
        data: {
          idjadwal,
          j_json_petugas: self.form_data.form_petugas,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.storeDataUnit(idjadwal);
        }
      });
    },
    storeDataUnit(idjadwal) {
      let self = this;

      for (let index = 0; index < self.form_data.form_unit.length; index++) {
        self.form_data.form_unit[index].jadwal_idjadwal = idjadwal;
      }
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog/store-unit",
        data: {
          idjadwal,
          json_unit: self.form_data.form_unit,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      var encryptedidjadwal = self.encryptedUrl(idjadwal.toString());
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Berhasil Menambahkan Jadwal",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({
                name: "detail-penjadwalan",
                params: { id: encryptedidjadwal },
              });
            }
          });
        }
      });
    },
    checkLokasi() {
      let self = this;
      this.$nextTick(() => {
        console.log(self.checkedLokasi);
      });
    },
    onSelectBatreTest() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-alat-test",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          id_batretest: this.selected_batrei_test.bt_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_alat_test = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onSearchJenisAsesmen(search, loading) {
      if (search.length) {
        loading(true);
        this.searchJenisAsesmen(loading, search, this);
      }
    },
    searchJenisAsesmen: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        params: {
          keyword: search,
          tipe: "PEG POG",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_jenis_asesmen = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onSearchJenisKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchJenisKegiatan(loading, search, this);
      }
    },
    searchJenisKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onSearchSubKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchSubKegiatan(loading, search, this);
      }
    },
    searchSubKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/sub-kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_sub_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onSearchMateri(search, loading) {
      if (search.length) {
        loading(true);
        this.searchMateri(loading, search, this);
      }
    },
    searchMateri: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_materi = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onSearchBatreTest(search, loading) {
      if (search.length) {
        loading(true);
        this.searchBatreTest(loading, search, this);
      }
    },
    searchBatreTest: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-test",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_batrei_test = response.data.data.referensi;
          loading(false);
        }
      });
    },
    async getAlatTes() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/alat-tes",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_alat_test = response_data.data.referensi;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onSearchAlatTest(search, loading) {
      if (search.length) {
        loading(true);
        this.searchAlatTest(loading, search, this);
      }
    },
    searchAlatTest: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/alat-tes",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_alat_test = response.data.data.referensi;
          loading(false);
        }
      });
    },
    changeRouteTipe() {
      this.form_data.form_asesmen_grade.splice(1);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="validasi()">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="card-title">Tambah Jadwal Pembinaan Grade</div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <!-- <router-link :to="{ name: 'add-penjadwalan-konversi' }" class="btn btn-sm btn-success"><i
                                            class="fa fa-plus"></i> Tambah</router-link> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mt-4">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="Metode" label-for="tgl-mulai">
                        <b-form-input id="tgl-mulai" class="bg-secondary text-white" type="text" readonly v-model="form_data.j_metode"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Tanggal Mulai" label-for="tgl-mulai">
                        <b-form-input id="tgl-mulai" type="date" v-model="form_data.j_tanggal_mulai"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Tanggal Selesai" label-for="tgl-selesai">
                        <b-form-input id="tgl-selesai" placeholder="Tanggal Selesai" type="date" v-model="form_data.j_tanggal_sampai"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Jam Mulai" label-for="tgl-mulai">
                        <b-form-input id="tgl-mulai" type="time" v-model="form_data.j_jam_mulai"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group class="mb-3" label="Jam Selesai" label-for="tgl-selesai">
                        <b-form-input id="tgl-selesai" type="time" v-model="form_data.j_jam_sampai"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="Tipe Jadwal" label-for="tipe">
                        <v-select :options="['PEG', 'Single']" label="tipe" v-model="form_data.j_tipe" placeholder="-Pilih Tipe Jadwal-" @update:modelValue="changeRouteTipe()"></v-select>
                      </b-form-group>
                    </div>
                  </div> -->
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Kegiatan"
                        label-for="tgl-mulai"
                      >
                        <v-select
                          :options="option_kegiatan"
                          label="kegiatan"
                          v-model="selected_kegiatan"
                          @search="onSearchJenisKegiatan"
                          placeholder="-Pilih Kegiatan-"
                          @update:modelValue="onChangeJenisKegiatan($event)"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="Sub Kegiatan">
                        <v-select :options="option_sub_kegiatan" label="nama_subkegiatan" v-model="selected_sub_kegiatan" @search="onSearchSubKegiatan" placeholder="-Pilih Sub Kegiatan-"></v-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="Survey Atasan">
                        <v-select :options="option_survey_atasan" label="survey_atasan" v-model="selected_survey_atasan" placeholder="-Pilih Survey Atasan-"></v-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" v-for="(form, k) in form_data.form_asesmen_grade" :key="k">
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Asesmen Grade" label-for="ptl">
                        <v-select
                          :options="option_jenis_asesmen"
                          label="nama_jenisasesmen"
                          v-model="selected_jenis_asesmen[k]"
                          placeholder="-Pilih Asesmen Grade-"
                          @search="onSearchJenisAsesmen"
                          @update:modelValue="changeRouteAsesmenGrade(k)"
                        >
                          <template v-slot:option="option">
                            <span :class="option.nama_jenisasesmen"></span>
                            {{ option.nama_jenisasesmen }} --
                            <span :class="option.level"></span>
                            {{ option.level }}
                          </template>
                        </v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-2 d-flex align-items-center" v-if="k == 0">
                      <button class="btn btn-success mt-2" type="button" @click="addFormAsesmenGrade()">
                        <span><i class="fa fa-plus"></i> </span>
                      </button>
                    </div>
                    <div class="col-md-2 d-flex align-items-center" v-if="k > 0">
                      <button class="btn btn-success mt-2" @click="clearFormAsesmenGrade(k)" type="button">
                        <span><i class="fa fa-minus"></i> </span>
                      </button>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="Jenis Asesmen" label-for="tgl-mulai">
                        <v-select :options="option_jenis_asesmen" label="nama_jenisasesmen" @search="onSearchJenisAsesmen" v-model="selected_jenis_asesmen" placeholder="-Pilih Jenis Asesmen -">
                          <template v-slot:option="option">
                            <span :class="option.nama_jenisasesmen"></span>
                            {{ option.nama_jenisasesmen }} -
                            <span :class="option.level"></span>
                            {{ option.level }}
                          </template>
                        </v-select>
                      </b-form-group>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-md-2">
                      <!-- <b-form-group
                        class="mb-3"
                        label="Lokasi"
                        label-for="lokasi"
                      >
                        <v-select
                          :options="option_lokasi"
                          label="ml_nama"
                          v-model="selected_lokasi"
                          placeholder="-Pilih Lokasi-"
                          @search="onSearchLokasi"
                        ></v-select>
                      </b-form-group> -->
                      <b-form-group class="mb-3" label="Lokasi" label-for="lokasi">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="checkedLokasi" value="true" id="autoSizingCheck" @change="checkLokasi($event)" />
                          <label class="form-check-label" for="autoSizingCheck"> Isi Text </label>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-md-10">
                      <b-form-group class="mb-3" v-if="!checkedLokasi" label="Pilih Lokasi" label-for="lokasi">
                        <v-select :options="option_lokasi" label="ml_nama" v-model="selected_lokasi" placeholder="-Pilih Lokasi-" @search="onSearchLokasi"></v-select>
                      </b-form-group>
                      <b-form-group class="mb-3" v-else label="Lokasi" label-for="lokasiText">
                        <b-form-input id="lokasiText" placeholder="Masukkan Nama Lokasi" type="text" v-model="lokasi_text"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="PTL" label-for="ptl">
                        <v-select :options="option_ptl" label="label" v-model="selected_ptl" placeholder="-Pilih PTL-" @search="onSearchPTL"></v-select>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-5 mt-4">
                  <div class="row" v-for="(form, k) in form_data.form_petugas" :key="k">
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Petugas" label-for="ptl">
                        <v-select :options="option_ptl" label="label" v-model="selected_petugas[k]" placeholder="-Pilih Petugas-" @search="onSearchPTL" @update:modelValue="changeRoute(k)"></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Tugas" label-for="tugas">
                        <v-select :options="option_tugas" v-model="selected_tugas[k]" label="tugas" placeholder="-Pilih Tugas-" @update:modelValue="changeRouteTugas(k)"></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-2 d-flex align-items-center" v-if="k == 0">
                      <button class="btn btn-success mt-2" type="button" @click="addForm()">
                        <span><i class="fa fa-plus"></i> </span>
                      </button>
                    </div>
                    <div class="col-md-2 d-flex align-items-center" v-if="k > 0">
                      <button class="btn btn-success mt-2" @click="clearForm(k)" type="button">
                        <span><i class="fa fa-minus"></i> </span>
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="Keterangan" label-for="keterangan">
                        <b-form-textarea id="keterangan" type="textarea" v-model="form_data.j_keterangan"></b-form-textarea>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" v-for="(form, k) in form_data.form_unit" :key="k">
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Unit Peserta" label-for="ptl">
                        <v-select :options="option_unit" label="m_unit_slug" v-model="selected_unit[k]" placeholder="-Pilih Unit-" @search="onSearchUnit" @update:modelValue="changeUnit(k)"></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-5">
                      <b-form-group class="mb-3" label="Peserta Diundang" label-for="pesertaDiundang">
                        <b-form-input id="pesertaDiundang" placeholder="Masukkan jumlah peserta diundang" type="text" v-model="form_data.form_unit[k].jumlah_diundang"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-2 d-flex align-items-center" v-if="k == 0">
                      <button class="btn btn-success mt-2" type="button" @click="addFormUnit()">
                        <span><i class="fa fa-plus"></i> </span>
                      </button>
                    </div>
                    <div class="col-md-2 d-flex align-items-center" v-if="k > 0">
                      <button class="btn btn-success mt-2" @click="clearFormUnit(k)" type="button">
                        <span><i class="fa fa-minus"></i> </span>
                      </button>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Pilih Materi"
                        label-for="ptl"
                      >
                        <v-select
                          :options="option_materi"
                          label="mm_nama"
                          v-model="selected_materi"
                          placeholder="-Pilih Materi-"
                          @search="onSearchMateri"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Pilih Batre Tes"
                        label-for="ptl"
                      >
                        <v-select
                          :options="option_batrei_test"
                          label="bt_nama"
                          v-model="selected_batrei_test"
                          placeholder="-Pilih Batre Tes-"
                          @search="onSearchBatreTest"
                          @update:modelValue="onSelectBatreTest()"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Pilih Alat Tes"
                        label-for="ptl"
                      >
                        <v-select
                          :options="option_alat_test"
                          label="nama_alattes"
                          v-model="selected_alat_test"
                          placeholder="-Pilih Alat Tes-"
                          @search="onSearchPTL"
                          :disabled="selected_batrei_test.length < 1"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div> -->
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <b-form-group class="mb-3" label="Alat Tes" label-for="ptl">
                        <v-select :options="option_alat_test" label="nama_lengkap" v-model="selected_alat_test" placeholder="-Pilih Alat Tes-" @search="onSearchAlatTest"></v-select>
                      </b-form-group>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="row">
                <div class="d-grid gap-2 col-6 mx-auto">
                  <button class="btn btn-primary" type="submit">Simpan</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
