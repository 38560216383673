<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: ["data_peserta","id_asesi","id_jadwal",'ja_nip',"idjadwal_peserta","id_jenisasesmen","jenis_asesmen", "tanggal_mulai_pelakasanaan"],
  data() {
    return {
      table_data: [],
    };
  },
  mounted() {
    let self = this;
    self.getData();
  },
  methods: {
    getData() {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/detail-soft-competency-supervisor",
        params: {
          nip: this.$props.ja_nip,
          idjadwal: this.$props.idjadwal_peserta,
          id_jenisasesmen: this.$props.id_jenisasesmen,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix;
            console.log(self.table_data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<template>
  <div>
    <b>{{ jenis_asesmen}} </b> - <b>{{ tanggal_mulai_pelaksanaan }}</b>
    <div class="table-responsive">
      <table class="table table-bordered mb-0 mt-2">
        <thead class="bg-dark text-white" style="padding: 10px">
          <th style="padding: 5px">No</th>
          <th style="padding: 5px">Kompetensi</th>
          <th style="padding: 5px">Value</th>
        </thead>
        <tbody>
          <tr v-for="(data,key) in table_data" :key="key">
            <td>{{ key+1 }}</td>
            <td>{{ data.nda_namaaspek }}</td>
            <td>{{ data.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <table
      border="1"
      class="table-responsive"
      style="border-collapse: collapse"
    >
      <thead>
        <th>Product</th>
        <th>Month</th>
        <th>Unit price</th>
        <th>No. sold</th>
      </thead>
      <tbody>
        <template v-for="mSale in salesData.monthlySales" :key="mSale">
          <tr v-for="(month, key) in mSale.months" :key="key">
            <td v-if="key == 0" :rowspan="mSale.months.length">
              {{ mSale.product }}
            </td>
            <td>{{ month.month }}</td>
            <td>{{ month.unitPrice }}</td>
            <td>{{ month.unitsSold }}</td>
          </tr>
        </template>
      </tbody>
    </table> -->
  </div>
</template>
