<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Jadwal Asesmen PEG POG",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        Datatable,
        Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "idjadwal" },
            { width: "auto", label: "Tanggal Mulai", name: "j_tanggal_mulai" },
            { width: "auto", label: "Tanggal Selesai", name: "j_tanggal_sampai" },
            { width: "auto", label: "Jenis Asesmen", name: "j_txt_dirkom" },
            // { width: "auto", label: "Batch", name: "batch" },
            { width: "auto", label: "Jumlah Peserta Diundang", name: "-" },
            { width: "auto", label: "Jumlah Peserta Hadir", name: "-" },
            { width: "150px", label: "Created At", name: "j_created_at" },
            { width: "150px", label: "Updated At", name: "j_updated_at" },
            { width: "150px", label: "Status", name: "j_status" },
            { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            title: "Jadwal Asesmen PEG POG",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Jadwal Asesmen PEG POG",
                    active: true,
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            loadingTable: true,
            table_data: [],
            columns: columns,
            sortKey: "id_role", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",
            option_jenis_asesmen: [],

            selected_jenis_asesmen: [],
            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_jenis_asesmen: "",
                // filter_status: "ENABLE", // untuk filtering data
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
        };
    },
    mounted() {
        console.log('asdads');
        let self = this;
        self.cekAksesMenu();
        self.getDataTable();
        self.getJenisAsesmen();
    },
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        },
        cekAksesMenu() {
            let self = this;
            var config_axios = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
                params: {
                    role_id: self.sessionRoleId,
                    link_name: self.namePathUrl,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_axios)
                .then((response) => {
                    let response_data_fix = response.data.data.status_access;
                    if (response.data.meta.code == 200) {
                        if (response_data_fix == false) {
                            Swal.fire({
                                icon: "warning",
                                title: "Oppss",
                                text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
                                timer: 2000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.$router.push({ name: "dashboard" });
                                }
                            });
                        }
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        //search & get jenis asesmen
        getJenisAsesmen() {
            let self = this;
            var config = {
                method: "get",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    if (response_data.meta.code == 200) {
                        self.option_jenis_asesmen = response_data.data.referensi;
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        getDataTable(
            url = process.env.VUE_APP_BACKEND_URL_VERSION + "penjadwalan-konversi/"
        ) {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;
            self.tableData.filter_jenis_asesmen = self.selected_jenis_asesmen.nama_jenisasesmen;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        if (self.tableData.draw == response_data_fix.draw) {
                            self.table_data = response_data_fix.list_data.data;
                            self.configPagination(response_data_fix.list_data);
                            self.currentTablePage = response_data_fix.list_data.current_page;
                        }
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        configPagination(data) {
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
        toPage: function (str) {
            let self = this;
            self.getDataTable(str);
        },
        sortBy(key) {
            let self = this;
            self.sortKey = key;
            self.sortOrders[key] = self.sortOrders[key] * -1;
            self.tableData.column = self.getIndex(self.columns, "name", key);
            self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
            self.getDataTable();
        },
        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        deleteData(id) {
            let self = this;
            Swal.fire({
                title: "Perhatian",
                text: "Anda yakin ingin menghapus data tersebut?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Iya`,
                denyButtonText: `Tidak`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fa fa-refresh fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });

                    var config = {
                        method: "delete",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
                        data: {
                            id: id,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        var response_data = response.data;
                        if (response_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data.data.message,
                            });
                        } else {
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Data berhasil dihapus.",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.getDataTable();
                                }
                            });
                        }
                    });
                } else {
                    Swal.close();
                }
            });
        },
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-title">Semua Jadwal Asesmen PEG POG</div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-end">
                                    <a class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Tambah Jadwal Asesmen PEG
                                        POG</a>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <hr />
                                <div class="row">
                                    <div class="form-group col-md-2">
                                        <label for="tanggalMulai">Tanggal Mulai</label>
                                        <input type="date" class="form-control" v-model="tableData.filter_tanggal_mulai"
                                            @change="getDataTable()">
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="tanggalSampai">Tanggal Sampai</label>
                                        <input type="date" class="form-control" v-model="tableData.filter_tanggal_sampai"
                                            @change="getDataTable()">
                                    </div>
                                    <!-- <div class="form-group col-md-2">
                                        <label for="JenisAsesmen">Jenis Asesmen</label>
                                        <input type="text" class="form-control" v-model="tableData.filter_jenis_asesmen"
                                            @change="getDataTable()">
                                    </div> -->
                                    <div class="form-group col-md-4">
                                        <label for="Batch">Jenis Asesmen</label>
                                        <v-select :options="option_jenis_asesmen" label="nama_jenisasesmen"
                                            v-model="selected_jenis_asesmen" @update:modelValue="getDataTable()"
                                            placeholde="-Pilih Jenis Asesmen-"></v-select>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="col-md-12 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable"
                                                        style="width: 100%" v-model="tableData.length"
                                                        @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable"
                                            placeholder="Cari Data ..." v-model="tableData.search"
                                            @input="getDataTable()" />
                                    </div>
                                </div>
                            </div>
                            <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                                <tbody>
                                    <tr v-if="loadingTable">
                                        <td colspan="6">
                                            <i class="fas fa-spinner fa-spin"></i> Loading...
                                        </td>
                                    </tr>
                                    <tr v-else-if="table_data == ''">
                                        <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                                    </tr>
                                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                                        <td class="text-center">
                                            <div v-if="key_data + 1 == 10">
                                                {{ currentTablePage }}0.
                                            </div>
                                            <div v-else>
                                                {{
                                                    currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                                                }}{{ key_data + 1 }}.
                                            </div>
                                        </td>
                                        <td>
                                            {{ row_data.j_tanggal_mulai }}
                                        </td>
                                        <td>
                                            {{ row_data.j_tanggal_sampai }}
                                        </td>
                                        <td>
                                            {{ row_data.j_txt_dirkom }}
                                        </td>
                                        <!-- <td>
                                            {{ row_data.batch }}
                                        </td> -->
                                        <td>
                                            {{ row_data.ju_peserta_diundang }}
                                        </td>
                                        <td>
                                            {{ row_data.j_peserta_hadir }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                row_data.j_created_at
                                                ? fullDateTimeFormat(row_data.j_created_at)
                                                : "-"
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                row_data.j_updated_at
                                                ? fullDateTimeFormat(row_data.j_updated_at)
                                                : "-"
                                            }}
                                        </td>
                                        <td>
                                            <span style="white-space:nowrap;" class="badge bg-danger"
                                                v-if="row_data.j_status == 'void'">
                                                Void
                                            </span>
                                            <span style="white-space:nowrap;" class="badge bg-info"
                                                v-if="row_data.j_status == 'Draft'">
                                                Draft
                                            </span>
                                            <span style="white-space:nowrap;" class="badge bg-warning"
                                                v-else-if="row_data.j_status == 'verifikasi'">
                                                Verifikasi Jadwal
                                            </span>
                                            <span style="white-space:nowrap;" class="badge bg-success"
                                                v-else-if="row_data.j_status == 'proses_ptl'">
                                                Proses Jadwal ke PTL
                                            </span>
                                            <span style="white-space:nowrap;" class="badge bg-primary"
                                                v-else-if="row_data.j_status == 'selesai'">
                                                Selesai
                                            </span>
                                            <span style="white-space:nowrap;" class="badge bg-info"
                                                v-else-if="row_data.j_status == 'MK Fix'">
                                                MK Fix
                                            </span>
                                            <span style="white-space:nowrap;" class="badge bg-danger"
                                                v-else-if="row_data.j_status == 'batal'">
                                                Batal
                                            </span>
                                            <span v-else>
                                                {{ row_data.j_status }}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <div>
                                                <div class="btn-group">
                                                    <a class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Edit</a>
                                                    <button type="button" class="btn btn-danger btn-sm"
                                                        v-on:click="deleteData(row_data.user_id)">
                                                        <i class="fa fa-trash"></i> Hapus
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </datatable>
                        </div>
                        <pagination :pagination="pagination" @to-page="toPage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
