<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import importjenisDetailAc from "./importjenis-detail-ap.vue";
import importalatDetailAc from "./importalat-detail-ap.vue";
import $ from "jquery";
import { decryptData } from "@/helpers/crypt.js";
import { encryptData } from "@/helpers/crypt.js";
import { debounce } from "@/helpers/debounce";

export default {
  page: {
    title: "Detail Asesmen Center",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    importalatDetailAc,
    importjenisDetailAc,
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "NIP", name: "ja_nip" },
      { width: "auto", label: "Nama", name: "ja_nama" },
      {
        width: "150px",
        label: "Nomer Telepon",
        name: "rekomendasi_kompetensi",
      },
      { width: "150px", label: "Unit", name: "rekomendasi_potensi" },
      { width: "150px", label: "Jabatan saat ini", name: "rekomendasi_atasan" },
      { width: "150px", label: "Konfirmasi" },
      { width: "50px", label: "kehadiran", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      { width: "50px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      data_jadwal: [],
      idjadwal: decryptData(this.$route.params.id),
      data_petugas: [],
      data_alat_test: [],
      showModalUpdateStatus: false,
      currentActiveIndex: 0,
      selected_status: "",
      table_data: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      showJenis: true,
      jenisTestClass: "btn-primary",
      alatTestClass: "btn-white",
      showModalImportExcel: false,
      file_temp: null,
      data_id_temp: [],
      peserta_diundang: 0,
      jumlah_peserta: 0,
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_jadwal: "",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      isUploadFile: false,
    };
  },
  mounted() {
    this.getDataJadwal();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataJadwal();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    getDataJadwal() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center",
        params: {
          idjadwal: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.data_jadwal = response_data.data.list_data;
            self.peserta_diundang = self.data_jadwal.jadwal_unit[0].ju_peserta_diundang;
            self.jumlah_peserta = response_data.data.count;
            self.data_petugas = JSON.parse(self.data_jadwal.j_json_petugas);
            self.data_alat_test = JSON.parse(self.data_jadwal.j_json_alattes);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    ConfirmResetAssesi() {
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin mereset Asesi?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetAsesi();
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },

    resetAsesi() {
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/vertifikasi/reset-temporary",
        params: {
          user_id: localStorage.session_user_id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Reset Asesi",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            });

            Swal.close();
            window.location.reload();
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data.message,
          });
        });
    },
    showJenisAssessment() {
      this.showJenis = true;
      this.jenisTestClass = "btn-primary";
      this.alatTestClass = "btn-white";
    },
    showAlatTest() {
      this.showJenis = false;
      this.jenisTestClass = "btn-white";
      this.alatTestClass = "btn-primary";
    },
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/pegpog/template_import_asesi.xlsx";
      link.download = "/pegpog/template_import_asesi.xlsx";
      link.click();
    },
    uploadFile() {
      let self = this;
      self.isUploadFile = true;
      if ($("#importexcel")[0].files[0]) {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("file", $("#importexcel")[0].files[0]);
        var config = {
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            self.file_temp = response.data.data.path_file;
            self.isUploadFile = false;
          })
          .catch((errors) => {
            console.log(errors);
            self.isUploadFile = false;
          });
      }
    },
    StoreData() {
      let self = this;
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/detail-center/assesitab/import-asesi",
        data: {
          id_jadwal: self.idjadwal,
          path_file: self.file_temp,
          user_id: localStorage.session_user_id,
          max_peserta: self.peserta_diundang,
          jumlah_sekarang: self.jumlah_peserta,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data.data;

          if (response.data.meta.code == 200) {
            let dataFailed = response_data.total_data_failed;

            if (dataFailed != 0) {
              Swal.fire({
                icon: "warning",
                title: "Perhatian",
                text: "Tedapat " + dataFailed + " data yang perlu divalidasi",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  this.showModalImportExcel = false;
                  this.$router.push({
                    name: "validasi-asesi-asesmen-psikologi",
                    params: {
                      idjadwal: this.encryptedUrl(self.idjadwal.toString()),
                    },
                  });
                }
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Import Asesi",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.data?.error || "Data Gagal Di Import";
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errorMessage,
            timerProgressBar: true,
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              window.location.reload();
            }
          });
        });
    },
    getDataidTemp(id_temp) {
      this.data_id_temp = id_temp;
    },

    confirmasiProsesJadwal() {
      if (this.data_id_temp.length == 0) {
        Swal.fire({
          icon: "info",
          text: "Mohon Pilih data yang akan di proses",
        });
      } else {
        console.log(this.data_id_temp);
        Swal.fire({
          title: "Perhatian",
          text: "Anda yakin ingin memproses jadwal?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Iya`,
          denyButtonText: `Tidak`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.prosesJadwal();
          } else if (result.isDenied) {
            Swal.close();
          }
        });
      }
    },

    prosesJadwal() {
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/vertifikasi/proses-import-asesi",
        data: {
          id_jadwal: this.idjadwal,
          user_id: localStorage.session_user_id,
          peserta: this.data_id_temp,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;

          if (response_data.meta.code == 200) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil Proses Jadwal",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(() => {
              // if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              this.$router.push({
                name: "detail-asesmen-psikologi",
                params: {
                  idjadwal: this.encryptedUrl(this.idjadwal.toString()),
                },
              });
              // }
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: error.response.data.data.message,
            showCancelButton: false,
            showConfirmButton: true, // Show the "OK" button
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({
                name: "detail-asesmen-psikologi",
                params: {
                  idjadwal: this.encryptedUrl(this.idjadwal.toString()),
                },
              });
            }
          });
        });
    },

    HapusByidTemp() {
      if (this.data_id_temp.length == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Pilih data yang akan dihapus",
        });
      } else {
        var config = {
          method: "delete",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/vertifikasi/delete-temporary",
          data: {
            id_temp: this.data_id_temp,
            user_id: localStorage.session_user_id,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        };
        axios(config)
          .then((response) => {
            if (response.data.meta.code == 200) {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Hapus Asesi",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.data.message,
            });
          });
      }
    },
    exportExcelJenisAssesment() {
      Swal.fire({
        title: "Loading...",
        text: "Mohon Tunggu Sebentar",
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      this.tableData.user_id = localStorage.session_user_id;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/vertifikasi/export-jenis-assesment",
        responseType: "blob",
        crossdomain: true,
        params: this.tableData,
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        Swal.close();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename = "Export-Jenis-Assesment" + new Date().toISOString().slice(0, 10) + ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },
    exportExcelAlatTest() {
      Swal.fire({
        title: "Loading...",
        text: "Mohon Tunggu Sebentar",
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      this.tableData.user_id = localStorage.session_user_id;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/vertifikasi/export-alat-test",
        responseType: "blob",
        crossdomain: true,
        params: this.tableData,
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        Swal.close();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename = "Export-AlatTes" + new Date().toISOString().slice(0, 10) + ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Detail Asesmen Psikologi</div>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td scope="row" style="width: 20%">ID Jadwal</td>
                        <td style="width: 5%">:</td>
                        <td>
                          <div class="d-flex justify-content-between">
                            #{{ data_jadwal?.idjadwal }}
                            <div v-if="data_jadwal?.j_status == 'Draft'">
                              <span class="badge badge-pill badge-soft-info font-size-12">Draft</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'void'">
                              <span class="badge badge-pill badge-soft-danger font-size-12">Batal</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'proses_ptl'">
                              <span class="badge badge-pill badge-soft-info font-size-12">Proses Verifikasi PTL</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'verifikasi'">
                              <span class="badge badge-pill badge-soft-info font-size-12">Verifikasi Asesi</span>
                            </div>
                            <div v-if="data_jadwal?.j_status == 'MK Draft'">
                              <span class="badge badge-pill badge-soft-info font-size-12">MK Draft</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Tanggal Mulai</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_tanggal_mulai }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Tanggal Selesai</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_tanggal_sampai }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Unit Client</td>
                        <td>:</td>
                        <td>
                          <ul>
                            <li v-for="unit in data_jadwal?.jadwal_unit" :key="unit.idjadwal_unit">
                              <p>Daerah: {{ unit.m_unit_slug }}</p>
                              <p>Unit: {{ unit.ju_txt_unit }}</p>
                              <p>Diundang: {{ unit.ju_peserta_diundang }}</p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Jenis Asesmen</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_txt_dirkom }}</td>
                      </tr>

                      <tr>
                        <td scope="row">Keterangan</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_keterangan }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td scope="row" style="width: 20%">Kegiatan</td>
                        <td style="width: 5%">:</td>
                        <td>{{ data_jadwal?.j_txt_kegiatan }}</td>
                      </tr>
                      <tr>
                        <td scope="row" style="width: 20%">PTL</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_txt_ptl }}</td>
                      </tr>
                      <tr v-for="(jadwal, index) in data_petugas" :key="index">
                        <td scope="row">
                          <span v-if="index == 0">Petugas</span>
                        </td>
                        <td><span v-if="index == 0">:</span></td>
                        <td>{{ index + 1 + ". " + jadwal.jp_txt_petugas }}</td>
                      </tr>
                      <template
                        v-if="data_jadwal?.j_metode != 'Feedback Individu' && data_jadwal?.j_metode != 'Talent Report'">
                        <tr>
                          <td scope="row">Batre Test</td>
                          <td>:</td>
                          <td v-for="item in data_jadwal?.batre_test" :key="item.bt_id">
                            {{ item.bt_nama }}
                          </td>
                        </tr>

                        <tr v-for="(alat_test, index) in data_alat_test" :key="index">
                          <td scope="row">
                            <span v-if="index == 0">Alat Test</span>
                          </td>
                          <td><span v-if="index == 0">:</span></td>
                          <td>
                            {{ index + 1 + ". " + alat_test.m_alat_tes_nama }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">DATA VERIFIKASI</div>
              </div>
              <div class="col-md-6 justify-content-end d-flex mb-2">
                <!-- <a
                  class="btn btn-sm btn-warning mr-2"
                  @click="exportExcelJenisAssesment"
                  ><i class="fas fa-file-excel mr-2"></i>Export Data Jenis
                  Assesment</a
                >
                <a
                  class="btn btn-sm btn-warning mr-2"
                  @click="exportExcelAlatTest"
                  ><i class="fas fa-file-excel mr-2"></i>Export Data Alat
                  test</a
                > -->
                <button class="btn btn-sm btn-success mr-2" @click="showModalImportExcel = true"><i
                    class="fas fa-file-excel mr-2"></i>Import Data excel</button>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="card-title text-primary"><i class="fa fa-exclamation-triangle mr-2"></i>Pengecekan Hasil
                  Asesmen
                </div>
              </div>
              <div class="col-md-6 justify-content-end d-flex mb-2">
                <a class="btn btn-sm btn-danger mr-2" @click="ConfirmResetAssesi"><i
                    class="fas fa-redo-alt mr-2"></i>Reset
                  Asesi</a>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-6">
                <button class="btn" :class="jenisTestClass" @click="showJenisAssessment">Jenis Assesment</button>
                <button class="btn" :class="alatTestClass" @click="showAlatTest">Alat Test</button>
              </div>
              <div class="col-md-6"></div>
            </div>
            <template v-if="showJenis">
              <importjenisDetailAc :data_jadwal="data_jadwal" @selected-temp="getDataidTemp" />
            </template>

            <template v-else>
              <importalatDetailAc :data_jadwal="data_jadwal" @selected-temp="getDataidTemp" />
            </template>
            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-danger" @click="HapusByidTemp"><i class="fas fa-trash mr-2"></i>Hapus
                  Asesi</button>
              </div>
              <div class="col-md-6 justify-content-end d-flex mb-2">
                <button class="btn btn-primary mr-2" @click="confirmasiProsesJadwal">Proses ke jadwal <i
                    class="fa fa-arrow-right ml-2"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="my-legend">
                <div class="legend-title">Legenda</div>
                <div class="legend-scale">
                  <ul class="legend-labels">
                    <li><span style="background: #ffceca"></span>Tidak berlaku lebih dari 2 tahun</li>
                    <li><span style="background: #d4d4d4"></span>Berlaku lebih dari 1 tahun</li>
                    <li><span style="background: #c8ff88"></span>Berlaku, kurang dari 1 tahun</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showModalImportExcel" title="Import Asesi" title-class="text-black font-18" body-class="p-3"
      hide-footer>
      <div class="col-12">
        <div class="mb-3">
          <a class="btn btn-success" href="https://upac.pln-pusdiklat.co.id/backend/storage/_template_import_asesi.xlsx"
            target="_blank">
            <i class="dripicons-article"> </i>
            Download Template
          </a>
        </div>
      </div>
      <b-form class="p-2" @submit.prevent="StoreData">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="Kode">Import File Excel</label>
              <input class="form-control" type="file" id="importexcel" @change="uploadFile" />
            </div>
            <div class="respond-input-file float-left" id="uploadLoading"></div>
          </div>
        </div>
        <div class="text-end pt-5 mt-3">
          <b-button variant="light" @click="showModalImportExcel = false">Close</b-button>
          <b-button type="submit" v-if="isUploadFile == false" variant="success" class="ms-1"> <i
              class="bx bx-save"></i>Save</b-button>
          <b-button type="submit" v-else variant="success" class="ms-1" disabled> <i
              class="bx bx-save"></i>Processing...</b-button>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>

<style scoped>
.mr-2 {
  margin-right: 0.5rem !important;
}

.my-legend .legend-title {
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 90%;
}

.my-legend .legend-scale ul {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  float: left;
  list-style: none;
}

.my-legend .legend-scale ul li {
  font-size: 80%;
  list-style: none;
  margin-left: 0;
  line-height: 18px;
  margin-bottom: 2px;
}

.my-legend ul.legend-labels li span {
  display: block;
  float: left;
  height: 16px;
  width: 30px;
  margin-right: 5px;
  margin-left: 0;
  border: 1px solid #999;
}

.my-legend .legend-source {
  font-size: 70%;
  color: #999;
  clear: both;
}

.my-legend a {
  color: #777;
}
</style>
