<script>
import LayoutPenilaian from "@/router/layouts/main-penilaian";
import appConfig from "@/app.config";
import moment from "moment";
import axios from "axios";

import { debounce } from "@/helpers/debounce";
import DataPeserta from "./detail-jadwal/data-peserta.vue";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Penjadwalan Konversi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    LayoutPenilaian,
    DataPeserta,
  },
  data() {
    return {
      data_jadwal: [],
      idjadwal: this.$route.params.id,
      data_petugas: [],
      showModalUpdateStatus: false,
      currentActiveIndex: 0,
      option_status: [
        {
          label: "Draft",
          status: "Draft",
        },
        {
          label: "Batal",
          status: "batal",
        },
        {
          label: "Verifikasi Asesi",
          status: "verifikasi",
        },
        {
          label: "Proses Verifikasi PTL",
          status: "proses_ptl",
        },
        {
          label: "MK Draft",
          status: "MK Draft",
        },
        {
          label: "MK Fix",
          status: "MK Fix",
        },
        {
          label: "Selesai",
          status: "selesai",
        },
      ],
      selected_status: "",
    };
  },
  mounted() {
    this.getDataJadwal();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    //search & get jenis asesmen
    getDataJadwal() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog",
        params: {
          id: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.data_jadwal = response_data.data[0];
            self.data_petugas = JSON.parse(self.data_jadwal.j_json_petugas);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<template>
  <LayoutPenilaian>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Detail Jadwal PEGPOG</div>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr style="display: none;">
                        <td scope="row" style="width: 20%">ID Jadwal</td>
                        <td style="width: 5%">:</td>
                        <td>{{ data_jadwal?.idjadwal }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Tanggal Mulai</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_tanggal_mulai }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Tanggal Selesai</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_tanggal_sampai }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Asesmen Grade</td>
                        <td>:</td>
                        <td>
                          <ul style="padding: 0;">
                            <li v-for="item in data_jadwal.asesmen_grade" :key="item.id_grade">
                              {{ item.nama_jenis_asesmen }}
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <tr style="display: none;">
                        <td scope="row">Keterangan</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_keterangan }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr style="display: none;">
                        <td scope="row" style="width: 20%">Kegiatan</td>
                        <td style="width: 5%">:</td>
                        <td>{{ data_jadwal?.j_txt_kegiatan }}</td>
                      </tr>
                      <tr>
                        <td scope="row" style="width: 20%">PTL</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_txt_ptl }}</td>
                      </tr>
                      <tr v-for="(jadwal, index) in data_petugas" :key="index">
                        <td scope="row">
                          <span v-if="index == 0">Petugas</span>
                        </td>
                        <td><span v-if="index == 0">:</span></td>
                        <td>{{ index + 1 + ". " + jadwal.jp_txt_petugas }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted text-secondary"
              v-model="currentActiveIndex"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Data Peserta</span>
                </template>
                <DataPeserta
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 0"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </LayoutPenilaian>
  </template>
