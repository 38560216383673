<script>
import axios from "axios";
// import Swal from "sweetalert2";
import moment from "moment";
export default {
  props: ["idjadwal"],
  data() {
    return {
      table_data: [],
    };
  },
  mounted() {
    let self = this;
    self.getMonitorQc();
  },
  methods: {
    getMonitorQc() {
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "qc/get-list-monitor/" +
          this.$props.idjadwal,
        params: {
          idjadwal: this.$props.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          this.table_data = response_data.data;
          console.log(this.table_data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
<template>
  <table class="table table-bordered mb-0">
    <thead>
      <tr class="text-white">
        <th scope="col">No</th>
        <th scope="col">Nip</th>
        <th scope="col">Nama</th>
        <th scope="col">Waktu Ubah</th>
        <th scope="col">Status QC</th>
      </tr>
    </thead>
    <tbody>
      <template
        v-for="(sectionData, sectionName) in table_data"
        :key="sectionName"
      >
        <!-- Only render the section if it has data -->
        <tr>
          <td
            style="background-color: #fffab0"
            colspan="5"
            class="section-header"
          >
            {{ sectionName }}
          </td>
        </tr>
        <!-- Loop through each item within a section -->
        <tr v-for="(item, index) in sectionData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.u_nip }}</td>
          <td>{{ item.u_nama }}</td>
          <td>{{ item.updated_at ? item.updated_at : "-" }}</td>
          <td>
            <span
              class="badge"
              :class="{
                'bg-success': item.qcst_status === 'SELESAI',
                'bg-info': item.qcst_status === null || item.qcst_status === 'ONPROSES',
              }"
              >{{ item.qcst_status === "SELESAI" ? "Selesai" : "On Proses"   }}</span
            >

            <!-- <span class="badge bg-warning">{{ item.qcst_status }}</span> -->
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
