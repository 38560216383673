<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { decryptData } from "@/helpers/crypt.js";
export default {
  components: {
    Datatable,
    Pagination,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_kompetensi_peg" },
      {
        width: "auto",
        label: "Kompetensi PEG POG",
        name: "inisial_kompetensi_target",
      },
      {
        width: "auto",
        label: "Kompetensi Group",
        name: "inisial_kompetensi_group",
      },
      {
        width: "auto",
        label: "Jenis Asesmen Source",
        name: "nama_jenisasesmen_source",
      },
      {
        width: "auto",
        label: "Kompetensi Source",
        name: "inisial_kompetensi_source",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    let sortOrders2 = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns2 = [
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      {
        width: "auto",
        label: "Kompetensi PEG POG",
        name: "inisial_kompetensi_target",
      },
      {
        width: "auto",
        label: "Alat Tes",
        name: "nama_alat_tes",
      },
      { width: "auto", label: "Aspek", name: "nama_aspek" },
    ];

    // cek sorting
    columns2.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      option_asesmen_grade: this.data_jadwal.asesmen_grade,
      selected_id_asesmen_grade: "",
      selected_asesmen_grade: [],

      idjadwal: "",
      table_data: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        m_dirkom_idm_dirkom: "",
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      table_data2: [],
      loadingTable2: true,
      columns2: columns2,
      sortKey2: "id_role", // sort key awal
      sortOrders2: sortOrders2,
      currentTablePage2: "",
      // parsing data ke table data
      tableData2: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        id_jadwal: "",
        m_dirkom_idm_dirkom: "",
      },
      // config untuk paginasi
      pagination2: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      path_file_import: "",
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
    this.searchDatatable2 = debounce(() => {
      this.getDataTable2();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/kompetensi-peg/kompetensi"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.id_jadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            self.loadingTable = false;
            console.log(self.table_data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDataTable2(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/kompetensi-peg/potensi"
    ) {
      let self = this;
      self.loadingTable2 = true;
      self.tableData2.id_jadwal = self.idjadwal2;

      var config = {
        method: "get",
        url: url,
        params: self.tableData2,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data2 = response_data_fix.list_data.data;
            self.configPagination2(response_data_fix.list_data);
            self.currentTablePage2 = response_data_fix.list_data.current_page;
            self.loadingTable2 = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    configPagination2(data) {
      let self = this;
      self.pagination2.lastPage = data.last_page;
      self.pagination2.currentPage = data.current_page;
      self.pagination2.total = data.total;
      self.pagination2.lastPageUrl = data.last_page_url;
      self.pagination2.nextPageUrl = data.next_page_url;
      self.pagination2.prevPageUrl = data.prev_page_url;
      self.pagination2.from = data.from;
      self.pagination2.to = data.to;
      self.pagination2.links = data.links;
    },
    sortBy2(key) {
      let self = this;
      self.sortKey2 = key;
      self.sortOrders2[key] = self.sortOrders2[key] * -1;
      self.tableData2.column = self.getIndex(self.columns, "name", key);
      self.tableData2.dir = self.sortOrders2[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex2(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage2: function (str) {
      let self = this;
      self.getDataTable(str);
    },

    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    onChangeAsesmenGrade() {
      this.selected_id_asesmen_grade =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.tableData.m_dirkom_idm_dirkom =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.tableData2.m_dirkom_idm_dirkom =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.getDataTable();
      this.getDataTable2();
    },

    //search & get jenis asesme
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-4">
        <label for="Batch">Pilih Asesmen Grade</label>
        <v-select
          :options="option_asesmen_grade"
          label="nama_jenis_asesmen"
          v-model="selected_asesmen_grade"
          @update:modelValue="onChangeAsesmenGrade()"
          placeholder="-Pilih Jenis Asesmen-"
          class="text-secondary"
        ></v-select>
      </div>
    </div>
    <div class="row mt-4" v-if="selected_id_asesmen_grade == ''">
      <b-alert variant="primary" show
        >Silahkan pilih salah satu Asesmen Grade</b-alert
      >
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="row">
          <h4>Kompetensi</h4>
        </div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <div class="row mb-3"></div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                        style="width: 100%"
                        v-model="tableData.length"
                        @change="getDataTable()"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-5">&nbsp;</div>
              <div class="col-md-3">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="searchTable"
                  placeholder="Cari Data ..."
                  v-model="tableData.search"
                  @input="searchDatatable"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <datatable
            :columns="columns"
            :sortKey="sortKey"
            :sortOrders="sortOrders"
            @sort="sortBy"
          >
            <tbody>
              <tr v-if="loadingTable">
                <td colspan="6">
                  <i class="fas fa-spinner fa-spin"></i> Loading...
                </td>
              </tr>
              <tr v-else-if="table_data == ''">
                <td class="text-center" colspan="6">Data Tidak Tersedia</td>
              </tr>
              <tr
                v-else
                v-for="(row_data, key_data) in table_data"
                :key="key_data"
              >
                <td class="text-center">
                  <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                  <div v-else>
                    {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                    }}{{ key_data + 1 }}.
                  </div>
                </td>
                <td>{{ row_data.inisial_kompetensi_target }}</td>
                <td>{{ row_data.inisial_kompetensi_group }}</td>
                <td>{{ row_data.nama_jenisasesmen_source }}</td>
                <td>{{ row_data.inisial_kompetensi_source }}</td>
              </tr>
            </tbody>
          </datatable>
        </div>
        <div class="row">
          <pagination :pagination="pagination" @to-page="toPage"></pagination>
        </div>
      </div>
      <div class="row mt-5">
        <div class="row">
          <h4>Potensi</h4>
        </div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <div class="row mb-3"></div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                        style="width: 100%"
                        v-model="tableData2.length"
                        @change="getDataTable2()"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-5">&nbsp;</div>
              <div class="col-md-3">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="searchTable"
                  placeholder="Cari Data ..."
                  v-model="tableData2.search"
                  @input="searchDatatable2"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <datatable
            :columns="columns2"
            :sortKey="sortKey2"
            :sortOrders="sortOrders2"
            @sort="sortBy2"
          >
            <tbody>
              <tr v-if="loadingTable2">
                <td colspan="6">
                  <i class="fas fa-spinner fa-spin"></i> Loading...
                </td>
              </tr>
              <tr v-else-if="table_data2 == ''">
                <td class="text-center" colspan="6">Data Tidak Tersedia</td>
              </tr>
              <tr
                v-else
                v-for="(row_data, key_data) in table_data2"
                :key="key_data"
              >
                <td class="text-center">
                  <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                  <div v-else>
                    {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                    }}{{ key_data + 1 }}.
                  </div>
                </td>
                <td>{{ row_data.inisial_kompetensi_target }}</td>
                <td>{{ row_data.nama_alat_tes }}</td>
                <td>{{ row_data.nama_aspek }}</td>
              </tr>
            </tbody>
          </datatable>
        </div>
        <div class="row">
          <pagination :pagination="pagination2" @to-page="toPage2"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>
