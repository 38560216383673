<script>
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { decryptData } from '../../../../../helpers/crypt';

export default {
  props: ["data_jadwal"],
  data() {
    return {
      idjadwal: this.$route.params.id,
      isSave: false,
      shuffle: [],
      tempShuffle: [],
      dataAsesi: [],
      dataAsesi2: [],
      dataAsesor: [],
      dataSimulasi: [],
      listRuang: [],
      form: [],
      currentKelompok: "",
      currentAsesor: "",
      noUrut: 0,
      isError: false,
      isDuplicateAsesi: false,
      isDuplicateAsesor: false,
      headerRequest: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
        id_user: localStorage.session_user_id,
        nama_user: localStorage.session_name,
      },
    };
  },
  computed: {
    dataAsesorNew() {
      return this.dataAsesor.filter(function (item) {
        return item.user != null;
      });
    },
    dataAsesiNew() {
      return this.dataAsesi.filter(function (item) {
        return item.user_asesi != null;
      });
    },
  },
  mounted() {
    this.idjadwal = decryptData(this.idjadwal);
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      let config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/mk/" +
          self.idjadwal +
          "/mk",
        headers: self.headerRequest,
      };

      Swal.fire({
        imageUrl: require("@/assets/images/loader.gif"),
        imageHeight: 150,
        title: "Refresh Data",
        text: "Mohon tunggu sejenak",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.shuffle = response_data.data.shuffle;
          self.tempShuffle = response_data.data.tempShuffle;
          self.dataAsesi = response_data.data.asesi;
          self.dataAsesi2 = response_data.data.newAsesi;
          self.dataAsesor = response_data.data.asesor;
          self.isSave = response_data.data.isSave;

          if (self.isSave) {
            self.dataSimulasi = response_data.data.simulasi;
            self.listRuang = response_data.data.list_ruang;
            self.form = response_data.data.form;
          }
          Swal.close();
        })
        .catch((errors) => {
          // console.log(errors.response.data);
          let response_error =
            errors.response == undefined ? "" : errors.response.data.data.error;
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: response_error,
          });
          this.isError = true;
        });
    },
    refreshData() {
      this.isDuplicateAsesi = false;
      this.isDuplicateAsesor = false;
      this.getData();
      this.$forceUpdate();
    },
    saveRandom() {
      let self = this;
      let config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/mk/" +
          self.idjadwal +
          "/mk",
        headers: self.headerRequest,
        data: {
          tempShuffle: self.tempShuffle,
          jadwal: self.data_jadwal,
          asesi: self.dataAsesi2,
        },
      };

      Swal.fire({
        title: "Apakah Anda yakin untuk menyimpan data shuffle ?",
        text: "Proses ini akan memakan waktu beberapa saat",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, saya yakin!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            imageUrl: require("@/assets/images/loader.gif"),
            imageHeight: 150,
            title: "Refresh Data",
            text: "Mohon tunggu sejenak",
            showConfirmButton: false,
            allowOutsideClick: false,
          });
          axios(config)
            .then(() => {
              self.refreshData();
              // console.log(response);
            })
            .catch((errors) => {
              // console.log(errors.response.data);
              let response_error =
                errors.response == undefined
                  ? ""
                  : errors.response.data.data.error;
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Something went wrong",
                text: response_error,
              });
              this.isError = true;
            });
        }
      });
    },
    printKelompok(namaKelompok) {
      let self = this;
      if (namaKelompok != self.currentKelompok) {
        self.currentKelompok = namaKelompok;
        return true;
      }
      return false;
    },
    printAsesor(idAsesor) {
      if (idAsesor != self.currentAsesor) {
        self.currentAsesor = idAsesor;
        return true;
      }
      return false;
    },
    kodeAsesi(kelompok) {
      if (kelompok != this.currentKelompok) {
        this.noUrut = 0;
        this.currentKelompok = kelompok;
      }
      if (this.noUrut >= 9) {
        this.noUrut = 0;
      }
      this.noUrut++;
      return (
        kelompok.replace("Kelompok", "") +
        this.noUrut.toString().substring(this.noUrut.toString().length - 2)
      );
    },
    cekDuplikat(className, type) {
      // get values
      let ids = $("." + className)
        .map(function () {
          return $(this).val();
        })
        .get();

      // sort
      let sorted_arr = ids.slice().sort();
      let cek = "";
      let duplicate = false;
      sorted_arr.forEach((element) => {
        if (cek == element && element != undefined && element != "") {
          duplicate = true;
          if (type == "asesor") {
            this.isDuplicateAsesor = duplicate;
          } else {
            this.isDuplicateAsesi = duplicate;
          }
          return;
        }
        cek = element;
      });

      if (type == "asesor") {
        this.isDuplicateAsesor = duplicate;
      } else {
        this.isDuplicateAsesi = duplicate;
      }
    },
    resetMk() {
      Swal.fire({
        icon: "question",
        title: "Apakah anda yakin akan reset Mk ?",
        showCancelButton: true,
        confirmButtonText: "Ya, saya yakin!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/mk/" +
              self.idjadwal +
              "/mk-reset",
            headers: self.headerRequest,
          };
          axios(config)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Berhasil reset data MK",
                timer: 1500,
              }).then(() => {
                self.refreshData();
              });
            })
            .catch(() => {});
        }
      });
    },
    ajukanMk() {
      let self = this;
      console.log();

      Swal.fire({
        icon: "warning",
        title: "Apakah Anda yakin simpan data ke Draft MK ?",
        showCancelButton: true,
        confirmButtonText: "Ya, saya yakin!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/mk/" +
              self.idjadwal +
              "/status/Draft",
            data: {
              shuffle: self.shuffle,
              form: self.form,
            },
            headers: self.headerRequest,
          };

          Swal.fire({
            imageUrl: require("@/assets/images/loader.gif"),
            imageHeight: 150,
            title: "Refresh Data",
            text: "Mohon tunggu sejenak",
            showConfirmButton: false,
            allowOutsideClick: false,
          });
          axios(config)
            .then((response) => {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Berhasil ajukan MK",
                timer: 1500,
              }).then(() => {
                self.refreshData();
              });
              console.log(response);
            })
            .catch((errors) => {
              console.log(errors);
              // console.log(errors.response.data);
              let response_error =
                errors.response == undefined
                  ? ""
                  : errors.response.data.data.error;
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Something went wrong",
                text: response_error,
              });
            });
        }
      });
    },
    aktifkanMK() {
      let self = this;

      Swal.fire({
        icon: "warning",
        title: "Apakah Anda yakin Aktifkan MK ?",
        showCancelButton: true,
        confirmButtonText: "Ya, saya yakin!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/mk/" +
              self.idjadwal +
              "/activate",
            headers: self.headerRequest,
          };

          axios(config)
            .then((response) => {
              console.log(response);
              Swal.fire({
                icon: "success",
                title: "Sukses aktifkan MK",
                timer: 1500,
              });
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
  },
};
</script>

<template>
  <!-- shuffle -->
  <div class="alert alert-danger" role="alert" v-if="isDuplicateAsesi">
    Terdapat asesi yang duplikat
  </div>
  <div class="alert alert-danger" role="alert" v-if="isDuplicateAsesor">
    Terdapat asesor yang duplikat
  </div>
  <table v-if="!isSave" class="table mb-0 table-bordered table-condensed">
    <thead class="text-white text-center">
      <tr>
        <th colspan="3">Mapping Asesor X Asesi</th>
        <th>Simulasi 1</th>
        <th rowspan="2"></th>
      </tr>
      <tr>
        <th class="text-left">
          <button class="btn btn-sm btn-danger mx-2" v-on:click="refreshData()">
            <i class="fa fa-recycle"></i>
            Shuffle
          </button>
          <button
            class="btn btn-sm btn-primary"
            :disabled="isDuplicateAsesi || isDuplicateAsesor || isError"
            @click="saveRandom()"
          >
            <i class="bx bx-save"></i>
            Save Shuffle
          </button>
        </th>
        <th>Ruang</th>
        <th>Assesor</th>
        <th>-</th>
      </tr>
    </thead>
    <tbody class="text-center align-middle">
      <template v-for="(kelompok, namaKelompok) in shuffle" :key="namaKelompok">
        <template v-for="(asesi, asesor) in kelompok" :key="asesor">
          <template v-for="(val, key) in asesi" :key="key">
            <template v-for="(value, key2) in [0, 1, 2]" :key="key2">
              <tr>
                <td
                  :rowspan="Object.keys(kelompok).length * 3"
                  v-if="printKelompok(namaKelompok)"
                >
                  {{ namaKelompok }}
                </td>
                <template v-if="printAsesor(asesor)">
                  <td rowspan="3">-</td>
                  <td rowspan="3">
                    <!-- {{ tempShuffle[namaKelompok]["asesor"][asesor].id }}
                    {{ asesor }} -->
                    <select
                      class="form-control form-control-sm asesor-id"
                      @change="cekDuplikat('asesor-id', 'asesor')"
                      v-model="tempShuffle[namaKelompok]['asesor'][asesor].id"
                    >
                      <option value="">-- Pilih Asesor --</option>
                      <option
                        v-for="(option, key3) in dataAsesorNew"
                        :value="option.user.iduser"
                        :key="key3"
                        :selected="option.user.iduser == asesor"
                      >
                        <!-- {{ option.user.u_nama }} -->
                        {{ option.jr_nama }}
                      </option>
                    </select>
                  </td>
                </template>
                <td v-if="asesi[0][value] != undefined">
                  <select
                    class="form-control form-control-sm asesi-id"
                    v-on:change="cekDuplikat('asesi-id', 'asesi')"
                    v-model="
                      tempShuffle[namaKelompok]['asesor'][asesor]['asesi'][
                        asesi[0][value]
                      ]
                    "
                  >
                    <option value="">-- Pilih Asesi --</option>
                    <option
                      v-for="(option, key4) in dataAsesi2"
                      :value="option.user_asesi[0].iduser"
                      :key="key4"
                      :selected="option.user_asesi[0].iduser == asesi[0][value]"
                    >
                      {{ option.user_asesi[0].u_nama }}
                    </option>
                  </select>
                </td>
                <td v-if="asesi[0][value] != undefined">
                  {{ kodeAsesi(namaKelompok) }}
                </td>
                <td class="text-center" v-if="asesi[0][value] == undefined">
                  -
                </td>
                <td class="text-center" v-if="asesi[0][value] == undefined">
                  -
                </td>
              </tr>
            </template>
          </template>
        </template>
      </template>
    </tbody>
  </table>
  <!-- shuffle - end -->

  <!-- simulasi -->
  <template v-if="isSave">
    <div
      class="d-flex justify-content-end mb-2"
      v-if="data_jadwal.j_status == 'proses_ptl'"
    >
      <button class="btn btn-danger pull-right" @click="resetMk()">
        <i class="fa fa-recycle"></i> Reset Mk
      </button>
    </div>
    <table class="table mb-0 table-bordered tbale-condensed">
      <thead class="text-center text-white">
        <tr>
          <th colspan="3">Mapping Asesor x Asesi</th>
          <th v-for="(simulasi, no) in dataSimulasi" :key="no" colspan="2">
            Simulasi {{ no + 1 }}
          </th>
        </tr>
        <tr>
          <th>Kelompok</th>
          <th width="100px">Ruang</th>
          <th>Asesor</th>
          <th
            v-for="(simulasi, no) in dataSimulasi"
            :key="no"
            width="160px"
            colspan="2"
          >
            {{ simulasi.nama_simulasi }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(kelompok, namaKelompok) in shuffle"
          :key="namaKelompok"
        >
          <template v-for="(asesi, asesor) in kelompok" :key="asesor">
            <template v-for="(val, key) in asesi" :key="key">
              <template v-for="(value, key2) in val" :key="key2">
                <!-- v dataAsesi[namaKelompok][urutan] -->
                <tr>
                  <td :rowspan="Object.keys(kelompok).length * 3" v-if="printKelompok(namaKelompok)">
                    {{ namaKelompok }}
                    <br />
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="form.jadwal_detail_ruang_kelompok[namaKelompok]"
                      :disabled="data_jadwal.j_status != 'proses_ptl'"
                    />
                  </td>
                  <template v-if="printAsesor(asesor)">
                    <td rowspan="3">
                      <select
                        class="form-control form-control-sm"
                        v-model="form.jadwal_detail_ruang_asesor[asesor]"
                        :disabled="data_jadwal.j_status != 'proses_ptl'"
                      >
                        <option
                          v-for="(ruang, i) in listRuang"
                          :key="i"
                          :value="ruang.id_ruang"
                        >
                          {{ ruang.rg_ruang }}
                        </option>
                      </select>
                    </td>
                    <td rowspan="3">
                      <span v-if="dataAsesor[asesor] != undefined">
                        <!-- {{ dataAsesor[asesor]["user"].u_nama }} -->
                        {{ dataAsesor[asesor].jr_nama }}
                      </span>
                      <span v-if="dataAsesor[asesor] == undefined"> - </span>
                    </td>
                  </template>
                  <template v-for="(peserta, i) in value" :key="i">
                    <template v-if="peserta != undefined">
                      <td>
                        <!-- {{ peserta.nama_simulasi }} -->
                        {{ peserta.nama_asesi }}
                        <!-- {{ peserta.user_master.u_nama }} -->
                      </td>
                      <td>
                        {{ peserta.pin }}
                      </td>
                    </template>
                    <template v-else>
                      <td>-</td>
                      <td>-</td>
                      <!-- <td colspan="2" class="text-center">-</td> -->
                    </template>
                  </template>
                </tr>
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </table>
    <div
      class="d-flex justify-content-end mb-2 mt-2"
      v-if="isSave && data_jadwal.j_status == 'proses_ptl'"
    >
      <button class="btn btn-primary" @click="ajukanMk()">
        <i class="fa fa-save"></i>
        Simpan ke Draft MK
      </button>
    </div>
    <div
      class="d-flex justify-content-end mb-2 mt-2"
      v-if="isSave && data_jadwal.j_status == 'MK Draft'"
    >
      <button class="btn btn-success" @click="aktifkanMK()">
        <i class="fa fa-save"></i>
        Aktifkan MK
      </button>
    </div>
  </template>
  <!-- simulasi - end -->
</template>
