<script>
import { layoutComputed } from "@/state/helpers";

/**
 * Sidebar component
 */
export default {
  components: {},
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      navbarUser: {
        displayName: localStorage.getItem("session_name"),
        pathPhoto: localStorage.getItem("session_path_photo"),
        pathPhotoFull:
          process.env.VUE_APP_BACKEND_URL +
          localStorage.getItem("session_path_photo"),
      },
      roleData: JSON.parse(localStorage.getItem("session_role")),
      roleActive: localStorage.getItem("session_role_active_name"),
      sessionData: JSON.parse(localStorage.getItem("session")),
    };
  },
  methods: {
    onRoutechange() {
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      }, 300);
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "dark");
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "small");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "icon":
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-sidebar-size");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "scrollable":
              document.body.setAttribute("data-layout-scrollable", "true");
              document.body.removeAttribute("data-layout-mode");
              document.body.removeAttribute("data-layout-size")
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="card">
    <div class="card-body">
      <div class="catalyst-profile-cont">
        <br>
        <img src="https://dev.alfahuma.tech/midpln/catalyst/assets/images/Avatar/AvatarMaleTrans.png"
          class="profil-img-catalyst" />
        <br><br>
        <b v-if="navbarUser">{{ navbarUser.displayName }}</b>
        <b v-else>Smartsoft Studio</b>
        <br>
        <span v-if="roleActive">{{ roleActive }}</span>
        <br><br>
        <ul class="catalyst-profile-menu">
          <li><a href="http://103.157.97.119/midpln/catalyst/" target="_blank">Pembelajaran Saya</a></li>
          <li><a href="http://103.157.97.119/midpln/catalyst/profile" target="_blank">Dashboard</a></li>
          <li><a href="http://103.157.97.119/midpln/catalyst/jadwal" target="_blank">Jadwal</a></li>
          <li><a href="http://103.157.97.119/midpln/catalyst/evaluasi" target="_blank">Evaluasi</a></li>
          <li>
            <router-link :to="{
              name: 'all-penilaian-pegpog',
            }" class="catalyst-link-active"> Penilaian Atasan
            </router-link>
          </li>
          <li><a href="http://103.157.97.119/midpln/catalyst/pengaturan" target="_blank">Pengaturan</a></li>
          <li><router-link to="/logout">Keluar</router-link></li>
        </ul>
      </div>

    </div>
  </div>
  <!-- Left Sidebar End -->
</template>