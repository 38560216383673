<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: ["data_peserta", "id_asesi", "id_jadwal", "idjadwal_peserta", "id_jenisasesmen", "jenis_asesmen", "tanggal_mulai_pelaksanaan", "nip"],
  data() {
    return {
      salesData: {
        title: "Monthly Sales",
        monthlySales: [
          {
            product: "P123",
            months: [
              {
                month: "January",
                unitPrice: "$80",
                unitsSold: 2200,
              },
              {
                month: "February",
                unitPrice: "$82",
                unitsSold: 1900,
              },
              {
                month: "March",
                unitPrice: "$81",
                unitsSold: 1800,
              },
            ],
          },
          {
            product: "Q456",
            months: [
              {
                month: "January",
                unitPrice: "$20",
                unitsSold: 200,
              },
              {
                month: "February",
                unitPrice: "$22",
                unitsSold: 100,
              },
            ],
          },
        ],
      },
      table_data: [],
      table_data_atas: [],
    };
  },
  mounted() {
    let self = this;
    self.getDataAtas();
    self.getData();
  },
  methods: {
    getDataAtas() {
      console.log(this.$props.id_jenisasesmen)
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/detail-soft-competency-manajerial-atas",
        params: {
          id_asesi: this.$props.id_asesi,
          idjadwal: this.$props.id_jadwal,
          idjadwal_peserta: this.$props.idjadwal_peserta,
          nip: this.$props.nip,
          id_jenisasesmen : this.$props.id_jenisasesmen
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data_atas = response_data_fix;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getData() {
      let self = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/detail-soft-competency-manajerial",
        params: {
          id_asesi: this.$props.id_asesi,
          idjadwal: this.$props.id_jadwal,
          idjadwal_peserta: this.$props.idjadwal_peserta,
          id_jenisasesmen : this.$props.id_jenisasesmen
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<template>
  <div>
    <b>{{ jenis_asesmen }} </b> - <b>{{ tanggal_mulai_pelaksanaan }}</b>
    <div class="row">
      <div class="col-6">
        <div class="table-responsive">
          <table class="table table-bordered mb-0 mt-2">
            <thead class="bg-dark text-white" style="padding: 10px">
              <th style="padding: 5px">Kompetensi</th>
              <th style="padding: 5px">Rating</th>
            </thead>
            <tbody>
              <tr v-for="(row_data, key) in table_data_atas" :key="key">
                <td>{{ row_data.kompetensi }}</td>
                <td>{{ row_data.rating }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-6">

        <div class="table-responsive">
          <table class="table table-bordered mb-0 mt-2">
            <thead class="bg-dark text-white" style="padding: 10px">
              <th style="padding: 5px">Kompetensi</th>
              <th style="padding: 5px">Inisial Keyb</th>
              <th style="padding: 5px">Value</th>
            </thead>
            <tbody>
              <!-- <template v-for="mSale in table_data" :key="mSale"> -->
              <tr v-for="(row_data, key) in table_data" :key="key">
                <td>{{ row_data.kompetensi }}</td>
                <td>{{ row_data.inisial_keyb }}</td>
                <td>{{ row_data.value }}</td>
              </tr>
              <!-- </template> -->
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <!-- <table
      border="1"
      class="table-responsive"
      style="border-collapse: collapse"
    >
      <thead>
        <th>Product</th>
        <th>Month</th>
        <th>Unit price</th>
        <th>No. sold</th>
      </thead>
      <tbody>
        <template v-for="mSale in salesData.monthlySales" :key="mSale">
          <tr v-for="(month, key) in mSale.months" :key="key">
            <td v-if="key == 0" :rowspan="mSale.months.length">
              {{ mSale.product }}
            </td>
            <td>{{ month.month }}</td>
            <td>{{ month.unitPrice }}</td>
            <td>{{ month.unitsSold }}</td>
          </tr>
        </template>
      </tbody>
    </table> -->
  </div>
</template>
