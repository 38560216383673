<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

import { debounce } from "@/helpers/debounce";
import DataPeserta from "./detail-jadwal/data-peserta.vue";
import KonfigurasiKompetensi from "./detail-jadwal/konfigurasi-kompetensi.vue";
import PenilaianSurvey from "./detail-jadwal/penilaian-survey.vue";
import PenilaianSoftCompetency from "./detail-jadwal/penilaian-soft-competency.vue";
import PenilaianPotensi from "./detail-jadwal/penilaian-potensi.vue";
import PenilaianPegPog from "./detail-jadwal/penilaian-pegpog.vue";
import { decryptData, encryptData } from "../../../../helpers/crypt.js";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Penjadwalan Konversi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    DataPeserta,
    KonfigurasiKompetensi,
    PenilaianSoftCompetency,
    PenilaianPotensi,
    PenilaianSurvey,
    PenilaianPegPog,
  },
  data() {
    return {
      data_jadwal: [],
      alat_survey: [],
      idjadwal: "",
      data_petugas: [],
      showModalUpdateStatus: false,
      currentActiveIndex: 0,
      sessionRoleId: localStorage.session_role_active_id,
      option_status: [
        {
          label: "Draft",
          status: "Draft",
        },
        {
          label: "Batal",
          status: "batal",
        },
        {
          label: "Verifikasi Asesi",
          status: "verifikasi",
        },
        {
          label: "Proses Verifikasi PTL",
          status: "proses_ptl",
        },
        {
          label: "MK Draft",
          status: "MK Draft",
        },
        {
          label: "MK Fix",
          status: "MK Fix",
        },
        {
          label: "Selesai",
          status: "selesai",
        },
      ],
      selected_status: "",
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
    this.getDataJadwal();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },

    //search & get jenis asesmen
    getDataJadwal() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pegpog",
        params: {
          id: self.idjadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.data_jadwal = response_data.data[0];
            self.alat_survey = response_data.data[1];
            self.data_petugas = JSON.parse(self.data_jadwal.j_json_petugas);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    updateStatus(status) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin Mengirim data jadwal ke PTL?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var config = {
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/jadwal-pegpog/update-status",
            data: {
              id: self.data_jadwal.idjadwal,
              status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            if (response.data.meta.code == 200) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Berhasil Mengubah Status ",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    decryptUrl(params) {
      const decryptedText = decryptData(params);
      return decryptedText;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Detail Jadwal PEGPOG</div>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td scope="row" style="width: 20%">ID Jadwal</td>
                        <td style="width: 5%">:</td>
                        <td>
                          <div class="d-flex justify-content-between">
                            #{{ data_jadwal?.idjadwal }}
                            <div v-if="data_jadwal?.j_status == 'Draft'">
                              <span
                                class="badge badge-pill badge-soft-info font-size-12"
                                >Draft</span
                              >
                            </div>
                            <div v-if="data_jadwal?.j_status == 'void'">
                              <span
                                class="badge badge-pill badge-soft-danger font-size-12"
                                >Batal</span
                              >
                            </div>
                            <div v-if="data_jadwal?.j_status == 'batal'">
                              <span
                                class="badge badge-pill badge-soft-danger font-size-12"
                                >Batal</span
                              >
                            </div>
                            <div v-if="data_jadwal?.j_status == 'proses_ptl'">
                              <span
                                class="badge badge-pill badge-soft-info font-size-12"
                                >Proses Verifikasi PTL</span
                              >
                            </div>
                            <div v-if="data_jadwal?.j_status == 'verifikasi'">
                              <span
                                class="badge badge-pill badge-soft-info font-size-12"
                                >Verifikasi Asesi</span
                              >
                            </div>
                            <div v-if="data_jadwal?.j_status == 'MK Draft'">
                              <span
                                class="badge badge-pill badge-soft-info font-size-12"
                                >MK Draft</span
                              >
                            </div>
                            <div v-if="data_jadwal?.j_status == 'selesai'">
                              <span
                                class="badge badge-pill badge-soft-success font-size-12"
                                >Selesai</span
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Tanggal Mulai</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_tanggal_mulai }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Tanggal Selesai</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_tanggal_sampai }}</td>
                      </tr>
                      <tr>
                        <td scope="row">Asesmen Grade</td>
                        <td>:</td>
                        <td>
                          <ul style="padding: 0;">
                            <li v-for="item in data_jadwal.asesmen_grade" :key="item.id_grade">
                              {{ item.nama_jenis_asesmen }}
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <tr>
                        <td scope="row">Keterangan</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_keterangan }}</td>
                      </tr>
                      <tr v-if="sessionRoleId != 9">
                        <td colspan="3">
                          <button
                            class="btn btn-success btn-sm"
                            @click="
                              () => {
                                showModalUpdateStatus = true;
                              }
                            "
                          >
                            <i class="fas fa-sync-alt"></i>
                            Update Status Jadwal
                          </button>
                          <router-link
                            v-if="data_jadwal?.j_status == 'Draft'"
                            class="btn btn-info btn-sm ms-2"
                            :to="{
                              name: 'edit-jadwal-pegpog',
                              params: {
                                id: encryptedUrl(idjadwal),
                              },
                            }"
                            ><i class="fa fa-edit"></i> Edit
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td scope="row" style="width: 20%">Sub Kegiatan</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.m_nama_kegiatan }}</td>
                      </tr>
                      <tr>
                        <td scope="row" style="width: 20%">PTL</td>
                        <td>:</td>
                        <td>{{ data_jadwal?.j_txt_ptl }}</td>
                      </tr>
                      <tr v-for="(jadwal, index) in data_petugas" :key="index">
                        <td scope="row">
                          <span v-if="index == 0">Petugas</span>
                        </td>
                        <td><span v-if="index == 0">:</span></td>
                        <td>{{ index + 1 + ". " + jadwal.jp_txt_petugas }}</td>
                      </tr>
                      <tr>
                        <td>Survey Atasan</td>
                        <td>:</td>
                        <td>
                          <span v-if="data_jadwal?.j_is_survey == 1">Ya</span>
                          <span v-else>Tidak</span>
                        </td>
                      </tr>
                      <!-- <tr v-for="(nama, index) in alat_survey" :key="index">
                        <td scope="row">
                          <span v-if="index == 0">Nama Alat Survey</span>
                        </td>
                        <td><span v-if="index == 0">:</span></td>
                        <td>{{ index + 1 + ". " + nama.nama_lengkap }}</td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted text-secondary"
              v-model="currentActiveIndex"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Data Peserta</span>
                </template>
                <DataPeserta
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 0"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>

              <b-tab>
                <template v-slot:title onclick="console.log('a')">
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Konfigurasi Kompetensi & Potensi</span
                  >
                </template>
                <KonfigurasiKompetensi
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 1"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
              <b-tab>
                <template v-slot:title onclick="console.log('a')">
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Penilaian Soft Competency</span
                  >
                </template>
                <PenilaianSoftCompetency
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 2"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
              <b-tab>
                <template v-slot:title onclick="console.log('a')">
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Penilaian Potensi</span
                  >
                </template>
                <PenilaianPotensi
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 3"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
              <b-tab v-if="data_jadwal?.j_is_survey == 1">
                <template v-slot:title onclick="console.log('b')">
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Penilaian Survey Atasan</span
                  >
                </template>
                <PenilaianSurvey
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 4"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
              <b-tab>
                <template v-slot:title onclick="console.log('a')">
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Penilaian Akhir</span
                  >
                </template>
                <PenilaianPegPog
                  v-if="data_jadwal.length != 0 && currentActiveIndex == 5"
                  :data_jadwal="data_jadwal"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal
    v-model="showModalUpdateStatus"
    id="modal-lg"
    size="lg"
    title="Tambah Peserta"
    title-class="font-18"
    hide-footer
  >
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <label for="formImport" class="form-label"
            >Update Status Jadwal</label
          >
          <v-select
            :options="option_status"
            v-model="selected_status"
            label="label"
            placeholder="-Pilih Status-"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">&nbsp;</div>
      <div class="col-md-6">
        <div class="text-end">
          &nbsp;
          <button
            type="button"
            class="btn btn-primary"
            @click="updateStatus(selected_status.status)"
          >
            <i class="fa fa-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
