<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import { debounce } from "@/helpers/debounce";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import DetaiLSoftCompetency from "./detail-soft-competency/detail-soft-competency.vue";
import DetaiLSoftCompetencySupervisor from "./detail-soft-competency/detail-soft-competenct-supervisor.vue";
import { decryptData } from "@/helpers/crypt.js";
export default {
  components: {
    Datatable,
    Pagination,
    DetaiLSoftCompetency,
    DetaiLSoftCompetencySupervisor,
  },
  props: {
    data_jadwal: {
      type: Object,
    },
  },
  data() {
    let sortOrders = [];
    let sortOrders2 = []; // select sortOrder
    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      { width: "auto", label: "NIP", name: "ja_nip" },
      { width: "auto", label: "Nama", name: "ja_nama" },
      { width: "auto", label: "ID Jadwal", name: "ja_idjadwal" },
      { width: "auto", label: "Jenis Asesmen", name: "j_txt_dirkom" },
      { width: "auto", label: "Tanggal Asesmen", name: "j_tanggal_mulai" },
      { width: "175px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    let columns2 = [
      { width: "40px", label: "-", name: "-" },
      { width: "50px", label: "No", name: "id_jadwalasesi" },
      { width: "auto", label: "NIP", name: "ja_nip" },
      { width: "auto", label: "Nama", name: "ja_nama" },
      { width: "auto", label: "Unit", name: "ja_unit" },
      { width: "auto", label: "Jabatan", name: "ja_jabatan" },
      { width: "auto", label: "Status", name: "status_jadwal_ac" },
    ];
    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    columns2.forEach((column2) => {
      sortOrders2[column2.name] = -1;
    });
    return {
      option_asesmen_grade: this.data_jadwal.asesmen_grade,
      selected_id_asesmen_grade: "",
      selected_asesmen_grade: [],

      idjadwal: "",
      selected_idasesi: "",
      selected_nama: "",
      table_data: [],
      loadingTable: true,
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      selected_peserta: [],
      showDetailSoftCompetency: false,
      showDetailSoftCompetencySupervisor: false,
      disabledCheckboxPeserta: false,
      selectedParticipants: [],
      disabledCheckboxes: [],
      checkedAll: "",
      is_process: false,
      selected_nip: "",
      sessionRoleId: localStorage.session_role_active_id,
      jenis_asesmen: "",
      id_jenisasesmen: "",
      tanggal_mulai_pelaksanaan: "",
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        idjadwal: "",
        role_id: localStorage.session_role_active_id,
        username: localStorage.session_username,
        m_dirkom_idm_dirkom: "",
      },
      syncData: {
        idjadwal: "",
        lastSync: "",
      },
      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      table_data2: [],
      loadingTable2: true,
      columns2: columns2,
      sortKey2: "id_role", // sort key awal
      sortOrders2: sortOrders2,
      currentTablePage2: "",
      // parsing data ke table data
      tableData2: {
        draw: 0,
        length: 50,
        search: "",
        column: 0,
        dir: "asc",
        idjadwal: "",
        completed_idasesi: [],
        role_id: localStorage.session_role_active_id,
        username: localStorage.session_username,
        m_dirkom_idm_dirkom: "",
      },
      // config untuk paginasi
      pagination2: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    this.idjadwal = decryptData(this.$route.params.id);
    this.syncCompleteSoftCompetency();
    this.getLastSync();
    // this.getDataTable();
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
    this.searchDatatable2 = debounce(() => {
      this.getDataSelfCompentency();
      // call fetch API to get results
    }, 500);
  },
  methods: {
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pegpog/penilaian-complete-soft-competency"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.idjadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list_data.data;
            self.configPagination(response_data_fix.list_data);
            self.currentTablePage = response_data_fix.list_data.current_page;
            self.loadingTable = false;

            self.table_data.forEach((element) => {
              self.tableData2.completed_idasesi.push(element.ja_idasesi);
            });
            this.getDataSelfCompentency();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    fullDateTimeFormat(datetime) {
      return moment(datetime).format("DD-MM-YYYY HH:mm");
    },

    configPagination2(data) {
      let self = this;
      self.pagination2.lastPage = data.last_page;
      self.pagination2.currentPage = data.current_page;
      self.pagination2.total = data.total;
      self.pagination2.lastPageUrl = data.last_page_url;
      self.pagination2.nextPageUrl = data.next_page_url;
      self.pagination2.prevPageUrl = data.prev_page_url;
      self.pagination2.from = data.from;
      self.pagination2.to = data.to;
      self.pagination2.links = data.links;
    },
    sortBy2(key) {
      let self = this;
      self.sortKey2 = key;
      self.sortOrders2[key] = self.sortOrders2[key] * -1;
      self.tableData2.column = self.getIndex2(self.columns2, "name", key);
      self.tableData2.dir = self.sortOrders2[key] === 1 ? "asc" : "desc";
      self.getDataSelfCompentency();
    },
    getIndex2(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    toPage2: function (str) {
      let self = this;
      self.getDataSelfCompentency(str);
    },
    getDataSelfCompentency(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pegpog/penilaian-uncomplete-soft-competency"
    ) {
      let self = this;
      self.loadingTable2 = true;
      self.tableData2.idjadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.tableData2,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.table_data2 = response_data_fix.list_data.data;
            self.configPagination2(response_data_fix.list_data);
            self.currentTablePage2 = response_data_fix.list_data.current_page;
            self.loadingTable2 = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    openDetailModal(
      id_asesi,
      nama,
      idjadwal_peserta,
      jenis_asesmen,
      tanggal_mulai_pelaksanaan,
      nip
    ) {
      this.showDetailSoftCompetency = !this.showDetailSoftCompetency;
      this.selected_idasesi = id_asesi;
      this.selected_nama = nama;
      this.selected_nip = nip;
      this.idjadwal_peserta = idjadwal_peserta;
      this.id_jenisasesmen = this.selected_asesmen_grade.id_jenis_asesmen;
      this.jenis_asesmen = jenis_asesmen;
      this.tanggal_mulai_pelaksanaan = tanggal_mulai_pelaksanaan;
    },
    openDetailModalSupervisor(
      nip,
      nama,
      idjadwal_peserta,
      jenis_asesmen,
      tanggal_mulai_pelaksanaan
    ) {
      this.showDetailSoftCompetencySupervisor =
        !this.showDetailSoftCompetencySupervisor;
      this.selected_nama = nama;
      this.selected_nip = nip;
      this.idjadwal_peserta = idjadwal_peserta;
      this.id_jenisasesmen = this.selected_asesmen_grade.id_jenis_asesmen;
      this.jenis_asesmen = jenis_asesmen;
      this.tanggal_mulai_pelaksanaan = tanggal_mulai_pelaksanaan;
    },
    checkAllPeserta() {
      let self = this;
      if (this.checkedAll) {
        self.table_data2.forEach((option, index) => {
          if (index <= 15) {
            self.selected_peserta.push(option);
          } else {
            return false;
          }
        });
      } else {
        self.selected_peserta = [];
      }
    },
    syncCompleteSoftCompetency(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pegpog/sync-complete-soft-competency"
    ) {
      let self = this;
      self.loadingTable2 = true;
      self.syncData.idjadwal = self.idjadwal;
      self.is_process = true;
      var config = {
        method: "get",
        url: url,
        params: self.syncData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.meta.code == 200) {
            this.getLastSync();
            // Swal.fire({
            //   icon: "success",
            //   title: "Berhasil!",
            //   text: response.data.meta.message,
            // });
            self.is_process = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.meta.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getLastSync(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pegpog/last-sync-complete-kompetensi"
    ) {
      let self = this;
      self.loadingTable2 = true;
      self.syncData.idjadwal = self.idjadwal;

      var config = {
        method: "get",
        url: url,
        params: self.syncData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          this.syncData.lastSync = response.data.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    isCheckboxDisabled(peserta) {
      // Disable the checkbox if more than two participants are selected,
      // except for the participant with the given ID.
      return (
        this.selected_peserta.length == 18 &&
        !this.selected_peserta.includes(peserta)
      );
    },
    onChangeAsesmenGrade() {
      this.selected_id_asesmen_grade =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.tableData.m_dirkom_idm_dirkom =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.tableData2.m_dirkom_idm_dirkom =
        this.selected_asesmen_grade.id_jenis_asesmen;
      this.getDataTable();
      this.getDataSelfCompentency();
    },

    exportCompleteSoftCompetency() {
      let self = this;
      self.tableData.idjadwal = self.idjadwal;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/export-complete-soft-competency",
        responseType: "blob",
        crossdomain: true,
        params: this.tableData,
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename =
          "Export Soft Competency" +
          new Date().toISOString().slice(0, 10) +
          ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },

    exportUncompleteSoftCompetency() {
      let self = this;
      self.tableData.idjadwal = self.idjadwal;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jadwal-pegpog/export-uncomplete-soft-competency",
        responseType: "blob",
        crossdomain: true,
        params: this.tableData,
        headers: {
          Accept: "application/vnd.ms-excel",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config).then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename =
          "Export_peserta_belum_soft_competency" +
          new Date().toISOString().slice(0, 10) +
          ".xlsx";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-4">
        <label for="Batch">Pilih Asesmen Grade</label>
        <v-select
          :options="option_asesmen_grade"
          label="nama_jenis_asesmen"
          v-model="selected_asesmen_grade"
          @update:modelValue="onChangeAsesmenGrade()"
          placeholder="-Pilih Jenis Asesmen-"
          class="text-secondary"
        ></v-select>
      </div>
    </div>
    <div class="row mt-4" v-if="selected_id_asesmen_grade == ''">
      <b-alert variant="primary" show
        >Silahkan pilih salah satu Asesmen Grade</b-alert
      >
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-6">
          <h4>Peserta Yang Punya Nilai Kompetensi</h4>
        </div>
        <div class="col-6 text-end">
          <button
            type="button"
            class="btn btn-primary"
            @click="exportCompleteSoftCompetency()"
          >
            Export
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="row mb-3"></div>
          <hr />
          <div class="row">
            <div class="col-md-4">
              <table>
                <tr>
                  <td>Show</td>
                  <td>
                    <select
                      class="form-control form-control-sm"
                      id="showpaginatetable"
                      style="width: 100%"
                      v-model="tableData.length"
                      @change="getDataTable()"
                    >
                      <option value="10" selected>10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </td>
                  <td>Entries</td>
                </tr>
              </table>
            </div>
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-3">
              <input
                type="text"
                class="form-control form-control-sm"
                id="searchTable"
                placeholder="Cari Data ..."
                v-model="tableData.search"
                @input="searchDatatable"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <datatable
          :columns="columns"
          :sortKey="sortKey"
          :sortOrders="sortOrders"
          @sort="sortBy"
        >
          <tbody>
            <tr v-if="loadingTable">
              <td colspan="6">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else-if="table_data == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr
              v-else
              v-for="(row_data, key_data) in table_data"
              :key="key_data"
            >
              <td class="text-center">
                <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                <div v-else>
                  {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                  }}{{ key_data + 1 }}.
                </div>
              </td>
              <td>{{ row_data.nip }}</td>
              <td>{{ row_data.ja_nama }}</td>
              <td>{{ row_data.idjadwal }}</td>
              <td>{{ row_data.jenis_asesmen }}</td>
              <td>{{ row_data.tanggal_mulai_pelaksanaan }}</td>
              <td class="text-center">
                <button
                  type="button"
                  v-if="row_data.level == 'Manajerial'"
                  class="btn btn-info btn-sm"
                  @click="
                    openDetailModal(
                      row_data.ja_idasesi,
                      row_data.ja_nama,
                      row_data.idjadwal,
                      row_data.jenis_asesmen,
                      row_data.tanggal_mulai_pelaksanaan,
                      row_data.nip
                    )
                  "
                >
                  <i class="fa fa-eye"></i> Detail
                </button>
                <button
                  type="button"
                  v-else-if="row_data.level == 'Supervisor'"
                  class="btn btn-info btn-sm"
                  @click="
                    openDetailModalSupervisor(
                      row_data.nip,
                      row_data.ja_nama,
                      row_data.idjadwal,
                      row_data.jenis_asesmen,
                      row_data.tanggal_mulai_pelaksanaan
                    )
                  "
                >
                  <i class="fa fa-eye"></i> Detail
                </button>
              </td>
            </tr>
          </tbody>
        </datatable>
      </div>
      <div class="row">
        <pagination :pagination="pagination" @to-page="toPage"></pagination>
      </div>
      <div class="row">
        <h4>Peserta Yang Belum Punya Nilai Kompetensi</h4>
      </div>

      <div class="row">
        <div class="col-md-12 mb-2">
          
          <div class="row mb-3"></div>
          <hr />
          <div class="row">
            <div class="col-12 text-end">
              <button
                type="button"
                class="btn btn-primary"
                @click="exportUncompleteSoftCompetency()"
              >
                Export
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <!-- <button
              v-if="selected_peserta.length > 0"
              class="btn btn-info btn-sm me-2"
              @click="checkAllPeserta()"
            >
              Pilih Peserta
            </button> -->
              <router-link
                class="btn btn-success btn-sm"
                v-if="selected_peserta.length >= 1"
                :to="{
                  name: 'add-jadwal-asesmen',
                  params: {
                    id: idjadwal,
                    // data_peserta : selected_peserta
                    // dataPeserta: JSON.stringify(selected_peserta),
                  },
                  state: {
                    data_peserta: JSON.stringify(selected_peserta),
                    data_jadwal: JSON.stringify(data_jadwal),
                  },
                }"
                ><i class="fa fa-edit"></i> Buat Jadwal Asesmen
              </router-link>
              
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <table>
                <tr>
                  <td>Show</td>
                  <td>
                    <select
                      class="form-control form-control-sm"
                      id="showpaginatetable"
                      style="width: 100%"
                      v-model="tableData2.length"
                      @change="getDataSelfCompentency()"
                    >
                      <option value="10" selected>10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </td>
                  <td>Entries</td>
                </tr>
              </table>
            </div>
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-3">
              <input
                type="text"
                class="form-control form-control-sm"
                id="searchTable"
                placeholder="Cari Data ..."
                v-model="tableData2.search"
                @input="searchDatatable2"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <table class="table mb-0 table-bordered table-condensed table-hover">
          <thead class="bg-dark text-center text-white">
            <tr>
              <th>
                <input
                  type="checkbox"
                  v-model="checkedAll"
                  @change="checkAllPeserta()"
                />
              </th>
              <th>No</th>
              <th>Nip</th>
              <th>Nama</th>
              <th>Unit</th>
              <th>Jabatan</th>
              <th>Status</th>
              <th v-if="sessionRoleId != 9">Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loadingTable2">
              <td colspan="6">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else-if="table_data2 == ''">
              <td class="text-center" colspan="6">Data Tidak Tersedia</td>
            </tr>
            <tr
              v-else
              v-for="(row_data, key_data) in table_data2"
              :key="key_data"
            >
              <td>
                <div v-if="row_data?.status_jadwal_ac == false">
                  <input
                    type="checkbox"
                    :id="row_data.id_jadwalasesi"
                    v-model="selected_peserta"
                    :value="row_data"
                    :disabled="isCheckboxDisabled(row_data)"
                  />
                </div>
              </td>
              <td class="text-center">
                <div v-if="key_data + 1 == 10">{{ currentTablePage2 }}0.</div>
                <div v-else>
                  {{ currentTablePage2 - 1 != 0 ? currentTablePage2 - 1 : ""
                  }}{{ key_data + 1 }}.
                </div>
              </td>
              <td>{{ row_data.ja_nip }}</td>
              <td>{{ row_data.ja_nama }}</td>
              <td>{{ row_data.ja_unit }}</td>
              <td>{{ row_data.ja_jabatan }}</td>
              <td class="text-center">
                <template v-if="row_data?.status_jadwal_ac == false">
                  <span class="badge bg-danger"
                    >Jadwal Asesmen Center Belum Dibuat</span
                  >
                </template>
                <template v-else>
                  <span class="badge bg-success">
                    Jadwal Asesmen Center Sudah Dibuatt</span
                  >
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <pagination :pagination="pagination2" @to-page="toPage2"></pagination>
      </div>
    </div>
  </div>
  <b-modal
    v-model="showDetailSoftCompetency"
    id="modal-lg"
    size="xl"
    :title="'Detail Nilai Soft Competency - ' + selected_nama"
    title-class="font-18"
    hide-footer
  >
    <DetaiLSoftCompetency
      v-if="showDetailSoftCompetency"
      :id_asesi="selected_idasesi"
      :nip="selected_nip"
      :id_jadwal="idjadwal"
      :idjadwal_peserta="idjadwal_peserta"
      :id_jenisasesmen="id_jenisasesmen"
      :jenis_asesmen="jenis_asesmen"
      :tanggal_mulai_pelaksanaan="tanggal_mulai_pelaksanaan"
    />
  </b-modal>
  <b-modal
    v-model="showDetailSoftCompetencySupervisor"
    id="modal-lg"
    size="lg"
    :title="'Detail Nilai Soft Competency Supervisor- ' + selected_nama"
    title-class="font-18"
    hide-footer
  >
    <DetaiLSoftCompetencySupervisor
      v-if="showDetailSoftCompetencySupervisor"
      :ja_nip="selected_nip"
      :id_jadwal="idjadwal"
      :idjadwal_peserta="idjadwal_peserta"
      :jenis_asesmen="jenis_asesmen"
      :id_jenisasesmen="this.selected_asesmen_grade.id_jenis_asesmen"
      :tanggal_mulai_pelaksanaan="tanggal_mulai_pelaksanaan"
    />
  </b-modal>
</template>
